import * as React from "react";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const CheckSummary = () => {
    let navigate = useNavigate();

    const handleClick = () => {
        navigate('/Checkout/Detail/ThankYou');
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{marginLeft: "1vw", fontSize: "2.1vw", marginTop: "3vh", marginBottom: "3.7vh", color: "#2F2F2F"}}>
                    Checkout
                </div>
                <div style={{marginLeft: "1vw", display: "flex"}}>
                    <div style={{width: "21vw", marginRight: "4.5vw", height: "38vh", border: "1px solid #C0C0C0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{width:"18vw", height: "32vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{fontSize: "1.9vw", width: "18vw", color: "#2F2F2F"}}>Detail</div>
                            <div style={{fontSize: "1.3vw", width: "15.5vw", height: "25vh"}}>
                                <div style={{marginBottom: "0.8vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Item:</span>
                                    <span style={{color: "#2F2F2F"}}>3</span>
                                </div>
                                <div style={{marginBottom: "0.8vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Subtotal:</span>
                                    <span style={{color: "#2F2F2F"}}>$1048</span>
                                </div>
                                <div style={{marginBottom: "6.5vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Discount:</span>
                                    <span style={{textDecoration: "line-through", color: "#9E9E9E", marginRight: "0.6vw"}}>$1048</span>
                                    <span style={{color: "#2F2F2F"}}>$948</span>
                                </div>
                                <hr style={{width: "108%"}}/>
                                <div style={{marginTop: "2.5vh"}}>
                                    <span style={{color: "#696969", marginRight: "1vw"}}>Total:</span>
                                    <span style={{color: "#2F2F2F"}}>$958</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width: "21vw", height: "58vh", border: "1px solid #C0C0C0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{width:"18vw", height: "52vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{fontSize: "1.9vw", width: "18vw", color: "#2F2F2F"}}>Payment</div>
                            <div style={{fontSize: "1.3vw", color: "#696969", width: "15.5vw", height: "43vh"}}>
                                <div style={{marginBottom: "5.8vh"}}>
                                    Billing Details:
                                </div>
                                <div>
                                    MasterCard: *3456
                                </div>
                                <div style={{marginBottom: "4.2vh"}}>
                                    MasterCard: *3456
                                </div>
                                <div style={{marginBottom: "5.8vh"}}>
                                    Billing Address:
                                </div>
                                <div>
                                    John Doe
                                </div>
                                <div>
                                    123 Street Name, Apt 1
                                </div>
                                <div>
                                    New York, NY USA
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button disableRipple disableElevation variant="contained" onClick={()=>handleClick()} style={{backgroundColor: "transparent", width: "11vw", height: "6vh", borderRadius: "0", background: "#2F2F2F", textTransform:"capitalize", marginTop: "6vh", marginLeft: "43.5vw", fontSize: "1.1vw"}}>
                    Place Order
                </Button>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginLeft: "1vw", fontSize: "3.6vw", marginTop: "3vh", marginBottom: "3.7vh", color: "#2F2F2F"}}>
                    Checkout
                </div>
                <div style={{display: "flex", flexDirection: "column",alignItems: "center", marginBottom: "8vh"}}>
                    <div style={{width: "38vw", marginBottom: "6vh", paddingTop: "2vh", paddingBottom: "2vh", border: "1px solid #C0C0C0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{width:"33vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{fontSize: "2.8vw", width: "30vw", color: "#2F2F2F"}}>Detail</div>
                            <div style={{fontSize: "2.1vw", width: "27vw"}}>
                                <div style={{marginBottom: "0.8vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Item:</span>
                                    <span style={{color: "#2F2F2F"}}>3</span>
                                </div>
                                <div style={{marginBottom: "0.8vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Subtotal:</span>
                                    <span style={{color: "#2F2F2F"}}>$1048</span>
                                </div>
                                <div style={{marginBottom: "6.5vh"}}>
                                    <span style={{color: "#696969", marginRight: "0.6vw"}}>Discount:</span>
                                    <span style={{textDecoration: "line-through", color: "#9E9E9E", marginRight: "0.6vw"}}>$1048</span>
                                    <span style={{color: "#2F2F2F"}}>$948</span>
                                </div>
                                <hr style={{width: "108%"}}/>
                                <div style={{marginTop: "2.5vh"}}>
                                    <span style={{color: "#696969", marginRight: "1vw"}}>Total:</span>
                                    <span style={{color: "#2F2F2F"}}>$958</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width: "38vw", border: "1px solid #C0C0C0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{width:"33vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", paddingTop: "2vh", paddingBottom: "2vh"}}>
                            <div style={{fontSize: "2.8vw", width: "30vw", color: "#2F2F2F", marginBottom: "3.8vh"}}>Payment</div>
                            <div  style={{fontSize: "2.1vw", width: "27vw", color: "#696969"}}>
                                <div style={{marginBottom: "3.8vh"}}>
                                    Billing Details:
                                </div>
                                <div>
                                    MasterCard: *3456
                                </div>
                                <div style={{marginBottom: "3vh"}}>
                                    MasterCard: *3456
                                </div>
                                <div style={{marginBottom: "4.8vh"}}>
                                    Billing Address:
                                </div>
                                <div>
                                    John Doe
                                </div>
                                <div>
                                    123 Street Name, Apt 1
                                </div>
                                <div>
                                    New York, NY USA
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button disableRipple disableElevation variant="contained" onClick={()=>handleClick()} style={{backgroundColor: "transparent", paddingTop: "0.5vh", paddingBottom: "0.5vh", paddingLeft: "2.5vw", paddingRight: "2.5vw", borderRadius: "0", background: "#2F2F2F", textTransform:"capitalize", marginTop: "6vh", fontSize: "2vw"}}>
                        Place Order
                    </Button>
                </div>

            </MediaQuery>
        </div>
    )
}

export default CheckSummary;