import React from 'react';
import MediaQuery from "react-responsive";

const PrivatePolicy = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "370vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "3.7vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Agreement to the Privacy Policy</div>
                    </div>
                    <div style={{height: "340vh", width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{height: "340vh", width: "23vw", fontWeight: "bold", fontSize: "1.4vw"}}>
                            Effective Date: 7/4/2023
                        </div>
                        <div style={{height: "340vh", width: "59vw"}}>
                            <div style={{height: "10vh", fontSize: "1.3vw"}}>
                                This Privacy Policy describes how we collect, use, disclose, and protect personal information when you visit our website www.octavate.io.
                            </div>
                            <div style={{height: "335vh", width: "59vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>1. Information We Collect</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>1.1. Personal Information: </span>
                                            <span>We may collect personal information such as your name, email address, postal address, and phone number when you voluntarily provide it to us, such as when you subscribe to our newsletter, submit a contact form, or make a purchase.</span>
                                        </div>
                                        <div style={{marginTop: "1.5vh"}}>
                                            <span style={{fontWeight: "bold"}}>1.2. Automatically Collected Information: </span>
                                            <span>When you visit our Website, we will NOT automatically collect certain information about your device, browsing actions, and patterns. This may include your IP address, browser type, referring/exit pages, operating system, and timestamps.</span>
                                        </div>
                                        <div style={{marginTop: "1.5vh"}}>
                                            <span style={{fontWeight: "bold"}}>1.3. Cookies and Similar Technologies: </span>
                                            <span>We will NOT use cookies, web beacons, and other tracking technologies during your experience on our Website. These technologies collect information about your usage patterns, preferences, and other browsing activities, and it is something Octavate strives away from.</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>2. Use of Information</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div style={{fontWeight: "bold"}}>
                                            2.1. We may use the personal information we collect to:
                                        </div>
                                        <div style={{marginTop: "1.5vh"}}>
                                            <ul>
                                                <li>
                                                    Provide and personalize our services to you.
                                                </li>
                                                <li>
                                                    Respond to your inquiries, comments, or requests.
                                                </li>
                                                <li>
                                                    Send you newsletters, promotional emails, or other communications that may be of interest to you.
                                                </li>
                                                <li>
                                                    Process and fulfill your orders, including payment processing and shipping.
                                                </li>
                                                <li>
                                                    Improve our Website, products, and services.
                                                </li>
                                                <li>
                                                    Detect and prevent fraudulent activities.
                                                </li>
                                                <li>
                                                    Comply with legal obligations.
                                                </li>
                                                <li>
                                                    Disclosure of Information
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>3. Disclosure of Information</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div style={{fontWeight: "bold"}}>
                                            3.1. We may disclose personal information to third parties in the following circumstances:
                                        </div>
                                        <div style={{marginTop: "1.5vh"}}>
                                            <ul>
                                                <li>
                                                    To our service providers who assist us in operating our Website and providing our services.
                                                </li>
                                                <li>
                                                    To comply with applicable laws, regulations, or legal processes.
                                                </li>
                                                <li>
                                                    To protect our rights, privacy, safety, or property, and that of our users or the public.
                                                </li>
                                                <li>
                                                    In connection with a merger, acquisition, or sale of our business assets.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>4. Data Security</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>4.1. </span>
                                            <span>
                                        We implement reasonable security measures to protect personal information from unauthorized access, use, disclosure, alteration, or destruction.
                                        However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>5. Your Choices</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>5.1. </span>
                                            <span>
                                        You can opt out of receiving promotional emails from us by following the instructions provided in those communications.
                                        Please note that even if you opt out, we may still send you non-promotional messages related to your use of our services.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>6. Third Party Links</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>6.1. </span>
                                            <span>
                                        Our Website may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites.
                                        We encourage you to read the privacy policies of those third parties.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>7. Children’s Privacy</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>7.1. </span>
                                            <span>
                                        Our Website is not intended for children under the age of 13.
                                        We do not knowingly collect personal information from children under 13.
                                        If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>8. Changes to this Private Policy</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>8.1. </span>
                                            <span>
                                        We may update this Privacy Policy from time to time.
                                        Any changes will be effective immediately upon posting the updated policy on our Website.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>9. Contact Us</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>9.1. </span>
                                            <span>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at </span>
                                            <span style={{fontWeight: "bold"}}>
                                        hello@octavate.io.
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "296vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "5vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Agreement to the Privacy Policy</div>
                    </div>
                    <div style={{width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{width: "29vw", fontWeight: "bolder", fontSize: "2.1vw"}}>
                            Effective Date: 7/4/2023
                        </div>
                        <div style={{width: "59vw"}}>
                            <div style={{height: "10vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.1vw"}}>
                                    This Privacy Policy describes how we collect, use, disclose, and protect personal information when you visit our website www.octavate.io.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{height: "255vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>1. Information We Collect</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <div>
                                        <span style={{fontWeight: "bold"}}>1.1. Personal Information: </span>
                                        <span>We may collect personal information such as your name, email address, postal address, and phone number when you voluntarily provide it to us, such as when you subscribe to our newsletter, submit a contact form, or make a purchase.</span>
                                    </div>
                                    <div style={{marginTop: "1.6vh"}}>
                                        <span style={{fontWeight: "bold"}}>1.2. Automatically Collected Information: </span>
                                        <span>When you visit our Website, we will NOT automatically collect certain information about your device, browsing actions, and patterns. This may include your IP address, browser type, referring/exit pages, operating system, and timestamps.</span>
                                    </div>
                                    <div style={{marginTop: "1.6vh"}}>
                                        <span style={{fontWeight: "bold"}}>1.3. Cookies and Similar Technologies: </span>
                                        <span>We will NOT use cookies, web beacons, and other tracking technologies during your experience on our Website. These technologies collect information about your usage patterns, preferences, and other browsing activities, and it is something Octavate strives away from.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>2. Use of Information</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <div style={{fontWeight: "bold"}}>
                                        2.1. We may use the personal information we collect to:
                                    </div>
                                    <div style={{marginTop: "1.5vh"}}>
                                        <ul>
                                            <li>
                                                Provide and personalize our services to you.
                                            </li>
                                            <li>
                                                Respond to your inquiries, comments, or requests.
                                            </li>
                                            <li>
                                                Send you newsletters, promotional emails, or other communications that may be of interest to you.
                                            </li>
                                            <li>
                                                Process and fulfill your orders, including payment processing and shipping.
                                            </li>
                                            <li>
                                                Improve our Website, products, and services.
                                            </li>
                                            <li>
                                                Detect and prevent fraudulent activities.
                                            </li>
                                            <li>
                                                Comply with legal obligations.
                                            </li>
                                            <li>
                                                Disclosure of Information
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>3. Disclosure of Information</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <div style={{fontWeight: "bold"}}>
                                        3.1. We may disclose personal information to third parties in the following circumstances:
                                    </div>
                                    <div style={{marginTop: "1.5vh"}}>
                                        <ul>
                                            <li>
                                                To our service providers who assist us in operating our Website and providing our services.
                                            </li>
                                            <li>
                                                To comply with applicable laws, regulations, or legal processes.
                                            </li>
                                            <li>
                                                To protect our rights, privacy, safety, or property, and that of our users or the public.
                                            </li>
                                            <li>
                                                In connection with a merger, acquisition, or sale of our business assets.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>4. Data Security</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>4.1. </span>
                                    <span>
                                        We implement reasonable security measures to protect personal information from unauthorized access, use, disclosure, alteration, or destruction.
                                        However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>5. Your Choices</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>5.1. </span>
                                    <span>
                                        You can opt out of receiving promotional emails from us by following the instructions provided in those communications.
                                        Please note that even if you opt out, we may still send you non-promotional messages related to your use of our services.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>6. Third Party Links</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>6.1. </span>
                                    <span>
                                        Our Website may contain links to third-party websites or services.
                                        We are not responsible for the privacy practices or the content of such websites.
                                        We encourage you to read the privacy policies of those third parties.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>7. Children’s Privacy</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>7.1. </span>
                                    <span>
                                        Our Website is not intended for children under the age of 13.
                                        We do not knowingly collect personal information from children under 13.
                                        If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>8. Changes to this Private Policy</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>8.1. </span>
                                    <span>
                                        We may update this Privacy Policy from time to time.
                                        Any changes will be effective immediately upon posting the updated policy on our Website.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>9. Contact Us</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    <span style={{fontWeight: "bold"}}>9.1. </span>
                                    <span>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at </span>
                                    <span style={{fontWeight: "bold"}}>
                                        hello@octavate.io.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default PrivatePolicy;
// import React from 'react';
//
// const PrivatePolicy = () => {
//     return (
//         <div style={{height: "370vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
//             <div style={{fontWeight: "bold", fontSize: "3.7vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
//                 <div>Agreement to the Privacy Policy</div>
//             </div>
//             <div style={{height: "340vh", width: "90vw", display: "flex", color: "#2F2F2F"}}>
//                 <div style={{height: "340vh", width: "23vw", fontWeight: "bold", fontSize: "1.4vw"}}>
//                     Effective Date: 7/4/2023
//                 </div>
//                 <div style={{height: "340vh", width: "59vw"}}>
//                     <div style={{height: "10vh", fontSize: "1.3vw"}}>
//                         This Privacy Policy describes how we collect, use, disclose, and protect personal information when you visit our website www.octavate.io.
//                     </div>
//                     <div style={{height: "335vh", width: "59vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>1. Information We Collect</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>1.1. Personal Information: </span>
//                                     <span>We may collect personal information such as your name, email address, postal address, and phone number when you voluntarily provide it to us, such as when you subscribe to our newsletter, submit a contact form, or make a purchase.</span>
//                                 </div>
//                                 <div style={{marginTop: "1.5vh"}}>
//                                     <span style={{fontWeight: "bold"}}>1.2. Automatically Collected Information: </span>
//                                     <span>When you visit our Website, we will NOT automatically collect certain information about your device, browsing actions, and patterns. This may include your IP address, browser type, referring/exit pages, operating system, and timestamps.</span>
//                                 </div>
//                                 <div style={{marginTop: "1.5vh"}}>
//                                     <span style={{fontWeight: "bold"}}>1.3. Cookies and Similar Technologies: </span>
//                                     <span>We will NOT use cookies, web beacons, and other tracking technologies during your experience on our Website. These technologies collect information about your usage patterns, preferences, and other browsing activities, and it is something Octavate strives away from.</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>2. Use of Information</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div style={{fontWeight: "bold"}}>
//                                     2.1. We may use the personal information we collect to:
//                                 </div>
//                                 <div style={{marginTop: "1.5vh"}}>
//                                     <ul>
//                                         <li>
//                                             Provide and personalize our services to you.
//                                         </li>
//                                         <li>
//                                             Respond to your inquiries, comments, or requests.
//                                         </li>
//                                         <li>
//                                             Send you newsletters, promotional emails, or other communications that may be of interest to you.
//                                         </li>
//                                         <li>
//                                             Process and fulfill your orders, including payment processing and shipping.
//                                         </li>
//                                         <li>
//                                             Improve our Website, products, and services.
//                                         </li>
//                                         <li>
//                                             Detect and prevent fraudulent activities.
//                                         </li>
//                                         <li>
//                                             Comply with legal obligations.
//                                         </li>
//                                         <li>
//                                             Disclosure of Information
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>3. Disclosure of Information</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div style={{fontWeight: "bold"}}>
//                                     3.1. We may disclose personal information to third parties in the following circumstances:
//                                 </div>
//                                 <div style={{marginTop: "1.5vh"}}>
//                                     <ul>
//                                         <li>
//                                             To our service providers who assist us in operating our Website and providing our services.
//                                         </li>
//                                         <li>
//                                             To comply with applicable laws, regulations, or legal processes.
//                                         </li>
//                                         <li>
//                                             To protect our rights, privacy, safety, or property, and that of our users or the public.
//                                         </li>
//                                         <li>
//                                             In connection with a merger, acquisition, or sale of our business assets.
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>4. Data Security</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>4.1. </span>
//                                     <span>
//                                         We implement reasonable security measures to protect personal information from unauthorized access, use, disclosure, alteration, or destruction.
//                                         However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>5. Your Choices</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>5.1. </span>
//                                     <span>
//                                         You can opt out of receiving promotional emails from us by following the instructions provided in those communications.
//                                         Please note that even if you opt out, we may still send you non-promotional messages related to your use of our services.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>6. Third Party Links</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>6.1. </span>
//                                     <span>
//                                         Our Website may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites.
//                                         We encourage you to read the privacy policies of those third parties.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>7. Children’s Privacy</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>7.1. </span>
//                                     <span>
//                                         Our Website is not intended for children under the age of 13.
//                                         We do not knowingly collect personal information from children under 13.
//                                         If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>8. Changes to this Private Policy</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>8.1. </span>
//                                     <span>
//                                         We may update this Privacy Policy from time to time.
//                                         Any changes will be effective immediately upon posting the updated policy on our Website.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>9. Contact Us</div>
//                             <div style={{fontSize: "1.3vw"}}>
//                                 <div>
//                                     <span style={{fontWeight: "bold"}}>9.1. </span>
//                                     <span>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at </span>
//                                     <span style={{fontWeight: "bold"}}>
//                                         hello@octavate.io.
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
//
// export default PrivatePolicy;