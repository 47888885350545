import React from 'react';
import '../Home/Home.css'

import img from './img/img.png';
import img_1 from './img/img_1.png';
import img_2 from './img/img_2.png';
import img_3 from './img/img_3.png';
import img_4 from './img/img_4.png';
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";
import {useNavigate} from "react-router-dom";

const CuratedCard_1 = () => {
    const navigate = useNavigate();

    const toIndividualArt=(img, price)=>{
        navigate('/IndividualArt',{state:{img:img,price:price}});
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "19vw", background: "#F1F1F1"}}>
                    <div onClick={()=>{toIndividualArt("./img/img", 233)}} style={{height: "75%", width: "100%", backgroundImage: `url(${img})`, backgroundSize: "100%", overflow: "hidden"}}>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1vw", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F1F1F1", paddingBottom: "1.5vh"}}>
                    <div style={{height: "25.5vh", maxWidth: "26vw", overflow: "hidden"}}>
                        <img src={img} style={{height: "25.5vh", position: "relative"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1.5vw", marginRight: "2vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.4vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.9vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CuratedCard_2 = () => {

    const navigate = useNavigate();

    const toIndividualArt=(img, price)=>{
        navigate('/IndividualArt',{state:{img:img,price:price}});
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "19vw", background: "#F1F1F1"}}>
                    <div onClick={()=>{toIndividualArt("./img/img_1", 233)}} style={{height: "75%", width: "100%", backgroundImage: `url(${img_1})`, backgroundSize: "100%", overflow: "hidden"}}>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1vw", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F1F1F1", paddingBottom: "1.5vh"}}>
                    <div style={{height: "25.5vh", maxWidth: "26vw", overflow: "hidden"}}>
                        <img src={img_1} style={{height: "25.5vh", position: "relative"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1.5vw", marginRight: "2vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.4vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.9vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CuratedCard_3 = () => {
    const navigate = useNavigate();

    const toIndividualArt=(img, price)=>{
        navigate('/IndividualArt',{state:{img:img,price:price}});
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "19vw", background: "#F1F1F1"}}>
                    <div onClick={()=>{toIndividualArt("./img/img_2", 233)}} style={{height: "75%", width: "100%", backgroundImage: `url(${img_2})`, backgroundSize: "100%", overflow: "hidden"}}>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1vw", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F1F1F1", paddingBottom: "1.5vh"}}>
                    <div style={{height: "25.5vh", maxWidth: "26vw", overflow: "hidden"}}>
                        <img src={img_2} style={{height: "25.5vh", position: "relative"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1.5vw", marginRight: "2vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.4vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.9vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CuratedCard_4 = () => {
    const navigate = useNavigate();

    const toIndividualArt=(img, price)=>{
        navigate('/IndividualArt',{state:{img:img,price:price}});
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "19vw", background: "#F1F1F1"}}>
                    <div onClick={()=>{toIndividualArt("./img/img_3", 233)}} style={{height: "75%", width: "100%", backgroundImage: `url(${img_3})`, backgroundSize: "100%", overflow: "hidden"}}>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1vw", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F1F1F1", paddingBottom: "1.5vh"}}>
                    <div style={{height: "25.5vh", maxWidth: "26vw", overflow: "hidden"}}>
                        <img src={img_3} style={{height: "25.5vh", position: "relative"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1.5vw", marginRight: "2vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.4vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.9vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CuratedCard_5 = () => {
    const navigate = useNavigate();

    const toIndividualArt=(img, price)=>{
        navigate('/IndividualArt',{state:{img:img,price:price}});
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "19vw", background: "#F1F1F1"}}>
                    <div onClick={()=>{toIndividualArt("./img/img_4", 233)}} style={{height: "75%", width: "100%", backgroundImage: `url(${img_4})`, backgroundSize: "100%", overflow: "hidden"}}>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1vw", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F1F1F1", paddingBottom: "1.5vh"}}>
                    <div style={{height: "25.5vh", maxWidth: "26vw", overflow: "hidden"}}>
                        <img src={img_4} style={{height: "25.5vh", position: "relative"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginLeft: "1.5vw", marginRight: "2vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.4vw", fontStyle: "italic", marginBottom: "1vh"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.9vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const imageUrls = [
    <CuratedCard_1/>,
    <CuratedCard_2/>,
    <CuratedCard_3/>,
    <CuratedCard_4/>,
    <CuratedCard_5/>,
];

const CuratedRoller = () => {
    const doubledImages = [...imageUrls, ...imageUrls];
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div className="image-roller-container_2">
                    <div className="image-roller" id={"roller_4"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginRight: "3vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div className="image-roller-container_2">
                    <div className="image-roller" id={"roller_4"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginRight: "3vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default CuratedRoller;