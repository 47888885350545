import React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from '@mui/material/TextField';
import img from './img/img.png'
import MediaQuery from "react-responsive";

const HorizontalDisplacement = () => {
    const handleSearch = (event) => {
        console.log(event.target.value);
    };
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <TextField
                        variant="standard"
                        placeholder="Search"
                        onChange={handleSearch}
                        sx={{
                            "& input::placeholder": { fontSize: "1.2vw", color: "#2F2F2F", fontStyle: "italic", opacity: 1},
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{fontSize: "1.5vw"}}/>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                            style: {color: "#2F2F2F", fontSize: "1.2vw", fontFamily: "Poppins, sans-serif"}
                        }}
                        style={{position: "relative", width: "12%", marginTop: "3vh"}}
                    />
                </div>
                <div style={{marginLeft: "5vw", marginRight: "5vw"}}>
                    <div style={{fontSize: '3vw'}}>
                        Exhibitions
                    </div>
                    <div style={{marginTop: "3vh", marginBottom: "5vh", width: "50vw", fontSize: "1vw"}}>
                        Stay updated on our current events, showcases, and exhibitions by our artists as well as guest artists.
                        Events and exhibitions are currently taking place in our New York location.
                    </div>
                    <img alt={"img"} src={img} style={{width: "100%"}}/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <TextField
                        variant="standard"
                        placeholder="Search"
                        onChange={handleSearch}
                        sx={{
                            "& input::placeholder": { fontSize: "2vw", color: "#2F2F2F", fontStyle: "italic", opacity: 1},
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{fontSize: "2.2vw", marginBottom: "0.5vh"}}/>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                            style: {color: "#2F2F2F", fontSize: "1.2vw", fontFamily: "Poppins, sans-serif"}
                        }}
                        style={{position: "relative", width: "15%", marginTop: "3vh"}}
                    />
                </div>
                <div style={{marginLeft: "7vw", marginRight: "7vw"}}>
                    <div style={{fontSize: '5vw'}}>
                        Exhibitions
                    </div>
                    <div style={{marginTop: "11.5vh", marginBottom: "5vh", width: "63vw", fontSize: "2vw"}}>
                        Stay updated on our current events, showcases, and exhibitions by our artists as well as guest artists.
                        Events and exhibitions are currently taking place in our New York location.
                    </div>
                    <img alt={"img"} src={img} style={{width: "100%"}}/>
                </div>
            </MediaQuery>
        </div>
    );
};

export default HorizontalDisplacement;