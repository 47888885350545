import * as React from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {useState} from "react";
import img from "./img/img.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useLocation, useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const ArtPiece = () => {
    const [value, setValue] = useState('credc');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [age, setAge] = useState('');

    const handleChange_age = (event) => {
        setAge(event.target.value);
    };

    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    const location = useLocation();
    console.log(location.state);

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: '100vw', marginBottom: "6vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: '92vw', marginBottom: "5vh", marginTop: "7vh", display: "flex"}}>
                        <div style={{width: '46vw', background: "#F3F3F3", paddingBottom: "10vh"}}>
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <div style={{marginBottom: "5vh", marginTop: "0.8vh", marginRight: "0.5vw", color: "#9B9B9B"}}>
                                    <ArrowBackIosOutlinedIcon style={{marginRight: "0.3vw"}}/>
                                    <ArrowForwardIosOutlinedIcon/>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <img src={img} style={{width: '33vw'}}/>
                            </div>
                        </div>
                        <div>
                            <div style={{marginLeft: "2.5vw", marginTop: "1vh"}}>
                                <Button disableRipple variant="outlined" startIcon={<ArrowBackIosOutlinedIcon style={{fontSize: "15px"}}/>} style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "0.8vw", borderRadius: 0, border: "2px solid #E3E3E3", color: "#696969"}}>
                                    Continue Shopping
                                </Button>
                                <div style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                    <div style={{marginLeft: "1vw", marginTop: "3vh", display: "flex", alignItems: "center"}}>
                                        <div style={{marginRight: "1.2vw", color: "#2F2F2F", fontSize: "1.9vw"}}>Ethereal Reverie</div>
                                        <Checkbox icon={<FavoriteBorderOutlinedIcon style={{fontSize: "30px", color: "#B0B0B0"}}/>} checkedIcon={<FavoriteOutlinedIcon />} />
                                        <Checkbox icon={<BookmarkBorderOutlinedIcon style={{fontSize: "30px", color: "#B0B0B0"}}/>} checkedIcon={<BookmarkOutlinedIcon />} />
                                    </div>
                                </div>
                                <div style={{width: "46vw"}}>
                                    <div style={{marginLeft: "1vw", marginTop: "2.5vh"}}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            style={{borderBottom: "1px solid #D4D4D4"}}
                                            TabIndicatorProps={{style: {background: "#808080"}}}
                                        >
                                            <Tab value="description" label="Description" style={{textTransform: "capitalize", fontSize: "0.8vw", color: "#808080"}}/>
                                            <Tab value="detail" label="Details" style={{textTransform: "capitalize", fontSize: "0.8vw", color: "#808080"}}/>
                                            <Tab value="review" label="Reviews" style={{textTransform: "capitalize", fontSize: "0.8vw", color: "#808080"}}/>
                                        </Tabs>
                                    </div>
                                    <div style={{width: "33vw", marginLeft: "1vw", marginTop: "5vh", color: "#808080", fontSize: "0.85vw"}}>
                                        Introducing the captivating masterpiece "Ethereal Reverie" – a symphony of color and emotion that transcends the canvas.
                                        This extraordinary artwork, created by the talented artist [Artist's Name], invites you to journey into a realm where imagination knows no bounds.
                                    </div>
                                </div>
                                <div style={{marginLeft: "1vw"}}>
                                    <div>
                                        <FormControl sx={{minWidth: 120}} style={{marginTop: "5vh"}}>
                                            <Select
                                                value={age}
                                                onChange={handleChange_age}
                                                IconComponent={KeyboardArrowDownIcon}
                                                variant="standard"
                                                displayEmpty
                                                disableUnderline
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{fontSize: "0.9vw", color: "#696969"}}
                                            >
                                                <MenuItem disabled value={""} style={{fontSize: "0.9vw"}}>Pick Size</MenuItem>
                                                <MenuItem value={"l"} style={{fontSize: "0.9vw", color: "#696969"}}>1</MenuItem>
                                                <MenuItem value={"m"} style={{fontSize: "0.9vw", color: "#696969"}}>2</MenuItem>
                                                <MenuItem value={"s"} style={{fontSize: "0.9vw", color: "#696969"}}>3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl sx={{minWidth: 120}} style={{marginTop: "3vh"}}>
                                            <Select
                                                value={age}
                                                onChange={handleChange_age}
                                                IconComponent={KeyboardArrowDownIcon}
                                                variant="standard"
                                                displayEmpty
                                                disableUnderline
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{fontSize: "0.9vw", color: "#696969"}}
                                            >
                                                <MenuItem disabled value={""} style={{fontSize: "0.9vw"}}>Pick Type</MenuItem>
                                                <MenuItem value={"l"} style={{fontSize: "0.9vw", color: "#696969"}}>Large</MenuItem>
                                                <MenuItem value={"m"} style={{fontSize: "0.9vw", color: "#696969"}}>Middle</MenuItem>
                                                <MenuItem value={"s"} style={{fontSize: "0.9vw", color: "#696969"}}>Small</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '92vw', height: "15vh", display: "flex", justifyContent: "space-between", alignItems: "end"}}>
                        <div style={{paddingTop: "3.5vh", paddingBottom: "3.5vh", width: '11vw', background: "#F3F3F3", border: "1px #999999 solid", display: "flex", justifyContent: "center"}}>
                            <div style={{width: '10vw', display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img src={img} style={{width: '7vw'}}/>
                            </div>
                        </div>
                        <div>
                            <Button disableRipple onClick={()=>handleClick('Shop')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "1vw", borderRadius: 0, background: "#343434", color: "white", width: "10vw", marginLeft: "8.5vw"}}>
                                Add to Cart
                            </Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: '150vh', width: '100vw', display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5vh"}}>
                    <div style={{width: '92vw'}}>
                        <Button disableRipple variant="outlined" startIcon={<ArrowBackIosOutlinedIcon style={{fontSize: "15px"}}/>} style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "2vw", borderRadius: 0, border: "2px solid #E3E3E3", color: "#696969", marginBottom: "3vh"}}>
                            Continue Shopping
                        </Button>
                        <div style={{height: '57vh', width: '100%', background: "#F3F3F3"}}>
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <div style={{marginBottom: "3vh", marginTop: "1vh", marginRight: "0.5vw", color: "#9B9B9B"}}>
                                    <ArrowBackIosOutlinedIcon style={{marginRight: "0.3vw"}}/>
                                    <ArrowForwardIosOutlinedIcon/>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <img src={img} style={{height: '41vh', width: '70vw'}}/>
                            </div>
                        </div>
                        <div style={{height: '15vh', width: '22vw', background: "#F3F3F3", border: "1px #999999 solid", display: "flex", justifyContent: "center", marginTop: "2.5vh"}}>
                            <div style={{height: '15vh', width: '22vw', display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img src={img} style={{height: '9vh', width: '14vw'}}/>
                            </div>
                        </div>
                        <div>
                            <div style={{marginLeft: "2.5vw", marginTop: "1vh"}}>
                                <div style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                    <div style={{marginLeft: "1vw", marginTop: "6vh", display: "flex", alignItems: "center"}}>
                                        <div style={{marginRight: "1.2vw", color: "#2F2F2F", fontSize: "3.5vw"}}>Ethereal Reverie</div>
                                        <Checkbox icon={<FavoriteBorderOutlinedIcon style={{fontSize: "30px", color: "#B0B0B0"}}/>} checkedIcon={<FavoriteOutlinedIcon style={{fontSize: "30px"}}/>} />
                                        <Checkbox icon={<BookmarkBorderOutlinedIcon style={{fontSize: "30px", color: "#B0B0B0"}}/>} checkedIcon={<BookmarkOutlinedIcon style={{fontSize: "30px"}}/>} />
                                    </div>
                                </div>
                                <div style={{width: "80vw"}}>
                                    <div style={{marginLeft: "1vw", marginTop: "2.5vh"}}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            style={{borderBottom: "1px solid #D4D4D4"}}
                                            TabIndicatorProps={{style: {background: "#808080"}}}
                                        >
                                            <Tab value="description" label="Description" style={{textTransform: "capitalize", fontSize: "2.1vw", color: "#808080"}}/>
                                            <Tab value="detail" label="Details" style={{textTransform: "capitalize", fontSize: "2.1vw", color: "#808080"}}/>
                                            <Tab value="review" label="Reviews" style={{textTransform: "capitalize", fontSize: "2vw", color: "#808080"}}/>
                                        </Tabs>
                                    </div>
                                    <div style={{width: "70vw", marginLeft: "1vw", marginTop: "5vh", color: "#808080", fontSize: "1.75vw"}}>
                                        Introducing the captivating masterpiece "Ethereal Reverie" – a symphony of color and emotion that transcends the canvas.
                                        This extraordinary artwork, created by the talented artist [Artist's Name], invites you to journey into a realm where imagination knows no bounds.
                                    </div>
                                </div>
                                <div style={{marginLeft: "1vw"}}>
                                    <div>
                                        <FormControl sx={{minWidth: 120}} style={{marginTop: "5vh"}}>
                                            <Select
                                                value={age}
                                                onChange={handleChange_age}
                                                IconComponent={KeyboardArrowDownIcon}
                                                variant="standard"
                                                displayEmpty
                                                disableUnderline
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{fontSize: "1.8vw", color: "#696969"}}
                                            >
                                                <MenuItem disabled value={""} style={{fontSize: "1.8vw"}}>Pick Size</MenuItem>
                                                <MenuItem value={"l"} style={{fontSize: "1.8vw", color: "#696969"}}>1</MenuItem>
                                                <MenuItem value={"m"} style={{fontSize: "1.8vw", color: "#696969"}}>2</MenuItem>
                                                <MenuItem value={"s"} style={{fontSize: "1.8vw", color: "#696969"}}>3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl sx={{minWidth: 120}} style={{marginTop: "3vh"}}>
                                            <Select
                                                value={age}
                                                onChange={handleChange_age}
                                                IconComponent={KeyboardArrowDownIcon}
                                                variant="standard"
                                                displayEmpty
                                                disableUnderline
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{fontSize: "1.8vw", color: "#696969"}}
                                            >
                                                <MenuItem disabled value={""} style={{fontSize: "1.8vw"}}>Pick Type</MenuItem>
                                                <MenuItem value={"l"} style={{fontSize: "1.8vw", color: "#696969"}}>Large</MenuItem>
                                                <MenuItem value={"m"} style={{fontSize: "1.8vw", color: "#696969"}}>Middle</MenuItem>
                                                <MenuItem value={"s"} style={{fontSize: "1.8vw", color: "#696969"}}>Small</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div style={{width: '92vw', height: "15vh", display: "flex", justifyContent: "space-between", alignItems: "end"}}>*/}
                    {/*    <div>*/}
                    {/*        <Button disableRipple onClick={()=>handleClick('Shop')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "1vw", borderRadius: 0, background: "#343434", color: "white", width: "10vw", marginLeft: "8.5vw"}}>*/}
                    {/*            Add to Cart*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </MediaQuery>
        </div>

    )
}

export default ArtPiece;