import * as React from 'react';
import img from "./img/img_2.png";
import img_1 from "./img/img_3.png";
import img_2 from "./img/img_4.png";
import img_3 from "./img/img_5.png"
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

function LocationCard_1() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Los Angeles
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 La Street, Apt
                                    </div>
                                    <div>
                                        Los Angeles, California 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                    <img src={img} style={{width: "28vw", position: "relative"}}/>
                    <div style={{position: "relative", display: "flex", alignItems: "center", marginLeft: "3.5vw"}}>
                        <div style={{fontSize: "2.5vw"}}>
                            <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                Octavate - Boston
                            </div>
                            <div style={{color: "#6B6B6B"}}>
                                <div>
                                    123 Boston Street, Apt
                                </div>
                                <div>
                                    Boston, Ma 123456
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function LocationCard_2() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img_1} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Chicago
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Chi Street, Apt
                                    </div>
                                    <div>
                                        Chicago, Illinois 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                        <img src={img_1} style={{width: "28vw", position: "relative"}}/>
                        <div style={{position: "relative", display: "flex", alignItems: "center", marginLeft: "3.5vw"}}>
                            <div style={{fontSize: "2.5vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Boston
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Boston Street, Apt
                                    </div>
                                    <div>
                                        Boston, Ma 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function LocationCard_3() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img_2} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Boston
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Boston Street, Apt
                                    </div>
                                    <div>
                                        Boston, Ma 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{marginBottom: "7vh", display: "flex", border: "2px #D4D4D4 solid"}}>
                        <img src={img_2} style={{width: "28vw", position: "relative"}}/>
                        <div style={{position: "relative", display: "flex", alignItems: "center", marginLeft: "3.5vw"}}>
                            <div style={{fontSize: "2.5vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Boston
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Boston Street, Apt
                                    </div>
                                    <div>
                                        Boston, Ma 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function Location() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "90vw", margin: "auto"}}>
                    <div style={{height: "18vh", display: "flex", alignItems: "center", fontSize: "2.8vw"}}>
                        Location
                    </div>
                    <Divider style={{color: "#D4D4D4", border: "1px solid", marginBottom: "7vh", width: "96.5%", position: "relative"}}/>
                    <div style={{display: "flex"}}>
                        <div style={{height: "72vh", width: "45vw"}}>
                            <div style={{height: "35vh"}}>
                                <div style={{marginTop: "1vh", fontSize: "1.3vw"}}>Headquarters</div>
                                <div style={{marginTop: "10vh", marginLeft: "2vw", fontSize: "1.2vw"}}>
                                    <div style={{marginBottom: "2vh", color: "#2F2F2F"}}>Octavate - New York City</div>
                                    <div style={{color: "#6B6B6B"}}>
                                        <div>
                                            372 Fifth Avenue, 3J
                                        </div>
                                        <div>
                                            New York, NY 100018
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{height: "33vh", width: "28vw"}}>
                                <img src={img_3} style={{height: "31vh"}}/>
                            </div>
                        </div>

                        <div style={{height: "72vh", width: "45vw"}}>
                            <LocationCard_1 />
                            <LocationCard_2 />
                            <LocationCard_3 />
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "11vh"}}>
                    <div style={{width: "83vw", marginTop: "10vh", marginBottom: "7.5vh", display: "flex", alignItems: "center", fontSize: "4.5vw"}}>
                        Location
                    </div>
                    <Divider style={{color: "#D4D4D4", width: "83vw", border: "1px solid", marginBottom: "7vh", position: "relative"}}/>
                    <div style={{display: "flex", flexDirection: "column", width: "100vw", alignItems: "center"}}>
                        <div style={{width: "83vw"}}>
                            <div style={{marginBottom: "5vh"}}>
                                <div style={{marginTop: "1vh", fontSize: "2.6vw"}}>Headquarters</div>
                                <div style={{marginTop: "8vh", marginLeft: "2vw", fontSize: "2.05vw"}}>
                                    <div style={{marginBottom: "2vh", color: "#2F2F2F"}}>Octavate - New York City</div>
                                    <div style={{color: "#6B6B6B"}}>
                                        <div>
                                            372 Fifth Avenue, 3J
                                        </div>
                                        <div>
                                            New York, NY 100018
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={img_3} style={{width: "60vw"}}/>
                        </div>

                        <div style={{width: "88vw", marginTop: "10vh"}}>
                            <LocationCard_1 />
                            <LocationCard_2 />
                            <LocationCard_3 />
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Location;