import * as React from 'react'
import img from "./img/img.png";
import MediaQuery from "react-responsive";

const ArtistHeader = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "90vh", width: "100vw"}}>
                    <div style={{marginTop: "5vh", fontSize: "3vw"}}>Isabella Rayne</div>
                    <img src={img} style={{marginTop: "5vh", width: "70vw"}}/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "83vh", width: "100vw"}}>
                    <div style={{marginTop: "5vh", fontSize: "4.5vw"}}>Isabella Rayne</div>
                    <img src={img} style={{marginTop: "5vh", height:"60vh", width: "100vw"}}/>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ArtistHeader;
