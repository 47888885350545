import React from "react";
import {Button} from "@mui/material";
import img from "../Home/img/img_join.png";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const Shop = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, width: "100vw", height: "100vh", position: "absolute", zIndex: -1, filter: "brightness(75%)"}}>
                </div>
                <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                    <div style={{color: "white"}}>
                        <div style={{fontSize: "3.5vw", marginBottom: "12vh", textAlign: "center"}}>Octavate Shop</div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: "1.2vw", width: "50vw", textAlign: "center"}}>
                                Curated collection of artworks ranging from abstract, expressionist, contemporary, and surrealist pieces.
                                View the artists tab to receive more information about the artist as well as how many of their works present on the site.
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "6vh", height: "5vh"}}>
                            <Button disableRipple onClick={()=>handleClick('Artists')} variant="standard" style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "6vh", fontSize: "1.1vw", borderRadius: 0, color: "#FFFFFF", width: "10.5vw", height: "5vh"}}>
                                View Artists
                            </Button>
                            <Button disableRipple variant="standard" style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "6vh", fontSize: "1.1vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "10.5vw", height: "5vh"}}>
                                Go to Shop
                            </Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, width: "100vw", height: "70vh", position: "absolute", zIndex: -1, filter: "brightness(75%)"}}>
                </div>
                <div style={{width: "100vw", height: "70vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                    <div style={{color: "white"}}>
                        <div style={{fontSize: "5vw", marginBottom: "12vh", textAlign: "center"}}>Octavate Shop</div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: "2vw", width: "60vw", textAlign: "center"}}>
                                Curated collection of artworks ranging from abstract, expressionist, contemporary, and surrealist pieces.
                                View the artists tab to receive more information about the artist as well as how many of their works present on the site.
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "4vh"}}>
                            <Button disableRipple onClick={()=>handleClick('Artists')} variant="standard" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "1.6vw", borderRadius: 0, color: "#FFFFFF", width: "15vw"}}>
                                View Artists
                            </Button>
                            <Button disableRipple variant="standard" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "1.6vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "15vw"}}>
                                Go to Shop
                            </Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Shop;