import React from "react";
import ImageRoller_4 from "./Roller_4";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const FeatureThisWeek = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "75.9vh", marginLeft: "2vw", marginTop: "15vh"}}>
                    <div style={{display: "flex"}}>
                        <div style={{fontSize: "4vw", marginTop: "1.5vh"}}>Featured this Week</div>
                        <div style={{width: "70vw", marginLeft: "5vw", height: "51vh"}}>
                            <ImageRoller_4/>
                        </div>
                    </div>
                    <Divider style={{marginTop: "7vh", fontWeight: "2px", width: "10vw", color: "#AFAFAF"}}/>
                    <div style={{marginTop: "2.5vh", marginRight: "45vw", fontSize: "1vw"}}>
                        Every artwork tells a story, and within these walls, narratives are woven together.
                        Discover the artist's journey, passion, and emotions as you embark on a visual storytelling adventure.
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{paddingBottom: "8vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: "4vw", marginTop: "7vh", marginBottom: "5vh"}}>Featured this Week</div>
                    <div style={{height: "48vh", display: "flex", justifyContent: "center"}}>
                        <ImageRoller_4/>
                    </div>
                    <Divider style={{borderBottom: "2px solid #AFAFAF", marginTop: "4vh", marginBottom: "3vh", width: "18vw", color: "#AFAFAF"}}/>
                    <div style={{fontSize: "2.4vw", width: "65vw", textAlign: "center"}}>
                        Every artwork tells a story, and within these walls, narratives are woven together.
                        Discover the artist's journey, passion, and emotions as you embark on a visual storytelling adventure.
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default FeatureThisWeek;