import React from 'react';
import '../Home/Home.css'

import img_1 from "./img/img_6.png";
import img_2 from "./img/img_7.png";
import img_3 from "./img/img_8.png";
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";

const EventCard_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "23vw", backgroundSize: "100%", backgroundImage: `url(${img_1})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "33vh", width: "23vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "1.1vw", marginBottom: "1vh"}}>New Artist Showcase</div>
                            <div style={{fontSize: "1.2vw", fontStyle: "italic"}}>08.05.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "0.67vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Dive into the Uncharted: A Journey Through Contemporary Art.
                            Discover the avant-garde, challenge the norms, and immerse yourself in the vibrant world of contemporary expressions."
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "30vh", width: "48vw", backgroundSize: "100%", backgroundImage: `url(${img_1})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "30vh", width: "48vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "2.5vw", marginBottom: "1vh"}}>New Artist Showcase</div>
                            <div style={{fontSize: "2.8vw", fontStyle: "italic"}}>08.05.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "1.5vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Dive into the Uncharted: A Journey Through Contemporary Art.
                            Discover the avant-garde, challenge the norms, and immerse yourself in the vibrant world of contemporary expressions."
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const EventCard_3 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "23vw", backgroundSize: "100%", backgroundImage: `url(${img_3})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "33vh", width: "23vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "1.1vw", marginBottom: "1vh"}}>Guest Speaker Exhibition</div>
                            <div style={{fontSize: "1.2vw", fontStyle: "italic"}}>08.15.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "0.67vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Echoes of the Past, Vibrancy of the Present: A Tribute to Timeless Artistry.
                            Immerse yourself in the enduring beauty of art that transcends generations."
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "30vh", width: "48vw", backgroundSize: "100%", backgroundImage: `url(${img_3})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "30vh", width: "48vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "2.5vw", marginBottom: "1vh"}}>Guest Speaker Exhibition</div>
                            <div style={{fontSize: "2.8vw", fontStyle: "italic"}}>08.15.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "1.5vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Echoes of the Past, Vibrancy of the Present: A Tribute to Timeless Artistry.
                            Immerse yourself in the enduring beauty of art that transcends generations."
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const EventCard_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "23vw", backgroundSize: "100%", backgroundImage: `url(${img_2})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "33vh", width: "23vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "1.1vw", marginBottom: "1vh"}}>Ethereal Exhibition</div>
                            <div style={{fontSize: "1.2vw", fontStyle: "italic"}}>08.10.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "0.67vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Evoke the Serenity of Nature: An Exhibition Celebrating Earth's Colors.
                            Let the strokes of green, blue, and golden hues transport you to the heart of the natural world."
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "30vh", width: "48vw", backgroundSize: "100%", backgroundImage: `url(${img_2})`, display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "30vh", width: "48vw", background: "#3A3A3A50", color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "4vh", textAlign: "center"}}>
                            <div style={{fontSize: "2.5vw", marginBottom: "1vh"}}>Ethereal Exhibition</div>
                            <div style={{fontSize: "2.8vw", fontStyle: "italic"}}>08.10.23</div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "5vh", fontSize: "1.5vw", marginLeft: "3.5vw", marginRight: "3.5vw"}}>
                            "Evoke the Serenity of Nature: An Exhibition Celebrating Earth's Colors.
                            Let the strokes of green, blue, and golden hues transport you to the heart of the natural world."
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const imageUrls = [
    <EventCard_1/>,
    <EventCard_2/>,
    <EventCard_3/>
];

const ImageRoller_Artist = () => {
    const doubledImages = [...imageUrls, ...imageUrls];
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div className="image-roller-container">
                    <div className="image-roller" id={"roller_3"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginRight: "2vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div className="image-roller-container_2">
                    <div className="image-roller" id={"roller_4"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginRight: "2vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default ImageRoller_Artist;