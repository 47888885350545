import React from "react";
import ImageRoller_4 from "./RollerCollect";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const FeatureThisWeekCollect = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "43vh", marginLeft: "6vw", marginTop: "8vh"}}>
                    <div style={{display: "flex"}}>
                        <div style={{fontSize: "3.5vw", marginTop: "3vh", color: "#575757"}}>Featured this Week</div>
                        <div style={{width: "70vw", marginLeft: "2vw"}}>
                            <ImageRoller_4/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "72vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontSize: "4vw", marginTop: "7vh", marginBottom: "5vh"}}>Featured this Week</div>
                    <div style={{height: "45vh", display: "flex", justifyContent: "center"}}>
                        <ImageRoller_4/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default FeatureThisWeekCollect;