import React from 'react';
import img from './img/img.png'
import MediaQuery from "react-responsive";

const ContactUs = () => {

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "5vw", marginRight: "5vw", height: "29vh"}}>
                    <div style={{width: "60%", position: "relative", fontSize: "2.8vw"}}>
                        Contact Us
                    </div>
                    <div style={{width: "40%", position: "relative", fontSize: "1.3vw"}}>
                        We value your feedback and are here to assist you. If you have any questions, comments, or concerns, please don't hesitate to get in touch with us.
                    </div>
                </div>
                <div style={{marginLeft: "5vw",  marginRight: "5vw"}}>
                    <img alt={"img"} src={img} style={{width: "100%"}}/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "6.5vw", marginRight: "6.5vw", height: "23vh"}}>
                    <div style={{width: "50%", position: "relative", fontSize: "5vw"}}>
                        Contact Us
                    </div>
                    <div style={{width: "50%", position: "relative", fontSize: "1.7vw"}}>
                        We value your feedback and are here to assist you. If you have any questions, comments, or concerns, please don't hesitate to get in touch with us.
                    </div>
                </div>
                <div style={{marginLeft: "6.5vw", marginRight: "6.5vw"}}>
                    <img alt={"img"} src={img} style={{width: "87vw"}}/>
                </div>
            </MediaQuery>
        </div>
    );
};

export default ContactUs;