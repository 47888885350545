const ChangePassIcon = () => {
    return (
        <svg fill="#2F2F2F" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m17.25 17.25c-.192 0-.384-.073-.53-.22l-2.22-2.22-2.22 2.22c-.293.293-.768.293-1.061 0s-.293-.768 0-1.061l2.75-2.75c.293-.293.768-.293 1.061 0l2.75 2.75c.293.293.293.768 0 1.061-.146.147-.338.22-.53.22z"/>
            <path d="m14.5 24c-.414 0-.75-.336-.75-.75v-9c0-.414.336-.75.75-.75s.75.336.75.75v9c0 .414-.336.75-.75.75z"/>
            <path d="m20.5 24c-.192 0-.384-.073-.53-.22l-2.75-2.75c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l2.22 2.22 2.22-2.22c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-2.75 2.75c-.148.147-.34.22-.532.22z"/>
            <path d="m20.5 23.5c-.414 0-.75-.336-.75-.75v-9c0-.414.336-.75.75-.75s.75.336.75.75v9c0 .414-.336.75-.75.75z"/>
            <path d="m9.25 20h-7c-1.24 0-2.25-1.01-2.25-2.25v-8.5c0-1.24 1.01-2.25 2.25-2.25h11.5c1.24 0 2.25 1.01 2.25 2.25v1c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1c0-.413-.337-.75-.75-.75h-11.5c-.413 0-.75.337-.75.75v8.5c0 .413.337.75.75.75h7c.414 0 .75.336.75.75s-.336.75-.75.75z"/>
            <path d="m12.25 8.5c-.414 0-.75-.336-.75-.75v-2.75c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5v2.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-2.75c0-2.757 2.243-5 5-5s5 2.243 5 5v2.75c0 .414-.336.75-.75.75z"/>
        </svg>
    )
}

export default ChangePassIcon;