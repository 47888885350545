import {Button} from "@mui/material";
import React from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MediaQuery from "react-responsive";

const LightMode = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "space-between"}}>
            <MediaQuery minWidth={1024}>
                <div style={{height: "93%", width: "43%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{fontSize: "1.05vw", color: "#575757"}}>Light Mode</div>
                    <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, width: "3vw", padding: 0, fontSize: "0.75vw", border: "#AAAAAA 1px solid", backgroundColor: "transparent", color: "#525252"}}>
                        Select
                    </Button>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "93%", width: "43%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{fontSize: "2vw", color: "#575757"}}>Light Mode</div>
                    <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, width: "3vw", padding: 0, fontSize: "1.4vw", border: "#AAAAAA 1px solid", backgroundColor: "transparent", color: "#525252"}}>
                        Select
                    </Button>
                </div>
            </MediaQuery>
            <div style={{height: "100%", width: "43%", border: "1px #AAAAAA solid", display: "flex", alignItems: "end", justifyContent: "start"}}>
                <div style={{height: "73%", width: "65%", backgroundColor: "#E8E8E8", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center"}}>
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", height: "19%", width: "93%"}}>
                        <MediaQuery minWidth={1024}>
                            <CloseOutlinedIcon style={{color: "#939393", fontSize: "0.4vw"}}/>
                        </MediaQuery>
                        <MediaQuery maxWidth={1024}>
                            <CloseOutlinedIcon style={{color: "#939393", fontSize: "0.8vw"}}/>
                        </MediaQuery>
                    </div>
                    <div style={{height: "63%", width: "68%", backgroundColor: "#F5F5F5"}}>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DarkMode = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "space-between"}}>
            <MediaQuery minWidth={1024}>
                <div style={{height: "93%", width: "43%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{fontSize: "1.05vw", color: "#575757"}}>Dark Mode</div>
                    <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, width: "3vw", padding: 0, fontSize: "0.75vw", border: "#AAAAAA 1px solid", backgroundColor: "transparent", color: "#525252"}}>
                        Select
                    </Button>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "93%", width: "43%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{fontSize: "2vw", color: "#575757"}}>Dark Mode</div>
                    <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, width: "3vw", padding: 0, fontSize: "1.4vw", border: "#AAAAAA 1px solid", backgroundColor: "transparent", color: "#525252"}}>
                        Select
                    </Button>
                </div>
            </MediaQuery>
            <div style={{height: "100%", width: "43%", border: "1px #AAAAAA solid", display: "flex", alignItems: "end", justifyContent: "start"}}>
                <div style={{height: "73%", width: "65%", backgroundColor: "#8A8A8A", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center"}}>
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", height: "19%", width: "93%"}}>
                        <MediaQuery minWidth={1024}>
                            <CloseOutlinedIcon style={{color: "#2F2F2F", fontSize: "0.4vw"}}/>
                        </MediaQuery>
                        <MediaQuery maxWidth={1024}>
                            <CloseOutlinedIcon style={{color: "#2F2F2F", fontSize: "0.8vw"}}/>
                        </MediaQuery>
                    </div>
                    <div style={{height: "63%", width: "68%", backgroundColor: "#BDBDBD"}}>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Theme = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%"}}>
                    <div style={{marginTop: "3.5vh", width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "1.23vw", color: "#575757"}}>
                            Theme Selection
                        </div>
                        <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "1vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                            Save
                        </Button>
                    </div>
                    <div style={{marginTop: "2vh", width: "50%", height: "110%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "42%", width: "100%"}}>
                            <LightMode/>
                        </div>
                        <div style={{height: "42%", width: "100%"}}>
                            <DarkMode/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%"}}>
                    <div style={{marginTop: "3.5vh", width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "2.25vw", color: "#575757"}}>
                            Theme Selection
                        </div>
                        <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "2vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                            Save
                        </Button>
                    </div>
                    <div style={{marginTop: "2vh", width: "50%", height: "110%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "42%", width: "100%"}}>
                            <LightMode/>
                        </div>
                        <div style={{height: "42%", width: "100%"}}>
                            <DarkMode/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default Theme;