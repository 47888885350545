import React from 'react';
import './Home.css'
import img_1 from "./img/img_3.png"
import img_2 from "./img/img_4.png"
import img_1_mobile from "./img/img_4_mobile.png";
import img_2_mobile from "./img/img_5_mobile.png";
import img_3_mobile from "./img/img_6_mobile.png";
import MediaQuery from "react-responsive";

const imageUrls = [
    img_1,
    img_2,
];

const imageUrls_mobile = [
    img_1_mobile,
    img_2_mobile,
    img_3_mobile,
];

const ImageRoller_2 = () => {
    const doubledImages = [...imageUrls, ...imageUrls, ...imageUrls];
    const doubledImages_mobile = [...imageUrls_mobile, ...imageUrls_mobile];
    return (
        <div className="image-roller-container_1">
            <div className="image-roller" id={"roller_2"}>
                <MediaQuery minWidth={1024}>
                    {doubledImages.map((url, index) => (
                        <img key={index} src={url} alt={`Rolling image ${index}`} style={{marginTop: "1.72vw"}}/>
                    ))}
                </MediaQuery>
                <MediaQuery maxWidth={1024}>
                    {doubledImages_mobile.map((url) => (
                        <div style={{marginLeft: "2vw"}}>
                            <div style={{height: "26.5vh"}}>
                                <img src={url} style={{height: "100%"}}/>
                            </div>
                        </div>
                    ))}
                </MediaQuery>
            </div>
        </div>
    );
};

export default ImageRoller_2;