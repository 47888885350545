import React from 'react';
import img from "./img/img_1.png"
import img_1 from "./img/img_2.png";
import img_2 from "./img/img_3.png";
import img_3 from "./img/img_4.png";
import MediaQuery from "react-responsive";

const Work_1 = () => {
    return (
        <div style={{width: "30%"}}>
            <div style={{width: "100%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "4.5vh", paddingBottom: "4.5vh"}}>
                <img src={img_1} style={{width: "72%"}}/>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                <div>
                    <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                    <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                </div>
            </div>
        </div>
    )
}

const Work_1_Mobile = () => {
    return (
        <div style={{height: "52vh",width: "70vw"}}>
            <div style={{height: "80%", width: "100%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                <img src={img_1} style={{height: "76%", width: "72%", }}/>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                <div>
                    <div style={{fontSize: "3.05vw", marginTop: "2.5vh", marginBottom: "1vh"}}>Art Piece Title</div>
                    <div style={{fontSize: "2.25vw", fontStyle: "italic"}}>Artist Name</div>
                </div>
            </div>
        </div>
    )
}

const Work_2 = () => {
    return (
        <div style={{width: "30%"}}>
            <div style={{width: "100%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "4.5vh", paddingBottom: "4.5vh"}}>
                <img src={img_2} style={{width: "72%"}}/>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                <div>
                    <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                    <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                </div>
            </div>
        </div>
    )
}

const Work_3 = () => {
    return (
        <div style={{width: "30%"}}>
            <div style={{width: "100%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "4.5vh", paddingBottom: "4.5vh"}}>
                <img src={img_3} style={{width: "72%"}}/>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                <div>
                    <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                    <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                </div>
            </div>
        </div>
    )
}

const AboutArtist = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{height: "38vh", width: "85vw", background: "#F2F2F2", display: "flex", justifyContent: "space-between", marginTop: "5vh"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "3vh", marginBottom: "4vh", marginLeft: "3vw"}}>
                            <div style={{fontSize: "2vw"}}>About</div>
                            <div style={{fontSize: "0.9vw"}}>
                                Isabella Rayne, a visionary of the canvas, draws inspiration from the ebb and flow of life's emotions, channeling them onto her artistic expressions.
                                With an innate ability to translate her innermost musings into vivid realities, Isabella's artwork becomes a mirror to the human experience.
                            </div>
                        </div>
                        <div style={{marginLeft: "12vw"}}>
                            <img alt="Your Image" src={img} style={{height:"38vh", width: "29vw"}}/>
                        </div>
                    </div>
                    <div style={{fontSize: "1.8vw", width: "85vw", height: "9vh", display: "flex", flexDirection: "column", justifyContent: "end"}}>Artwork</div>
                    <div style={{width: "85vw", display: "flex", justifyContent: "space-between", marginBottom: "5vh"}}>
                        <Work_1/>
                        <Work_2/>
                        <Work_3/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "100vh", width: "100vw", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{height: "30.5vh", width: "91vw", background: "#F2F2F2", display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "4vh", marginBottom: "3vh", marginLeft: "3.5vw"}}>
                            <div style={{fontSize: "3vw"}}>About</div>
                            <div style={{fontSize: "1.35vw"}}>
                                Isabella Rayne, a visionary of the canvas, draws inspiration from the ebb and flow of life's emotions, channeling them onto her artistic expressions.
                                With an innate ability to translate her innermost musings into vivid realities, Isabella's artwork becomes a mirror to the human experience.
                            </div>
                        </div>
                        <div style={{marginLeft: "5vw"}}>
                            <img alt="Your Image" src={img} style={{height:"30.5vh", width: "40vw"}}/>
                        </div>
                    </div>
                    <div style={{fontSize: "3vw", width: "85vw", height: "9vh", display: "flex", flexDirection: "column", justifyContent: "center"}}>Artwork</div>
                    <div style={{width: "85vw", display: "flex", justifyContent: "center"}}>
                        <Work_1_Mobile/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default AboutArtist;