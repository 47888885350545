import AmericanExpress from "./Card/AmericanExpress";
import Discovery from "./Card/Discovery";
import MasterCard from "./Card/MasterCard";
import Visa from "./Card/Visa";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as React from "react";
import {useState} from "react";
import MediaQuery from "react-responsive";

const Cards = () => {
    const [value, setValue] = useState('credc');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "55vw"}}>
                    <div style={{marginLeft: "1vw", fontSize: "2.1vw", marginTop: "3vh", marginBottom: "5vh", color: "#2F2F2F"}}>
                        Checkout
                    </div>
                    <div style={{marginLeft: "1vw", fontSize: "1.5vw", marginBottom: "4vh", color: "#787878"}}>
                        Choose Payment Method
                    </div>
                    <div style={{marginLeft: "1vw", width: "53vw", marginTop: "2.5vh"}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            style={{borderBottom: "1px solid #D4D4D4"}}
                            TabIndicatorProps={{style: {background: "#808080"}}}
                        >
                            <Tab value="Credit_Card" label="Credit Card" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "1.15vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="PayPal" label="PayPal" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "1.15vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Connect_Wallet" label="Connect Wallet" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "1.15vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Crypto_Currency" label="Crypto Currency" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "1.15vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Metamask" label="Metamask" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "1.15vw", color: "#808080", marginBottom: "1.2vh"}}/>
                        </Tabs>
                    </div>
                    <div style={{display: "flex", marginLeft: "1vw", marginTop: "5.5vh"}}>
                        <div style={{marginRight: "1.5vw"}}>
                            <Visa/>
                        </div>
                        <div style={{marginRight: "1.5vw"}}>
                            <MasterCard/>
                        </div>
                        <div style={{marginRight: "1.5vw"}}>
                            <AmericanExpress/>
                        </div>
                        <div>
                            <Discovery/>
                        </div>
                    </div>
                    <div style={{fontSize: "0.7vw", color: "#A0A0A0", marginLeft: "1vw", marginTop: "1.5vh"}}>
                        Billing supports the four cards above.
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "55vw"}}>
                    <div style={{fontSize: "4vw", marginTop: "3vh", marginBottom: "4.5vh", color: "#2F2F2F"}}>
                        Checkout
                    </div>
                    <div style={{marginLeft: "3vw", fontSize: "2.8vw", marginBottom: "2vh", color: "#787878"}}>
                        Choose Payment Method
                    </div>
                    <div style={{marginLeft: "3vw", width: "85vw", marginTop: "2.5vh"}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            style={{borderBottom: "1px solid #D4D4D4"}}
                            TabIndicatorProps={{style: {background: "#808080"}}}
                        >
                            <Tab value="Credit_Card" label="Credit Card" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "2vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="PayPal" label="PayPal" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "2vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Connect_Wallet" label="Connect Wallet" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "2vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Crypto_Currency" label="Crypto Currency" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "2vw", color: "#808080", marginBottom: "1.2vh", marginRight: "0.9vw"}}/>
                            <Tab value="Metamask" label="Metamask" style={{textTransform: "capitalize", whiteSpace: "nowrap", fontSize: "2vw", color: "#808080", marginBottom: "1.2vh"}}/>
                        </Tabs>
                    </div>
                    <div style={{display: "flex", marginLeft: "3vw", marginTop: "5.5vh"}}>
                        <div style={{marginRight: "1.5vw"}}>
                            <Visa/>
                        </div>
                        <div style={{marginRight: "1.5vw"}}>
                            <MasterCard/>
                        </div>
                        <div style={{marginRight: "1.5vw"}}>
                            <AmericanExpress/>
                        </div>
                        <div>
                            <Discovery/>
                        </div>
                    </div>
                    <div style={{fontSize: "1.5vw", color: "#A0A0A0", marginLeft: "3vw", marginTop: "1.5vh"}}>
                        Billing supports the four cards above.
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Cards;