import React from 'react';
import img from './img/img_5.png';
import ImageRoller_Artist from "./Roller_Artist";
import MediaQuery from "react-responsive";

const EventsSlider = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{paddingTop: "6vh", paddingBottom: "6vh", width: "100vw", background: "#F2F2F2"}}>
                    <div style={{display: "flex"}}>
                        <div style={{marginLeft: "3.5vw", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "39vw", marginRight: "3.5vw"}}>
                            <div style={{fontSize: "1.6vw"}}>Exhibitions</div>
                            <div style={{fontSize: "0.9vw"}}>
                                Connect with Isabella Rayne's artistic odyssey, where colors tell tales, emotions come alive, and each stroke is a chapter in the grand narrative of existence.
                            </div>
                        </div>
                        <div style={{marginLeft: "1vw", overflow: "hidden"}}>
                            <ImageRoller_Artist/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{paddingTop: "6vh", paddingBottom: "7vh", width: "100vw", background: "#F2F2F2"}}>
                    <div>
                        <div style={{marginLeft: "6vw", marginRight: "5vw"}}>
                            <div style={{fontSize: "3.5vw"}}>Exhibitions</div>
                            <div style={{fontSize: "2.1vw", marginTop: "7vh", marginBottom: "7vh"}}>
                                Connect with Isabella Rayne's artistic odyssey, where colors tell tales, emotions come alive, and each stroke is a chapter in the grand narrative of existence.
                            </div>
                        </div>
                        <div style={{marginLeft: "15vw", overflow: "hidden"}}>
                            <ImageRoller_Artist/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const ArtistExhibition = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw"}}>
                    <div style={{height: "50vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "40vh", width: "93vw", display: "flex"}}>
                            <img src={img} style={{height: "40vh", marginRight: "3.5vw"}}/>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "0.92vw"}}>
                                <div>
                                    A self-taught artist who believes in the unbounded power of imagination, Isabella seamlessly blends classical techniques with contemporary influences, creating a harmonious juxtaposition that captures the essence of the present while paying homage to the past.
                                    Her artistry dances on the edge of abstract and realism, inviting viewers to explore the nuanced layers of her creations.
                                </div>
                                <div style={{marginTop: "2vh", marginBottom: "2vh"}}>
                                    With a rich palette that spans from ethereal pastels to bold, vibrant hues, Isabella's artworks speak a language of their own.
                                    They reveal her intimate conversations with nature, the echoes of her encounters with bustling cityscapes, and the whispered secrets of the human soul.
                                    Each brushstroke is an invitation to embark on a visual journey, a journey that is both deeply personal and universally relatable.
                                </div>
                                <div>
                                    Isabella believes that art has the power to connect, heal, and provoke thought.
                                    Her masterpieces have graced galleries around the world, leaving an indelible impression on those fortunate enough to experience them.
                                    She continues to push the boundaries of her creativity, forever driven by the desire to encapsulate the transient beauty of life onto her canvases.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "50vh", width: "100vw"}}>
                        <EventsSlider/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw"}}>
                    <div style={{width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "9.5vh", marginBottom: "7.5vh"}}>
                        <img src={img} style={{height: "51vh", width: "100vw"}}/>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "91vw", fontSize: "1.87vw", marginTop: "7vh"}}>
                            <div>
                                A self-taught artist who believes in the unbounded power of imagination, Isabella seamlessly blends classical techniques with contemporary influences, creating a harmonious juxtaposition that captures the essence of the present while paying homage to the past.
                                Her artistry dances on the edge of abstract and realism, inviting viewers to explore the nuanced layers of her creations.
                            </div>
                            <div style={{marginTop: "4vh", marginBottom: "4vh"}}>
                                With a rich palette that spans from ethereal pastels to bold, vibrant hues, Isabella's artworks speak a language of their own.
                                They reveal her intimate conversations with nature, the echoes of her encounters with bustling cityscapes, and the whispered secrets of the human soul.
                                Each brushstroke is an invitation to embark on a visual journey, a journey that is both deeply personal and universally relatable.
                            </div>
                            <div>
                                Isabella believes that art has the power to connect, heal, and provoke thought.
                                Her masterpieces have graced galleries around the world, leaving an indelible impression on those fortunate enough to experience them.
                                She continues to push the boundaries of her creativity, forever driven by the desire to encapsulate the transient beauty of life onto her canvases.
                            </div>
                        </div>
                    </div>
                    <div style={{width: "100vw"}}>
                        <EventsSlider/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ArtistExhibition;