import * as React from 'react';
import img from "./img/img_1.png";
import img_1 from "./img/img_2.png";
import img_2 from "./img/img_3.png";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";
import Twitter from "./MediaIcon/Twitter";
import Instagram from "./MediaIcon/Instagram";
import Facebook from "./MediaIcon/Facebook";
import Linkedin from "./MediaIcon/Linkedin";
import Youtube from "./MediaIcon/Youtube";

function LocationCard_1() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", background: "#F4F4F4"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Los Angeles
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 La Street, Apt
                                    </div>
                                    <div>
                                        Los Angeles, California 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "78vw", marginBottom: "3vh"}}>
                    <div style={{width: "78vw", display: "flex", background: "#F4F4F4"}}>
                        <img src={img} style={{width: "25vw", position: "relative"}}/>
                        <div style={{width: "73%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "2.5vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Los Angeles
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 La Street, Apt
                                    </div>
                                    <div>
                                        Los Angeles, California 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function LocationCard_2() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", background: "#F4F4F4"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img_1} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Chicago
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Chi Street, Apt
                                    </div>
                                    <div>
                                        Chicago, Illinois 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "78vw", marginBottom: "3vh"}}>
                    <div style={{width: "78vw", display: "flex", background: "#F4F4F4"}}>
                        <img src={img_1} style={{width: "25vw", position: "relative"}}/>
                        <div style={{width: "73%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "2.5vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Los Angeles
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 La Street, Apt
                                    </div>
                                    <div>
                                        Los Angeles, California 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function LocationCard_3() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "45vw", height: "25vh"}}>
                    <div style={{width: "45vw", height: "18vh", marginBottom: "7vh", display: "flex", background: "#F4F4F4"}}>
                        <div style={{maxWidth: "12.5vw", height: "18vh", overflow: "hidden", position: "relative"}}>
                            <img src={img_2} style={{height: "100%", position: "relative"}}/>
                        </div>
                        <div style={{width: "73%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "1.16vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Boston
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 Boston Street, Apt
                                    </div>
                                    <div>
                                        Boston, Ma 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "78vw", marginBottom: "3vh"}}>
                    <div style={{width: "78vw", display: "flex", background: "#F4F4F4"}}>
                        <img src={img_2} style={{width: "25vw", position: "relative"}}/>
                        <div style={{width: "73%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                            <div style={{fontSize: "2.5vw"}}>
                                <div style={{color: "#2F2F2F", fontStyle: "italic", marginBottom: "2vh", position: "relative"}}>
                                    Octavate - Los Angeles
                                </div>
                                <div style={{color: "#6B6B6B"}}>
                                    <div>
                                        123 La Street, Apt
                                    </div>
                                    <div>
                                        Los Angeles, California 123456
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

function StayConnected() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "90vw", margin: "auto"}}>
                    <div style={{height: "18vh", display: "flex", alignItems: "center", fontSize: "2.8vw"}}>
                        Stay Connected
                    </div>
                    <Divider style={{color: "#D4D4D4", border: "1px solid", marginBottom: "7vh", width: "100%", position: "relative"}}/>
                    <div style={{display: "flex"}}>
                        <div style={{height: "72vh", width: "45vw"}}>
                            <div >
                                <div style={{marginTop: "1vh", fontSize: "1.3vw"}}>Direct Inquries</div>
                                <div style={{marginTop: "10vh", marginLeft: "2vw", fontSize: "1.2vw"}}>
                                    <div style={{marginBottom: "2.5vh", color: "#2F2F2F"}}>Email</div>
                                    <div style={{color: "#6B6B6B", marginLeft: "1.5vw"}}>
                                        hello@octavate.io
                                    </div>
                                </div>
                                <div style={{marginTop: "10vh", marginLeft: "2vw", fontSize: "1.2vw"}}>
                                    <div style={{marginBottom: "2.5vh", color: "#2F2F2F"}}>Phone Number</div>
                                    <div style={{color: "#6B6B6B", marginLeft: "1.5vw"}}>
                                        +1 (203) 500-4301
                                    </div>
                                </div>
                            </div>
                            <div style={{marginLeft: "2vw", width: "16vw", marginTop: "13vh", display: "flex", justifyContent: "space-between"}}>
                                <Twitter/>
                                <Instagram/>
                                <Facebook/>
                                <Linkedin/>
                                <Youtube/>
                            </div>
                        </div>

                        <div style={{height: "72vh", width: "45vw"}}>
                            <LocationCard_1 />
                            <LocationCard_2 />
                            <LocationCard_3 />
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "10vh"}}>
                    <div style={{width: "83vw", marginTop: "10vh", marginBottom: "7.5vh", display: "flex", alignItems: "center", fontSize: "4.5vw"}}>
                        Stay Connected
                    </div>
                    <Divider style={{color: "#D4D4D4", width: "83vw", border: "1px solid", marginBottom: "7vh", position: "relative"}}/>
                    <div style={{display: "flex", flexDirection: "column", width: "100vw", alignItems: "center"}}>
                        <div style={{width: "83vw"}}>
                            <div>
                                <div>
                                    <div style={{fontSize: "3vw"}}>Direct Inquries</div>
                                    <div style={{marginTop: "8vh", marginLeft: "2vw", fontSize: "2.5vw"}}>
                                        <div style={{marginBottom: "2.5vh", color: "#2F2F2F"}}>Email</div>
                                        <div style={{color: "#6B6B6B", marginLeft: "1.5vw"}}>
                                            hello@octavate.io
                                        </div>
                                    </div>
                                    <div style={{marginTop: "8vh", marginLeft: "2vw", fontSize: "2.5vw"}}>
                                        <div style={{marginBottom: "2.5vh", color: "#2F2F2F"}}>Phone Number</div>
                                        <div style={{color: "#6B6B6B", marginLeft: "1.5vw"}}>
                                            +1 (203) 500-4301
                                        </div>
                                    </div>
                                </div>
                                <div className="social-icons-contact" style={{height: "17vh", width: "27vw", marginLeft: "2vw", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div style={{marginRight: "8vw", whiteSpace: "nowrap"}}>
                                        <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="twitter">
                                            <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                        </a>
                                        <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="instagram">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                        <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="facebook">
                                            <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                        </a>
                                        <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="linkedin">
                                            <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                        </a>
                                        <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="youtube">
                                            <i className="fa fa-youtube-square" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{width: "78vw",marginTop: "3.5vh"}}>
                            <div style={{fontSize: "3vw", marginBottom: "4.5vh"}}>Locations</div>
                            <LocationCard_1 />
                            <LocationCard_2 />
                            <LocationCard_3 />
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default StayConnected;