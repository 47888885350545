import React, {useState} from 'react';
import '../Exhibitions/Exhibition.css'
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import {Box, Button} from "@mui/material";
import img1 from "./img/img_2.png";
import img2 from "./img/img_3.png";
import img3 from "./img/img_4.png";
import img4 from "./img/img_5.png";
import img5 from "./img/img_6.png";
import img6 from "./img/img_7.png";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

function CollectGallery() {
    const [imgStyle_1, setStyle_1] = useState("")
    const [visib_1, setVisibility_1] = useState("hidden")
    const onHover_1 = (e) =>{
        e.preventDefault()
        setVisibility_1("visible")
        setStyle_1("brightness(50%)")
    }
    const onHoverOver_1 = (e) => {
        e.preventDefault()
        setVisibility_1("hidden")
        setStyle_1("brightness(100%)")
    }


    const [imgStyle_2, setStyle_2] = useState("")
    const [visib_2, setVisibility_2] = useState("hidden")
    const onHover_2 = (e) =>{
        e.preventDefault()
        setVisibility_2("visible")
        setStyle_2("brightness(50%)")
    }
    const onHoverOver_2 = (e) => {
        e.preventDefault()
        setVisibility_2("hidden")
        setStyle_2("brightness(100%)")
    }


    const [imgStyle_3, setStyle_3] = useState("")
    const [visib_3, setVisibility_3] = useState("hidden")
    const onHover_3 = (e) =>{
        e.preventDefault()
        setVisibility_3("visible")
        setStyle_3("brightness(50%)")
    }
    const onHoverOver_3 = (e) => {
        e.preventDefault()
        setVisibility_3("hidden")
        setStyle_3("brightness(100%)")
    }


    const [imgStyle_4, setStyle_4] = useState("")
    const [visib_4, setVisibility_4] = useState("hidden")
    const onHover_4 = (e) =>{
        e.preventDefault()
        setVisibility_4("visible")
        setStyle_4("brightness(50%)")
    }
    const onHoverOver_4 = (e) => {
        e.preventDefault()
        setVisibility_4("hidden")
        setStyle_4("brightness(100%)")
    }


    const [imgStyle_5, setStyle_5] = useState("")
    const [visib_5, setVisibility_5] = useState("hidden")
    const onHover_5 = (e) =>{
        e.preventDefault()
        setStyle_5("brightness(50%)")
        setVisibility_5("visible")
    }
    const onHoverOver_5 = (e) => {
        e.preventDefault()
        setVisibility_5("hidden")
        setStyle_5("brightness(100%)")
    }


    const [imgStyle_6, setStyle_6] = useState("")
    const [visib_6, setVisibility_6] = useState("hidden")
    const onHover_6 = (e) =>{
        e.preventDefault()
        setVisibility_6("visible")
        setStyle_6("brightness(50%)")
    }
    const onHoverOver_6 = (e) => {
        e.preventDefault()
        setVisibility_6("hidden")
        setStyle_6("brightness(100%)")
    }

    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "100vh",width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{display:"flex", justifyContent: "space-between", height: "92.8vh",width: "93.58vw"}}>
                        <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "35vw", height: "60vh"}}
                                 onMouseEnter={onHover_1}
                                 onMouseLeave={onHoverOver_1}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img1})`, backgroundSize: "100%", zIndex: -1, width: "35vw", height: "60vh", position: "absolute", filter: imgStyle_1}}>
                                </div>
                            </div>

                            <div style={{width: "35vw", height: "30.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "16.7vw", height: "30.5vh"}}
                                     onMouseEnter={onHover_2}
                                     onMouseLeave={onHoverOver_2}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img4})`, backgroundSize: "100%", zIndex: -1, width: "16.7vw", height: "30.5vh", position: "absolute", filter: imgStyle_2}}>
                                    </div>
                                    <div style={{width: "16vw", height: "23.5vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "4.5vh", marginLeft: "1.7vw", visibility: visib_2}}>
                                        <div style={{fontSize: "1.5vw", fontStyle: "italic"}}>
                                            Our Artists
                                        </div>
                                        <div>
                                            <div style={{fontSize: "1.1vw", marginRight: "3.5vw", marginBottom: "1.5vh"}}>
                                                Explore the visionaries behind the artwork.
                                            </div>
                                            <div style={{display: "flex", justifyContent: "end", marginRight: "3vw"}}>
                                                <Button disableRipple onClick={()=>handleClick('Artists')}
                                                        style={{borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", fontSize: "1vw", paddingLeft: "0.8vw", paddingRight: "0.8vw", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                    Artists
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "16.7vw", height: "30.5vh"}}
                                     onMouseEnter={onHover_3}
                                     onMouseLeave={onHoverOver_3}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img5})`, backgroundSize: "100%", zIndex: -1, width: "16.7vw", height: "30.5vh", position: "absolute", filter: imgStyle_3}}>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "57.3vw", height: "37.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "34vw", height: "37.5vh"}}
                                     onMouseEnter={onHover_4}
                                     onMouseLeave={onHoverOver_4}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img2})`, backgroundSize: "100%", zIndex: -1, width: "34vw", height: "37.5vh", position: "absolute", filter: imgStyle_4}}>
                                    </div>
                                    <div style={{width: "34vw", height: "27vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "7vh", marginLeft: "2.5vw", visibility: visib_4}}>
                                        <div style={{fontSize: "1.65vw", fontStyle: "italic"}}>
                                            Exhibitions
                                        </div>
                                        <div>
                                            <div style={{fontSize: "1.1vw", marginRight: "10vw", marginBottom: "1.5vh"}}>
                                                Immerse yourself in the art with interactive installations that encourage you to become a part of the creative process.
                                            </div>
                                            <div style={{display: "flex", justifyContent: "end", marginRight: "4vw"}}>
                                                <Button disableRipple onClick={()=>handleClick('Exhibitions')}
                                                        style={{borderRadius: 0, backgroundSize: "100%", background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", fontSize: "1vw", paddingLeft: "0.8vw", paddingRight: "0.8vw", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                    Current Events
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "22vw", height: "37.5vh"}}
                                     onMouseEnter={onHover_5}
                                     onMouseLeave={onHoverOver_5}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img3})`, backgroundSize: "100%", zIndex: -1, width: "22vw", height: "37.5vh", position: "absolute", filter: imgStyle_5}}>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "57.3vw", height: "53vh"}}
                                 onMouseEnter={onHover_6}
                                 onMouseLeave={onHoverOver_6}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img6})`, backgroundSize: "100%", zIndex: -1, width: "57.3vw", height: "53vh", position: "absolute", filter: imgStyle_6}}>
                                </div>
                                <div style={{width: "57.3vw", height: "37.5vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "10vh", marginLeft: "2.5vw", visibility: visib_6}}>
                                    <div style={{fontSize: "1.9vw", fontStyle: "italic"}}>
                                        Pieces Curated for You
                                    </div>
                                    <div>
                                        <div style={{fontSize: "1.3vw", marginRight: "18vw", marginBottom: "1.5vh"}}>
                                            Explore the collection, choose your favorite, and let art become an integral part of your world.
                                            Start your journey of owning a piece of your favorite artist’s legacy today.
                                        </div>
                                        <div style={{display: "flex", justifyContent: "end", marginRight: "6vw"}}>
                                            <Button disableRipple onClick={()=>handleClick('Shop')}
                                                    style={{backgroundColor: "transparent", fontSize: "1.16vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", paddingLeft: "0.8vw", paddingRight: "0.8vw", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                Explore Shop
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", flexDirection: "column", height: "170vh",width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{width: "88vw"}}>
                        <div style={{height: "82vh", marginBottom: "5vh", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "88vw", height: "45vh"}}
                                 onMouseEnter={onHover_1}
                                 onMouseLeave={onHoverOver_1}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img1})`, backgroundSize: "100%", zIndex: -1, width: "88vw", height: "45vh", position: "absolute", filter: imgStyle_1}}>
                                </div>
                            </div>

                            <div style={{width: "88vw", height: "32vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "42vw", height: "32vh"}}
                                     onMouseEnter={onHover_2}
                                     onMouseLeave={onHoverOver_2}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img4})`, backgroundSize: "100%", zIndex: -1, width: "42vw", height: "32vh", position: "absolute", filter: imgStyle_2}}>
                                    </div>
                                    <div style={{width: "35vw", height: "25vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "4vh", marginLeft: "3.5vw", visibility: visib_2}}>
                                        <div style={{fontSize: "2.9vw", fontStyle: "italic"}}>
                                            Our Artists
                                        </div>
                                        <div>
                                            <div style={{fontSize: "2.2vw", marginRight: "5vw", marginBottom: "2.5vh"}}>
                                                Explore the visionaries behind the artwork.
                                            </div>
                                            <div style={{display: "flex", justifyContent: "end"}}>
                                                <Button disableRipple onClick={()=>handleClick('Artists')}
                                                        style={{borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", fontSize: "1.8vw", width: "28%", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                    Artists
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "42vw", height: "32vh"}}
                                     onMouseEnter={onHover_3}
                                     onMouseLeave={onHoverOver_3}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img5})`, backgroundSize: "100%", zIndex: -1, width: "42vw", height: "32vh", position: "absolute", filter: imgStyle_3}}>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{height: "82vh", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "88vw", height: "45vh"}}
                                 onMouseEnter={onHover_6}
                                 onMouseLeave={onHoverOver_6}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img6})`, backgroundSize: "100%", zIndex: -1, width: "88vw", height: "45vh", position: "absolute", filter: imgStyle_6}}>
                                </div>
                                <div style={{width: "78vw", height: "35vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "6vh", marginLeft: "3.5vw", visibility: visib_6}}>
                                    <div style={{fontSize: "3.2vw", fontStyle: "italic"}}>
                                        Pieces Curated for You
                                    </div>
                                    <div>
                                        <div style={{fontSize: "2.1vw", marginRight: "15vw", marginBottom: "1.5vh"}}>
                                            Explore the collection, choose your favorite, and let art become an integral part of your world.
                                            Start your journey of owning a piece of your favorite artist’s legacy today.
                                        </div>
                                        <div style={{display: "flex", justifyContent: "end"}}>
                                            <Button disableRipple onClick={()=>handleClick('Shop')}
                                                    style={{backgroundColor: "transparent", fontSize: "1.8vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                Explore Shop
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "88vw", height: "32vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "50vw", height: "33vh"}}
                                     onMouseEnter={onHover_4}
                                     onMouseLeave={onHoverOver_4}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img2})`, backgroundSize: "100%", zIndex: -1, width: "50vw", height: "32vh", position: "absolute", filter: imgStyle_4}}>
                                    </div>
                                    <div style={{width: "43vw", height: "24.5vh", display: "flex", color: "white", flexDirection: "column", justifyContent: "space-between", marginTop: "4vh", marginLeft: "3.5vw", visibility: visib_4}}>
                                        <div style={{fontSize: "2.8vw", fontStyle: "italic"}}>
                                            Exhibitions
                                        </div>
                                        <div>
                                            <div style={{fontSize: "1.7vw", marginRight: "3vw", marginBottom: "0.8vh"}}>
                                                Immerse yourself in the art with interactive installations that encourage you to become a part of the creative process.
                                            </div>
                                            <div style={{display: "flex", justifyContent: "end"}}>
                                                <Button disableRipple onClick={()=>handleClick('Artists')}
                                                        style={{borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", textTransform: "capitalize", fontStyle: "italic", fontSize: "1.6vw", width: "32%", paddingTop: "0.35vh", paddingBottom: "0.35vh"}}>
                                                    Current Events
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "34vw", height: "32vh"}}
                                     onMouseEnter={onHover_5}
                                     onMouseLeave={onHoverOver_5}>
                                    <div className={"img"}
                                         style={{backgroundImage: `url(${img3})`, backgroundSize: "100%", zIndex: -1, width: "34vw", height: "32vh", position: "absolute", filter: imgStyle_5}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

const itemData = [
    {
        img: img1,
        title: 'Breakfast',
        rows: 3,
        cols: 6
    },
    {
        img: img2,
        title: 'Burger',
        cols: 6,
        rows: 2
    },
    {
        img: img3,
        title: 'Camera',
        cols: 4,
        rows: 2
    },
    {
        img: img6,
        title: 'Coffee',
        rows: 3,
        cols: 10
    },
    {
        img: img5,
        title: 'Coffee',
        rows: 2,
        cols: 3
    },
    {
        img: img6,
        title: 'Hats',
        rows: 2,
        cols: 3
    },
];

export default CollectGallery;
