import React from "react";
import img from "./img/img_1.png"
import MediaQuery from "react-responsive";

const OurWork = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw", background: "#F7F7F7"}}>
                    <div style={{ height: "70vh", width: "90vw", paddingTop: "15vh", paddingLeft: "5vw", paddingBottom: "15vh", paddingRight: "5vw",display: "flex", justifyContent: "space-between"}}>
                        <div style={{height: "100%", position: "relative", width: "40%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{fontSize: "2.5vw", color: "#2F2F2F"}}>Our Work</div>
                            <div style={{fontSize: "1.3vw", color: "#2F2F2F"}}>
                                In order to mark this moment in the company’s progress, Octavate is taking the data it has been amassing for over a year and minting a commemorative set of art pieces in its directory, each of which is distinctive and representative of some of the greatest visual artists in human history.
                            </div>
                        </div>
                        <div style={{height: "100%", position: "relative", width: "45%", overflow: "hidden"}}>
                            <img src={img} style={{position: "relative", height: "100%"}}/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", background: "#F7F7F7", paddingBottom: "15vh"}}>
                    <div style={{width: "90vw", paddingTop: "10vh", paddingLeft: "5vw", paddingRight: "5vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{position: "relative", width: "90%"}}>
                            <img src={img} style={{position: "relative", width: "100%"}}/>
                        </div>
                        <div style={{position: "relative", width: "90%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{fontSize: "4.8vw", color: "#2F2F2F", marginTop: "8vh", marginBottom: "17vh"}}>Our Work</div>
                            <div style={{fontSize: "2.73vw", color: "#2F2F2F"}}>
                                In order to mark this moment in the company’s progress, Octavate is taking the data it has been amassing for over a year and minting a commemorative set of art pieces in its directory, each of which is distinctive and representative of some of the greatest visual artists in human history.
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default OurWork;