import ImageRoller_1 from './Roller_1'
import ImageRoller_2 from './Roller_2'
import {Box, Grid} from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import SquareIcon from '@mui/icons-material/Square';
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";
import "./Home.css";

function OctavateGallery() {
    const handleSearch = (event) => {
        console.log(event.target.value);
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <Grid container style={{height: "100vh"}}>
                    <Grid item xs={6}>
                        <div style={{marginLeft: "4vw", marginRight: "3.5vw", marginTop: "2vh"}}>
                            <div style={{textAlign: "right"}}>
                                <TextField
                                    variant="standard"
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    sx={{
                                        "& input::placeholder": { fontSize: "1.1vw", color: "#2F2F2F", fontStyle: "italic", opacity: 1},
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{fontSize: "1.5vw"}}/>
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                        style: {color: "#2F2F2F", fontSize:"1.1vw", fontFamily: "Poppins, sans-serif"}
                                    }}
                                    style={{position: "relative", width: "18%"}}
                                />
                            </div>
                            <div style={{fontStyle:"italic", marginTop:"4.5vh", marginBottom: "5.4vh", fontSize: "1.3vw"}}>Curation based on data visuals</div>
                            <div style={{fontSize: "3.25vw"}}>
                                Octavate Gallery
                            </div>
                            <Divider style={{borderColor: "#2F2F2F", marginTop: "26.5vh", marginBottom: "3vh", width: "30%"}}/>
                            <div style={{fontSize: "1.1vw", marginRight: "12vw"}}>
                                Welcome to Octavate, where art meets inspiration and creativity knows no bounds.
                                Our art gallery is a haven for artists and art enthusiasts alike, dedicated to showcasing a diverse and captivating range of artistic expressions.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={5.8} style={{height: "98.5vh"}}>
                                <ImageRoller_1/>
                            </Grid>
                            <Grid item xs={0.4}></Grid>
                            <Grid item xs={5.8} style={{height: "98.5vh"}}>
                                <ImageRoller_2/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", height: "5vh", display: "flex", justifyContent: "end", alignItems: "end"}}>
                    <TextField
                        variant="standard"
                        placeholder="Search"
                        onChange={handleSearch}
                        sx={{
                            "& input::placeholder": { fontSize: "1.8vw", color: "#2F2F2F", fontStyle: "italic", opacity: 1},
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{fontSize: "2.2vw"}}/>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                            style: {color: "#2F2F2F", fontSize:"1.8vw", fontFamily: "Poppins, sans-serif"}
                        }}
                        style={{position: "relative", width: "13%"}}
                    />
                </div>
                <div style={{display: "flex", height: "23vh", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{fontStyle:"italic", marginBottom: "2.3vh", fontSize: "2vw"}}>
                            Curation based on data visuals
                        </div>
                        <div style={{fontSize: "4.8vw"}}>
                            Octavate’s Private Gallery
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{height: "28.5vh"}}>
                        <ImageRoller_1/>
                    </div>
                    <div>
                        <ImageRoller_2/>
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "7vh", marginBottom: "6vh"}}>
                    <SquareIcon style={{transform: "rotate(45deg)", color: "#DCDCDC", fontSize: "1.5vw"}}/>
                    <svg height="2px" width="55vw">
                        <line x1="0" y1="0" x2="55vw" y2="0" style={{stroke: "#DCDCDC", strokeWidth: "4px"}}/>
                    </svg>
                    <SquareIcon style={{transform: "rotate(45deg)", color: "#DCDCDC", fontSize: "1.5vw"}}/>
                </div>
                <div style={{width: "100vw", display: "flex", justifyContent: "center", marginBottom: "17vh"}}>
                    <div style={{fontSize: "2vw", width: "80vw", textAlign: "center"}}>
                        Welcome to Octavate, where art meets inspiration and creativity knows no bounds.
                        Our art gallery is a haven for artists and art enthusiasts alike, dedicated to showcasing a diverse and captivating range of artistic expressions.
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default OctavateGallery;