import {Button} from "@mui/material";
import React from "react";
import Slider from "./Slider/Slider";
import NotificationIcon from "./Icons/NotificationIcon";
import MediaQuery from "react-responsive";
import AccountIcon from "./Icons/AccountIcon";

const Notif = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "5vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.8vh"}}>
                            <div style={{height: "2.1vw", width: "2.1vw", marginLeft: "0.6vw"}}>
                                <NotificationIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Notifications</div>
                        </div>
                        <div style={{height: "75%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "1.15vw", marginBottom: "1.5vh", color: "#666666"}}>General Notifications</div>
                                    <div style={{color:" #858585", fontSize: "0.9vw", width: "88%"}}>
                                        Stay informed about events, promotions, and changes on the platform.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "1.1vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "1.1vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "1.15vw", marginBottom: "1.5vh", color: "#666666"}}>Event Reminders</div>
                                    <div style={{color:" #858585", fontSize: "0.9vw", width: "88%"}}>
                                        Receive reminders for upcoming art exhibitions, workshops, and events.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "1.1vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "1.1vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "1.15vw", marginBottom: "1.5vh", color: "#666666"}}>Account Security Alerts</div>
                                    <div style={{color:" #858585", fontSize: "0.9vw", width: "88%"}}>
                                        Get notified about suspicious activities or logins to enhance your account security.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "1.1vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "1.1vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "1.15vw", marginBottom: "1.5vh", color: "#666666"}}>Newsletter Subscriptions</div>
                                    <div style={{color:" #858585", fontSize: "0.9vw", width: "88%"}}>
                                        Choose to receive newsletters about art trends, artist spotlights, and exclusive offers.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "1.1vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "1.1vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "0.6vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "5vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "43%", background: "#545454", borderRadius: 0, fontSize: "1vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "80vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.5vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <NotificationIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Notifications</div>
                        </div>
                        <div style={{height: "75%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "2.25vw", marginBottom: "1.5vh", color: "#666666"}}>General Notifications</div>
                                    <div style={{color:" #858585", fontSize: "1.68vw", width: "72%"}}>
                                        Stay informed about events, promotions, and changes on the platform.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "2.2vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "2.2vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "2.25vw", marginBottom: "1.5vh", color: "#666666"}}>Event Reminders</div>
                                    <div style={{color:" #858585", fontSize: "1.68vw", width: "72%"}}>
                                        Receive reminders for upcoming art exhibitions, workshops, and events.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "2.2vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "2.2vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "2.25vw", marginBottom: "1.5vh", color: "#666666"}}>Account Security Alerts</div>
                                    <div style={{color:" #858585", fontSize: "1.68vw", width: "72%"}}>
                                        Get notified about suspicious activities or logins to enhance your account security.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "2.2vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "2.2vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    <div style={{fontSize: "2.25vw", marginBottom: "1.5vh", color: "#666666"}}>Newsletter Subscriptions</div>
                                    <div style={{color:" #858585", fontSize: "1.68vw", width: "72%"}}>
                                        Choose to receive newsletters about art trends, artist spotlights, and exclusive offers.
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: "flex", fontSize: "2.2vw"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>SMS</span>
                                    </div>
                                    <div style={{display: "flex", fontSize: "2.2vw", marginTop: "1.5vh"}}>
                                        <Slider/>
                                        <span style={{marginLeft: "1.2vw", color: "#575757"}}>Email</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "4.5vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "35%", background: "#545454", borderRadius: 0, fontSize: "1.6vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Notif;