import React from 'react';
import './Home.css'
import img_1 from "./img/img_event1.png";
import img_2 from "./img/img_event2.png";
import img_3 from "./img/img_event3.png";
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";

const EventCard_1 = () => {
    return (
        <div style={{height: "33vh", width: "58vw", backgroundImage: `url(${img_1})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
            <div style={{height: "17.5vh", width: "37vw", background: "rgba(47,47,47, 0.5)"}}>
                <div style={{marginTop: "1.5vh", marginLeft: "1.8vw"}}>
                    <div style={{fontSize: "2.5vw", color: "white"}}>
                        Etheral Exhibition
                    </div>
                    <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "1.5vw"}}>
                        with featured artwork by Kate Evans
                    </div>
                    <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "white"}}>
                        July 5th @ MoMA on the 2nd Floor
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "13vw", fontSize: "1.35vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white", textTransform: "none"}}>Reserve a Ticket</Button>
                </div>
            </div>
        </div>
    )
}

const EventCard_3 = () => {
    return (
        <div style={{height: "33vh", width: "58vw", backgroundImage: `url(${img_3})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
            <div style={{height: "17.5vh", width: "37vw", background: "rgba(47,47,47, 0.5)"}}>
                <div style={{marginTop: "1.5vh", marginLeft: "1.8vw"}}>
                    <div style={{fontSize: "2.5vw", color: "white"}}>
                        Etheral Exhibition
                    </div>
                    <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "1.5vw"}}>
                        with featured artwork by Kate Evans
                    </div>
                    <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "white"}}>
                        July 5th @ MoMA on the 2nd Floor
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "13vw", fontSize: "1.35vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white", textTransform: "none"}}>Reserve a Ticket</Button>
                </div>
            </div>
        </div>
    )
}

const EventCard_2 = () => {
    return (
        <div style={{height: "33vh", width: "58vw", backgroundImage: `url(${img_2})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
            <div style={{height: "17.5vh", width: "37vw", background: "rgba(242,242,242, 0.5)"}}>
                <div style={{marginTop: "1.5vh", marginLeft: "1.8vw"}}>
                    <div style={{fontSize: "2.5vw", color: "2F2F2F"}}>
                        Etheral Exhibition
                    </div>
                    <div style={{color: "#424242", fontStyle: "italic", fontSize: "1.5vw"}}>
                        with featured artwork by Kate Evans
                    </div>
                    <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "#2F2F2F"}}>
                        July 5th @ MoMA on the 2nd Floor
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "13vw", fontSize: "1.35vw", border: "solid #2F2F2F 0.3px", borderRadius: 0, color: "#2F2F2F", textTransform: "none"}}>Reserve a Ticket</Button>
                </div>
            </div>
        </div>
    )
}

const imageUrls = [
    <EventCard_1/>,
    <EventCard_2/>,
    <EventCard_3/>
];

const ImageRoller_3_mobile = () => {
    const doubledImages = [...imageUrls, ...imageUrls];
    return (
        <div className="image-roller-container_2">
            <div className="image-roller" id={"roller_4"}>
                {doubledImages.map((elem, index) => (
                    <div style={{marginRight: "2vw"}}>
                        {elem}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageRoller_3_mobile;