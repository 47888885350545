import React from "react";
import img from "./img/img_viewCollect.png"
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const ViewCollection = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "140vh", width: "100vw", marginTop: "20vh", display: "flex", justifyContent: "center"}}>
                    <div>
                        <div style={{maxWidth: "88vw", overflow: "hidden"}}>
                            <img src={img} style={{height: "90vh"}}/>
                        </div>
                        <div style={{fontSize: "3.5vw", marginTop: "6vh", marginBottom: "4vh", color: "#2F2F2F"}}>
                            The Collection
                        </div>
                        <div style={{fontSize: "1.3vw", width: "45vw"}}>
                            Witness the unveiling of the artists' souls through their passion-fueled creations, connecting with the core of their being.
                        </div>
                        <Button disableRipple onClick={()=>handleClick('Collect')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "3.5vh", fontSize: "1.1vw", borderRadius: 0, border: "1px black solid", color: "#2F2F2F"}}>View Now</Button>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{paddingBottom: "9vh", width: "100vw"}}>
                    <div style={{marginTop:"17vh"}}>
                        <img src={img} style={{width: "100vw"}}/>
                        <div style={{marginTop: "6vh", marginLeft: "7vw"}}>
                            <div style={{fontSize: "4.7vw", marginBottom: "5vh", color: "#2F2F2F"}}>
                                The Collection
                            </div>
                            <div style={{fontSize: "2.5vw", marginRight: "10vw"}}>
                                Witness the unveiling of the artists' souls through their passion-fueled creations, connecting with the core of their being.
                            </div>
                            <Button disableRipple onClick={()=>handleClick('Collect')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "4vh", fontSize: "2vw", borderRadius: 0, border: "1px black solid", color: "#2F2F2F", width: "15vw"}}>View Now</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ViewCollection;