import React, {useEffect, useState} from 'react';
import './Exhibition.css'
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import {Box} from "@mui/material";
import img1 from "./img/img_1.png";
import img2 from "./img/img_2.png";
import img3 from "./img/img_3.png";
import img4 from "./img/img_4.png";
import img5 from "./img/img_5.png";
import img6 from "./img/img_6.png";
import MediaQuery from "react-responsive";

function Gallery() {
    const rand = Math.floor(Math.random()*6);
    const visibilities = ["hidden", "hidden", "hidden", "hidden", "hidden", "hidden"];
    visibilities[rand] = "visible";

    const styles = ["", "", "", "", "", ""];
    styles[rand] = "brightness(50%)";

    const [imgStyle_1, setStyle_1] = useState(styles[0])
    const [visib_1, setVisibility_1] = useState(visibilities[0])
    const onHover_1 = (e) =>{
        e.preventDefault()
        setVisibility_1("visible")
        setStyle_1("brightness(50%)")

        setVisibility_2("hidden")
        setVisibility_3("hidden")
        setVisibility_4("hidden")
        setVisibility_5("hidden")
        setVisibility_6("hidden")

        setStyle_2("brightness(100%)")
        setStyle_3("brightness(100%)")
        setStyle_4("brightness(100%)")
        setStyle_5("brightness(100%)")
        setStyle_6("brightness(100%)")
    }
    const onHoverOver_1 = (e) => {
        e.preventDefault()
        setVisibility_1("hidden")
        setStyle_1("brightness(100%)")
    }


    const [imgStyle_2, setStyle_2] = useState(styles[1])
    const [visib_2, setVisibility_2] = useState(visibilities[1])
    const onHover_2 = (e) =>{
        e.preventDefault()
        setVisibility_2("visible")
        setStyle_2("brightness(50%)")

        setVisibility_1("hidden")
        setVisibility_3("hidden")
        setVisibility_4("hidden")
        setVisibility_5("hidden")
        setVisibility_6("hidden")

        setStyle_1("brightness(100%)")
        setStyle_3("brightness(100%)")
        setStyle_4("brightness(100%)")
        setStyle_5("brightness(100%)")
        setStyle_6("brightness(100%)")
    }
    const onHoverOver_2 = (e) => {
        e.preventDefault()
        setVisibility_2("hidden")
        setStyle_2("brightness(100%)")
    }


    const [imgStyle_3, setStyle_3] = useState(styles[2])
    const [visib_3, setVisibility_3] = useState(visibilities[2])
    const onHover_3 = (e) =>{
        e.preventDefault()
        setVisibility_3("visible")
        setStyle_3("brightness(50%)")

        setVisibility_1("hidden")
        setVisibility_2("hidden")
        setVisibility_4("hidden")
        setVisibility_5("hidden")
        setVisibility_6("hidden")

        setStyle_1("brightness(100%)")
        setStyle_2("brightness(100%)")
        setStyle_4("brightness(100%)")
        setStyle_5("brightness(100%)")
        setStyle_6("brightness(100%)")
    }
    const onHoverOver_3 = (e) => {
        e.preventDefault()
        setVisibility_3("hidden")
        setStyle_3("brightness(100%)")
    }


    const [imgStyle_4, setStyle_4] = useState(styles[3])
    const [visib_4, setVisibility_4] = useState(visibilities[3])
    const onHover_4 = (e) =>{
        e.preventDefault()
        setVisibility_4("visible")
        setStyle_4("brightness(50%)")

        setVisibility_1("hidden")
        setVisibility_2("hidden")
        setVisibility_3("hidden")
        setVisibility_5("hidden")
        setVisibility_6("hidden")

        setStyle_1("brightness(100%)")
        setStyle_2("brightness(100%)")
        setStyle_3("brightness(100%)")
        setStyle_5("brightness(100%)")
        setStyle_6("brightness(100%)")
    }
    const onHoverOver_4 = (e) => {
        e.preventDefault()
        setVisibility_4("hidden")
        setStyle_4("brightness(100%)")
    }


    const [imgStyle_5, setStyle_5] = useState(styles[4])
    const [visib_5, setVisibility_5] = useState(visibilities[4])
    const onHover_5 = (e) =>{
        e.preventDefault()
        setStyle_5("brightness(50%)")
        setVisibility_5("visible")

        setVisibility_1("hidden")
        setVisibility_2("hidden")
        setVisibility_3("hidden")
        setVisibility_4("hidden")
        setVisibility_6("hidden")

        setStyle_1("brightness(100%)")
        setStyle_2("brightness(100%)")
        setStyle_3("brightness(100%)")
        setStyle_4("brightness(100%)")
        setStyle_6("brightness(100%)")
    }
    const onHoverOver_5 = (e) => {
        e.preventDefault()
        setVisibility_5("hidden")
        setStyle_5("brightness(100%)")
    }


    const [imgStyle_6, setStyle_6] = useState(styles[5])
    const [visib_6, setVisibility_6] = useState(visibilities[5])
    const onHover_6 = (e) =>{
        e.preventDefault()
        setVisibility_6("visible")
        setStyle_6("brightness(50%)")

        setVisibility_1("hidden")
        setVisibility_2("hidden")
        setVisibility_3("hidden")
        setVisibility_4("hidden")
        setVisibility_5("hidden")

        setStyle_1("brightness(100%)")
        setStyle_2("brightness(100%)")
        setStyle_3("brightness(100%)")
        setStyle_4("brightness(100%)")
        setStyle_5("brightness(100%)")
    }
    const onHoverOver_6 = (e) => {
        e.preventDefault()
        setVisibility_6("hidden")
        setStyle_6("brightness(100%)")
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display:"flex", justifyContent: "space-around", height: "100vh",width: "100vw"}}>
                    <div style={{display:"flex", flexDirection: "column", justifyContent: "space-around"}}>
                        <div style={{width: "40.7vw", height: "58vh"}}
                             onMouseEnter={onHover_1}
                             onMouseLeave={onHoverOver_1}>
                            <div className={"img"}
                                 style={{backgroundImage: `url(${img1})`, backgroundSize: "100%", zIndex: -1, width: "40.7vw", height: "58vh", position: "absolute", filter: imgStyle_1}}>
                            </div>
                            <div style={{width: "40.7vw", height: "58vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_1}}>
                                <div style={{fontSize: "1.3vw"}}>
                                    New Artist Showcase
                                </div>
                                <div style={{fontSize: "2vw"}}>
                                    08.05.23
                                </div>
                            </div>
                        </div>

                        <div style={{width: "40.7vw", height: "38vh", display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "19vw", height: "38vh"}}
                                 onMouseEnter={onHover_2}
                                 onMouseLeave={onHoverOver_2}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img4})`, backgroundSize: "100%", zIndex: -1, width: "19vw", height: "38vh", position: "absolute", filter: imgStyle_2}}>
                                </div>
                                <div style={{width: "19vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_2}}>
                                    <div style={{fontSize: "1.3vw"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "2vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "19vw", height: "38vh"}}
                                 onMouseEnter={onHover_3}
                                 onMouseLeave={onHoverOver_3}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img5})`, backgroundSize: "100%", zIndex: -1, width: "19vw", height: "38vh", position: "absolute", filter: imgStyle_3}}>
                                </div>
                                <div style={{width: "19vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_3}}>
                                    <div style={{fontSize: "1.3vw"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "2vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{display:"flex", flexDirection: "column", justifyContent: "space-around"}}>
                        <div style={{width: "55vw", height: "38vh", display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "32vw", height: "38vh"}}
                                 onMouseEnter={onHover_4}
                                 onMouseLeave={onHoverOver_4}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img2})`, backgroundSize: "100%", Index: -1, width: "32vw", height: "38vh", position: "absolute", filter: imgStyle_4}}>
                                </div>
                                <div style={{width: "32vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_4}}>
                                    <div style={{fontSize: "1.3vw"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "2vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "22vw", height: "38vh"}}
                                 onMouseEnter={onHover_5}
                                 onMouseLeave={onHoverOver_5}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img3})`, backgroundSize: "100%", zIndex: -1, width: "22vw", height: "38vh", position: "absolute", filter: imgStyle_5}}>
                                </div>
                                <div style={{width: "22vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_5}}>
                                    <div style={{fontSize: "1.3vw"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "2vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "55vw", height: "58vh"}}
                             onMouseEnter={onHover_6}
                             onMouseLeave={onHoverOver_6}>
                            <div className={"img"}
                                 style={{backgroundImage: `url(${img6})`, backgroundSize: "100%", zIndex: -1, width: "55vw", height: "58vh", position: "absolute", filter: imgStyle_6}}>
                            </div>
                            <div style={{width: "55vw", height: "58vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_6}}>
                                <div style={{fontSize: "1.3vw"}}>
                                    New Artist Showcase
                                </div>
                                <div style={{fontSize: "2vw"}}>
                                    08.05.23
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "column", width: "100vw"}}>
                    <div style={{display:"flex", flexDirection: "column", justifyContent: "space-around"}}>
                        <div style={{width: "94vw", height: "58vh", marginBottom: "2vh"}}
                             onMouseEnter={onHover_1}
                             onMouseLeave={onHoverOver_1}>
                            <div className={"img"}
                                 style={{backgroundImage: `url(${img1})`, backgroundSize: "100%", zIndex: -1, width: "94vw", height: "58vh", position: "absolute", filter: imgStyle_1}}>
                            </div>
                            <div style={{width: "94vw", height: "58vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_1}}>
                                <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                    New Artist Showcase
                                </div>
                                <div style={{fontSize: "4vw"}}>
                                    08.05.23
                                </div>
                            </div>
                        </div>

                        <div style={{width: "94vw", height: "38vh", display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "46vw", height: "38vh"}}
                                 onMouseEnter={onHover_2}
                                 onMouseLeave={onHoverOver_2}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img4})`, backgroundSize: "100%", zIndex: -1, width: "46vw", height: "38vh", position: "absolute", filter: imgStyle_2}}>
                                </div>
                                <div style={{width: "46vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_2}}>
                                    <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "4vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "46vw", height: "38vh"}}
                                 onMouseEnter={onHover_3}
                                 onMouseLeave={onHoverOver_3}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img5})`, backgroundSize: "100%", zIndex: -1, width: "46vw", height: "38vh", position: "absolute", filter: imgStyle_3}}>
                                </div>
                                <div style={{width: "46vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_3}}>
                                    <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "4vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{display:"flex", flexDirection: "column", justifyContent: "space-around", marginTop: "2vh"}}>
                        <div style={{width: "94vw", height: "58vh", marginBottom: "2vh"}}
                             onMouseEnter={onHover_4}
                             onMouseLeave={onHoverOver_4}>
                            <div className={"img"}
                                 style={{backgroundImage: `url(${img6})`, backgroundSize: "100%", zIndex: -1, width: "94vw", height: "58vh", position: "absolute", filter: imgStyle_4}}>
                            </div>
                            <div style={{width: "94vw", height: "58vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_4}}>
                                <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                    New Artist Showcase
                                </div>
                                <div style={{fontSize: "4vw"}}>
                                    08.05.23
                                </div>
                            </div>
                        </div>

                        <div style={{width: "94vw", height: "38vh", display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "51vw", height: "38vh"}}
                                 onMouseEnter={onHover_5}
                                 onMouseLeave={onHoverOver_5}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img2})`, backgroundSize: "100%", zIndex: -1, width: "51vw", height: "38vh", position: "absolute", filter: imgStyle_5}}>
                                </div>
                                <div style={{width: "51vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_5}}>
                                    <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "4vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "41vw", height: "38vh"}}
                                 onMouseEnter={onHover_6}
                                 onMouseLeave={onHoverOver_6}>
                                <div className={"img"}
                                     style={{backgroundImage: `url(${img3})`, backgroundSize: "100%", zIndex: -1, width: "41vw", height: "38vh", position: "absolute", filter: imgStyle_6}}>
                                </div>
                                <div style={{width: "41vw", height: "38vh", display: "flex", color: "white", textAlign: "center", flexDirection: "column", justifyContent: "center", visibility: visib_6}}>
                                    <div style={{fontSize: "2vw", marginBottom: "2vh"}}>
                                        New Artist Showcase
                                    </div>
                                    <div style={{fontSize: "4vw"}}>
                                        08.05.23
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

const itemData = [
    {
        img: img1,
        title: 'Breakfast',
        rows: 3,
        cols: 6
    },
    {
        img: img2,
        title: 'Burger',
        cols: 6,
        rows: 2
    },
    {
        img: img3,
        title: 'Camera',
        cols: 4,
        rows: 2
    },
    {
        img: img6,
        title: 'Coffee',
        rows: 3,
        cols: 10
    },
    {
        img: img5,
        title: 'Coffee',
        rows: 2,
        cols: 3
    },
    {
        img: img6,
        title: 'Hats',
        rows: 2,
        cols: 3
    },
];

export default Gallery;
