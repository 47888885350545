import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React from "react";
import MediaQuery from "react-responsive";
import img from "./img/img.png";

const AccountBar = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{background: "#F3F3F3", height: "89vh", width: "30vw"}}>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "3vw", marginTop: "4.5vh", marginBottom: "6vh", color: "#575757"}}>
                        <AccountCircleOutlinedIcon style={{fontSize: "3.5vw"}}/>
                        <div style={{fontSize: "1.8vw", marginLeft: "2vw"}}>Welcome, Name</div>
                    </div>
                    <div style={{marginLeft: "5.5vw", height: "55vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <SettingsOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Dashboard</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <AccountCircleOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Manage Account</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <LockResetOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Change Password</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <NotificationsNoneOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Notification</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <MonetizationOnOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Billing and Payment</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <InsertPhotoOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Collection</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                            <HelpOutlineOutlinedIcon style={{fontSize: "2.1vw"}}/>
                            <div style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>Help and Feedback</div>
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "3vw", marginTop: "10vh", color: "#575757"}}>
                        <LogoutOutlinedIcon style={{fontSize: "2.4vw"}}/>
                        <div style={{fontSize: "1.5vw", marginLeft: "0.3vw"}}>Log Out</div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F3F3F3", height: "68vh", width: "100vw"}}>
                    <img src={img} style={{height: "19vh", width: "100vw", filter: "brightness(50%)"}}/>
                    <div style={{display: "flex", alignItems: "center", marginTop: "2vh", marginLeft: "3vw", marginBottom: "4vh"}}>
                        <AccountCircleOutlinedIcon sx={{
                            strokeWidth: 0.5,
                            stroke: "#F3F3F3",
                            color: "#575757",
                            fontSize: "7.8vw",
                            marginRight: "2vw"
                        }}/>
                        <div style={{fontSize: "3.5vw", color: "#575757"}}>Welcome, Name</div>
                    </div>
                    <div style={{width: "100vw", display: "flex", justifyContent: "center"}}>
                        <div style={{width: "76vw", display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757", marginBottom: "2.5vh"}}>
                                    <SettingsOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Dashboard</div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757", marginBottom: "2.5vh"}}>
                                    <AccountCircleOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Manage Account</div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757", marginBottom: "2.5vh"}}>
                                    <LockResetOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Change Password</div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                                    <NotificationsNoneOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Notification</div>
                                </div>
                            </div>
                            <div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757", marginBottom: "2.5vh"}}>
                                    <MonetizationOnOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Billing and Payment</div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757", marginBottom: "2.5vh"}}>
                                    <InsertPhotoOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Collection</div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", color: "#575757"}}>
                                    <HelpOutlineOutlinedIcon sx={{
                                        strokeWidth: 0.7,
                                        stroke: "#F3F3F3",
                                        color: "#575757",
                                        fontSize: "6vw",
                                    }}/>
                                    <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Help and Feedback</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", position: "relative", alignItems: "center", color: "#575757", marginLeft: "75vw", bottom: "3vh"}}>
                        <LogoutOutlinedIcon sx={{
                            strokeWidth: 0.7,
                            stroke: "#F3F3F3",
                            color: "#575757",
                            fontSize: "6vw",
                        }}/>
                        <div style={{fontSize: "2.6vw", marginLeft: "1.8vw"}}>Log Out</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default AccountBar;