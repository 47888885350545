import "./RadioButton.css";

const Radio = () => {
    return (
        <div>
            <div style={{display: "flex"}}>
                <div className="radio">
                    <input className="radio-input" type="radio" id="m-option" name="selector"/>
                    <label className="radio-label" htmlFor="m-option">Male</label>
                    <div className="check"></div>
                </div>
                <div className="radio">
                    <input className="radio-input" type="radio" id="f-option" name="selector"/>
                    <label className="radio-label" htmlFor="f-option">Female</label>
                    <div className="check"></div>
                </div>
            </div>
            <div style={{display: "flex"}}>
                <div className="radio">
                    <input className="radio-input" type="radio" id="nb-option" name="selector"/>
                    <label className="radio-label" htmlFor="nb-option">Non Binary</label>
                    <div className="check"></div>
                </div>
                <div className="radio">
                    <input className="radio-input" type="radio" id="other-option" name="selector"/>
                    <label className="radio-label" htmlFor="other-option">Other</label>
                    <div className="check"></div>
                </div>
            </div>
        </div>
    )
}

export default Radio;