import React from 'react';
import img from './img/img_1.png';
import MediaQuery from "react-responsive";

const AboutArtistArt = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh", width: "100vw"}}>
                    <div style={{height: "50vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "42vh", width: "92vw", display: "flex", justifyContent: "space-between"}}>
                            <div style={{width: "42vw", display: "flex", flexDirection: "column", justifyContent: "space-between", fontSize: "0.92vw"}}>
                                <div style={{fontSize: "2vw"}}>
                                    About the Artist
                                </div>
                                <div style={{fontSize: "1.2vw", color: "#808080"}}>
                                    Artist's Name draws inspiration from the ever-changing landscapes of life, from the tranquility of nature's colors to the complexity of human experiences.
                                    Each of their artworks serves as a visual diary, capturing moments of introspection, joy, and wonderment.
                                </div>
                            </div>
                            <div style={{width: "40vw", overflow: "hidden"}}>
                                <img src={img} style={{height: "42vh"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "102vh", width: "100vw"}}>
                    <div style={{height: "50vh", width: "100vw", marginTop: "9vh"}}>
                        <div style={{width: "100vw"}}>
                            <img src={img} style={{height: "55vh", width: "100%"}}/>
                            <div style={{marginLeft: "6vw", marginTop: "6.5vh", marginRight: "15vw", display: "flex", flexDirection: "column", justifyContent: "space-between", fontSize: "0.92vw"}}>
                                <div style={{fontSize: "3.5vw", marginBottom: "10vh"}}>
                                    About the Artist
                                </div>
                                <div style={{fontSize: "2.3vw", color: "#808080"}}>
                                    Artist's Name draws inspiration from the ever-changing landscapes of life, from the tranquility of nature's colors to the complexity of human experiences.
                                    Each of their artworks serves as a visual diary, capturing moments of introspection, joy, and wonderment.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>

    )
}

export default AboutArtistArt;