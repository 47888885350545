import React from 'react';
import MediaQuery from "react-responsive";

const TermOfUse = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "300vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "3.7vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Agreement to the Terms of Use</div>
                    </div>
                    <div style={{height: "270vh", width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{height: "270vh", width: "23vw", fontWeight: "bold", fontSize: "1.4vw"}}>
                            Effective Date: 7/4/2023
                        </div>
                        <div style={{height: "270vh", width: "59vw"}}>
                            <div style={{height: "25vh", fontSize: "1.3vw"}}>
                                Welcome to Octavate! These terms and conditions ("Terms") govern your access to and use of our website [www.octavate.io] (the "Website"). Please read these Terms carefully before using our Website. By accessing or using our Website, you agree to be bound by these Terms and our Privacy Policy.
                            </div>
                            <div style={{height: "235vh", width: "59vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>1. Acceptance of Terms</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        By accessing or using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Website.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>2. Intellectual Property</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        All content on our Website, including text, graphics, logos, images, videos, and software, is the property of Octavate or its licensors and is protected by copyright and other intellectual property laws.
                                        You may not use, reproduce, modify, distribute, or display any content from our Website without our prior written consent.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>3. User Contact</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        When using our Website, you agree to comply with all applicable laws and regulations. You may not engage in any conduct that is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.
                                        You also agree not to interfere with the operation or security of our Website or attempt to gain unauthorized access to any part of our Website.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>4. Third Party Links</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        Our Website may contain links to third-party websites or resources.
                                        These links are provided for your convenience only and do not imply any endorsement or responsibility for the content, products, or services offered by these third parties.
                                        We are not responsible for the availability or accuracy of these third-party websites, nor do we have any control over their privacy practices.
                                        Your use of third-party websites is at your own risk.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>5. Disclaimer of Warranties</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        Our Website is provided on an "as is" and "as available" basis.
                                        We make no representations or warranties of any kind, express or implied, regarding the operation or availability of our Website, or the accuracy, reliability, or completeness of any content on our Website.
                                        You use our Website at your own risk.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>6. Limitation of Liability</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        To the fullest extent permitted by law, Octavate and its officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of our Website.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>7. Changes to Terms</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our Website.
                                        Your continued use of our Website after the posting of any changes constitutes your acceptance of such changes.
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontSize: "1.7vw", fontWeight: "bold", marginBottom: "4vh"}}>8. Governing Law and Jurisdiction</div>
                                    <div style={{fontSize: "1.3vw"}}>
                                        These Terms shall be governed by and construed in accordance with the laws of New York, NY.
                                        Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of New York, NY.
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: "6.5vh"}}>
                                <div style={{fontSize: "1.3vw"}}>If you have any questions or concerns about these Terms, please contact us at</div>
                                <div style={{fontSize: "1.3vw", fontWeight: "bold"}}>hello@octavate.io.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "296vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "5vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Agreement to the Terms of Use</div>
                    </div>
                    <div style={{width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{width: "29vw", fontWeight: "bolder", fontSize: "2.1vw"}}>
                            Effective Date: 7/4/2023
                        </div>
                        <div style={{width: "59vw"}}>
                            <div style={{height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.1vw"}}>
                                    Welcome to Octavate! These terms and conditions ("Terms") govern your access to and use of our website [www.octavate.io] (the "Website").
                                </div>
                                <div style={{fontSize: "2.1vw"}}>
                                    Please read these Terms carefully before using our Website. By accessing or using our Website, you agree to be bound by these Terms and our Privacy Policy.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{height: "240vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>1. Acceptance of Terms</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    By accessing or using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Website.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>2. Intellectual Property</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    All content on our Website, including text, graphics, logos, images, videos, and software, is the property of Octavate or its licensors and is protected by copyright and other intellectual property laws.
                                    You may not use, reproduce, modify, distribute, or display any content from our Website without our prior written consent.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>3. User Contact</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    When using our Website, you agree to comply with all applicable laws and regulations. You may not engage in any conduct that is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.
                                    You also agree not to interfere with the operation or security of our Website or attempt to gain unauthorized access to any part of our Website.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>4. Third Party Links</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    Our Website may contain links to third-party websites or resources.
                                    These links are provided for your convenience only and do not imply any endorsement or responsibility for the content, products, or services offered by these third parties.
                                    We are not responsible for the availability or accuracy of these third-party websites, nor do we have any control over their privacy practices.
                                    Your use of third-party websites is at your own risk.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>5. Disclaimer of Warranties</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    Our Website is provided on an "as is" and "as available" basis.
                                    We make no representations or warranties of any kind, express or implied, regarding the operation or availability of our Website, or the accuracy, reliability, or completeness of any content on our Website.
                                    You use our Website at your own risk.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>6. Limitation of Liability</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    To the fullest extent permitted by law, Octavate and its officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of our Website.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>7. Changes to Terms</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our Website.
                                    Your continued use of our Website after the posting of any changes constitutes your acceptance of such changes.
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: "3.2vw", fontWeight: "bold", marginBottom: "4vh"}}>8. Governing Law and Jurisdiction</div>
                                <div style={{fontSize: "2.1vw"}}>
                                    These Terms shall be governed by and construed in accordance with the laws of New York, NY.
                                    Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of New York, NY.
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{fontSize: "2.1vw"}}>If you have any questions or concerns about these Terms, please contact us at</div>
                            <div style={{fontSize: "2.1vw", fontWeight: "bold"}}>hello@octavate.io.</div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default TermOfUse;