import * as React from 'react';
import {Box, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const About = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{border: "solid 40px #F1F1F1"}}>
                    <div style={{display: "flex", width: "90vw", height: "40vh"}}>
                        <div style={{width: "40vw", marginRight: "10vw", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "35%"}}>
                                        <div style={{fontSize: "1.5vw"}}>Artist</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <div style={{fontSize: "1vw"}}>Artist Name</div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "35%"}}>
                                        <div style={{fontSize: "1.5vw"}}>Artwork</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <div style={{fontSize: "1vw"}}>Ethereal Reverie</div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "35%"}}>
                                        <div style={{fontSize: "1.5vw"}}>Date</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <div style={{fontSize: "1vw"}}>August 4, 2023</div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "35%"}}>
                                        <div style={{fontSize: "1.5vw"}}>Credit</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <div style={{fontSize: "1vw"}}>Oil on Canvas, 24 inches x 36 inches, Abstract Contemporary, Octavate Private Gallery Collection</div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                        <div style={{width: "40vw", height: "40vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "2.2vw", marginTop: "1.5vh"}}>About the Artwork</div>
                            </div>
                            <div>
                                <p style={{color: "#808080"}}>
                                    "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space.
                                    With each stroke, the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{border: "solid 40px #F1F1F1"}}>
                    <div style={{width: "90vw", height: "100vh"}}>
                        <div style={{width: "70vw", height: "35.5vh", marginBottom: "12.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "3.5vw", marginTop: "1.5vh"}}>About the Artwork</div>
                            </div>
                            <div>
                                <p style={{color: "#808080"}}>
                                    "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space.
                                    With each stroke, the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                                </p>
                            </div>
                        </div>
                        <div style={{width: "78vw", height: "45vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "30%"}}>
                                        <div style={{fontSize: "2.5vw"}}>Artist</div>
                                    </div>
                                    <div style={{width: "63%"}}>
                                        <p style={{color: "#808080"}}>Artist Name</p>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "30%"}}>
                                        <div style={{fontSize: "2.5vw"}}>Artwork</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <p style={{color: "#808080"}}>Ethereal Reverie</p>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "30%"}}>
                                        <div style={{fontSize: "2.5vw"}}>Date</div>
                                    </div>
                                    <div style={{width: "65%"}}>
                                        <p style={{color: "#808080"}}>August 4, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{width: "30%"}}>
                                        <div style={{fontSize: "2.5vw"}}>Credit</div>
                                    </div>
                                    <div style={{width: "70%"}}>
                                        <p style={{color: "#808080"}}>Oil on Canvas, 24 inches x 36 inches, Abstract Contemporary, Octavate Private Gallery Collection</p>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const AboutArtPiece = () => {
    return (
        <Box style={{background: "#F1F1F1"}}>
            <About/>
        </Box>
    )
}
export default AboutArtPiece;