import React from 'react';
import {useNavigate} from "react-router-dom";
import img from "../About/img/img_2.png";
import FooterBar from "./FooterBar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from '@mui/icons-material/Email';
import MediaQuery from "react-responsive";

const Footer = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    const handleSearch = (event) => {
        console.log(event.target.value);
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh", borderTop: "1px #D9D9D9 solid", width: "100vw", display: "flex", justifyContent: "center"}}>
                    <div style={{height: "45vh", marginTop: "7vh", width: "94vw", display: "flex", justifyContent: "space-between"}}>
                        <div style={{height: "36vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{fontSize: "1.45vw", height: "5vh", fontWeight: "lighter"}}>Connect with Octavate</div>
                            <div style={{height: "7vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{display: "flex"}}>
                                    <div style={{fontSize: "1vw", marginRight: "0.3vw", color: "#3C3C3C"}}>Email:</div>
                                    <div style={{color: "#6B6B6B", fontSize: "1vw"}}>hello@octavate.io</div>
                                </div>
                                <div style={{display: "flex"}}>
                                    <div style={{fontSize: "1vw", marginRight: "0.3vw", color: "#3C3C3C"}}>Phone Number: </div>
                                    <div style={{color: "#6B6B6B", fontSize: "1vw"}}>+1 (800) 123-467</div>
                                </div>
                            </div>
                            <div style={{height: "14.3vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "1vw"}}>Location:</div>
                                <div style={{width: "20vw", height: "10vh"}}>
                                    <div style={{width: "20vw", height: "10vh", display: "flex", background:"#F4F4F4"}}>
                                        <div style={{width: "8vw", height: "10vh", position: "relative"}}>
                                            <img src={img} style={{width: "100%", height: "100%", position: "relative"}}/>
                                        </div>
                                        <div style={{width: "70%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                                            <div>
                                                <div style={{fontSize: "0.8vw", color: "#2F2F2F", fontStyle: "italic", marginBottom: "0.7vh", position: "relative"}}>
                                                    Octavate - New York City
                                                </div>
                                                <div style={{fontSize: "0.7vw", color: "#6B6B6B"}}>
                                                    <div>
                                                        764 Temple St.
                                                    </div>
                                                    <div>
                                                        New York, NY 11743
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div style={{fontSize: "1.4vw", height: "7.5vh", color: "#2F2F2F"}}>Log in</div>
                            <div style={{height: "25vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('Register')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Register</div>
                                <div onClick={()=>handleClick('Login')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Login in</div>
                                <div style={{fontSize: "1vw", color: "white"}}>a</div>
                                <div style={{fontSize: "1vw", color: "white"}}>a</div>
                                <div style={{fontSize: "1vw", color: "white"}}>a</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div style={{fontSize: "1.4vw", height: "7.5vh", color: "#2F2F2F"}}>Who We Are</div>
                            <div style={{height: "25vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('About')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>About Us</div>
                                <div onClick={()=>handleClick('ContactUs')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Contact Us</div>
                                <div style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Staff</div>
                                <div style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Press</div>
                                <div onClick={()=>handleClick('Video')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Video</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div style={{fontSize: "1.4vw", height: "7.5vh", color: "#2F2F2F"}}>Collect</div>
                            <div style={{height: "25vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('Shop')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Shop</div>
                                <div onClick={()=>handleClick('Artists')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Artists</div>
                                <div onClick={()=>handleClick('Exhibitions')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Exhibitions</div>
                                <div style={{fontSize: "1vw", color: "white"}}>a</div>
                                <div style={{fontSize: "1vw", color: "white"}}>a</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div style={{fontSize: "1.4vw", height: "7.5vh", color: "#2F2F2F"}}>Support</div>
                            <div style={{height: "25vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('FAQ')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>FAQ</div>
                                <div onClick={()=>handleClick('Setting')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Settings</div>
                                <div style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>My Account</div>
                                <div style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Website Accessibility</div>
                                <div style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "1vw", color: "#6B6B6B"}}>Culture</div>
                            </div>
                        </div>
                        <div style={{height: "32vh", width: "19.5vw"}}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", height: "5.5vh"}}>
                                    <svg width="2.4vw" height="2.4vw">
                                        <rect x="0" y="0" width="2.4vw" height="2.4vw" stroke="white" fill="#2D2D2D"/>
                                        <circle cx="1.2vw" cy="1.2vw" r="0.85vw" stroke="white" strokeWidth="0.2vw" fill="#2D2D2D" />
                                    </svg>
                                    <div style={{marginLeft: "1vw", color: "#2F2F2F"}}>
                                        <div style={{fontSize: "2.2vw"}}>OCTAVATE</div>
                                    </div>
                                </div>
                                <div style={{position: "relative", top: "-0.7vh", fontSize: "0.9vw", height: "2vh", marginLeft: "5.4vw"}}>Audio Art Dealer.</div>
                            </div>
                            <div style={{marginTop: "4vh", height: "11vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "1.25vw", color: "#2F2F2F"}}>
                                    Subscribe
                                </div>
                                <div style={{fontSize: "0.9vw", color: "#565656", width: "15vw"}}>
                                    Enter your email to get notified about Octavate updates.
                                </div>
                            </div>
                            <TextField
                                variant="standard"
                                placeholder="Email"
                                sx={{
                                    "& input::placeholder": { fontSize: "0.75vw", color: "#7D7D7D", opacity: 1},
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon style={{fontSize: "1.5vw"}}/>
                                        </InputAdornment>
                                    ),
                                    style: {color: "#2F2F2F", background: "#F1F1F1",  height: "100%", paddingLeft: "0.6vw", paddingRight: "0.75vw", fontSize:"0.75vw", fontFamily: "Poppins, sans-serif"}
                                }}
                                style={{position: "relative", marginTop: "7vh", width: "90%", height: "13%"}}
                            />
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "77vh", borderTop: "2px #D9D9D9 solid", width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around"}}>
                    <div style={{width: "84vw", display: "flex", justifyContent: "space-between"}}>
                        <div style={{height: "31.6vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{fontSize: "3vw", height: "5vh", fontWeight: "lighter"}}>Connect with Octavate</div>
                            <div style={{height: "7vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{display: "flex"}}>
                                    <div style={{fontSize: "2vw", marginRight: "0.3vw", color: "#3C3C3C"}}>Email:</div>
                                    <div style={{color: "#6B6B6B", fontSize: "2vw"}}>hello@octavate.io</div>
                                </div>
                                <div style={{display: "flex"}}>
                                    <div style={{fontSize: "2vw", marginRight: "0.3vw", color: "#3C3C3C"}}>Phone Number: </div>
                                    <div style={{color: "#6B6B6B", fontSize: "2vw"}}>+1 (203) 500-4301</div>
                                </div>
                            </div>
                            <div style={{height: "14.3vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2vw"}}>Location:</div>
                                <div style={{width: "20vw", height: "10vh"}}>
                                    <div style={{width: "39vw", height: "10vh", display: "flex", background:"#F4F4F4"}}>
                                        <div style={{width: "18vw", height: "10vh", position: "relative"}}>
                                            <img src={img} style={{width: "100%", height: "100%", position: "relative"}}/>
                                        </div>
                                        <div style={{width: "70%", height: "100%", position: "relative", display: "flex", alignItems: "center", marginLeft: "4.5%"}}>
                                            <div>
                                                <div style={{fontSize: "1.6vw", color: "#2F2F2F", fontStyle: "italic", marginBottom: "0.7vh", position: "relative"}}>
                                                    Octavate - New York City
                                                </div>
                                                <div style={{fontSize: "1.35vw", color: "#6B6B6B"}}>
                                                    <div>
                                                        372 Fifth Avenue, 3J
                                                    </div>
                                                    <div>
                                                        New York, NY 100018
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <svg width="6vw" height="6vw">
                                        <rect x="0" y="0" width="6vw" height="6vw" stroke="white" fill="#2D2D2D"/>
                                        <circle cx="3vw" cy="3vw" r="2vw" stroke="white" strokeWidth="0.4vw" fill="#2D2D2D" />
                                    </svg>
                                    <div style={{marginLeft: "1vw", color: "#2F2F2F"}}>
                                        <div style={{fontSize: "4.5vw"}}>OCTAVATE</div>
                                    </div>
                                </div>
                                <div style={{position: "relative", top: "-0.7vh", fontSize: "1.9vw", height: "2vh", marginLeft: "11vw"}}>Audio Art Dealer.</div>
                            </div>
                            <div style={{marginTop: "3vh", height: "11vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.7vw", color: "#2F2F2F"}}>
                                    Subscribe
                                </div>
                                <div style={{fontSize: "2vw", color: "#565656", width: "35vw"}}>
                                    Enter your email to get notified about Octavate updates.
                                </div>
                            </div>
                            <TextField
                                variant="standard"
                                placeholder="Email"
                                sx={{
                                    "& input::placeholder": { fontSize: "1.8vw", color: "#7D7D7D", opacity: 1},
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon style={{fontSize: "2.5vw"}}/>
                                        </InputAdornment>
                                    ),
                                    style: {color: "#2F2F2F", background: "#F1F1F1",  height: "100%", paddingLeft: "0.6vw", paddingRight: "0.75vw", fontSize:"1.8vw", fontFamily: "Poppins, sans-serif"}
                                }}
                                style={{position: "relative", marginTop: "5vh", width: "35vw", height: "13%"}}
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", width: "84vw", justifyContent: "space-between"}}>
                        <div style={{height: "29.5vh", width: "23vw"}}>
                            <div style={{fontSize: "3vw", height: "7.5vh", color: "#2F2F2F"}}>Who We Are</div>
                            <div style={{height: "22vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('About')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>About Us</div>
                                <div onClick={()=>handleClick('ContactUs')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Contact Us</div>
                                <div style={{display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Staff</div>
                                <div style={{display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Press</div>
                                <div onClick={()=>handleClick('Video')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Video</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh", width: "18vw"}}>
                            <div style={{fontSize: "3vw", height: "7.5vh", color: "#2F2F2F"}}>Collect</div>
                            <div style={{height: "22vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('Shop')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Shop</div>
                                <div onClick={()=>handleClick('Artists')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Artists</div>
                                <div onClick={()=>handleClick('Exhibitions')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Exhibitions</div>
                                <div style={{fontSize: "2vw", color: "white"}}>a</div>
                                <div style={{fontSize: "2vw", color: "white"}}>a</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh", width: "24vw"}}>
                            <div style={{fontSize: "3vw", height: "7.5vh", color: "#2F2F2F"}}>Support</div>
                            <div style={{height: "22vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('FAQ')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>FAQ</div>
                                <div onClick={()=>handleClick('Setting')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Settings</div>
                                <div style={{display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>My Account</div>
                                <div style={{display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Website Accessibility</div>
                                <div style={{display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Culture</div>
                            </div>
                        </div>
                        <div style={{height: "29.5vh"}}>
                            <div style={{fontSize: "3vw", height: "7.5vh", color: "#2F2F2F"}}>Log in</div>
                            <div style={{height: "22vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div onClick={()=>handleClick('Register')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Register</div>
                                <div onClick={()=>handleClick('Login')} style={{cursor: "pointer", display: "flex", alignItems: "end", fontSize: "2vw", color: "#6B6B6B"}}>Login in</div>
                                <div style={{fontSize: "2vw", color: "white"}}>a</div>
                                <div style={{fontSize: "2vw", color: "white"}}>a</div>
                                <div style={{fontSize: "2vw", color: "white"}}>a</div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <div>
                <FooterBar/>
            </div>
        </div>
    );
};

export default Footer;