import Box from "@mui/material/Box";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import {Button} from "@mui/material";
import React from "react";
import logo_1 from "./img/img_1.png";
import logo_2 from "./img/img_2.png";
import ImageIcon from "./Icons/ImageIcon";
import MediaQuery from "react-responsive";
import AccountIcon from "./Icons/AccountIcon";

const Colls = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "8vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "1.7vh"}}>
                            <div style={{height: "2.5vw", width: "2.5vw", marginLeft: "0.4vw"}}>
                                <ImageIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Collection</div>
                        </div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "5.5vh", marginLeft: "0.5vw"}}>
                                <BookmarkBorderOutlinedIcon style={{fontSize: "1.6vw", marginRight: "1.2vw", color: "#B0B0B0"}}/>
                                <div style={{color: "#575757", fontSize: "1.2vw"}}>View Saved</div>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "5vh", marginLeft: "0.5vw"}}>
                                <FavoriteBorderOutlinedIcon style={{fontSize: "1.6vw", marginRight: "1.2vw", color: "#B0B0B0"}}/>
                                <div style={{color: "#575757", fontSize: "1.2vw"}}>View Saved</div>
                            </div>
                        </div>
                        <div style={{marginLeft: "0.5vw"}}>
                            <div style={{color:" #2F2F2F", marginTop: "8vh", fontSize: "1.2vw", marginBottom: "5vh"}}>Pieces You Own</div>
                            <div style={{display: "flex", justifyContent: "start"}}>
                                <div style={{width: "45%", marginRight: "0.5vw"}}>
                                    <div>
                                        <img src={logo_2} style={{width: "90%", height: "90%"}}/>
                                        <div style={{color: "#575757", fontStyle: "italic", fontSize: "0.9vw", marginTop: "1.1vh"}}>Artist Name</div>
                                        <div style={{color: "#575757", fontSize: "1vw"}}>Natural Fade</div>
                                    </div>
                                </div>
                                <div style={{width: "45%", marginRight: "3vw"}}>
                                    <div>
                                        <img src={logo_1} style={{width: "90%", height: "90%"}}/>
                                        <div style={{color: "#575757", fontStyle: "italic", fontSize: "0.9vw", marginTop: "1.1vh"}}>Artist Name</div>
                                        <div style={{color: "#575757", fontSize: "1vw"}}>Natural Fade</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "right", marginTop: "5vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "32%",  color: "#545454", padding: 0, border: "#C3C3C3 1px solid", background: "transparent", borderRadius: 0, fontSize: "1vw"}}>Go To Shop</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "80vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.5vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <ImageIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Collection</div>
                        </div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "5.5vh", marginLeft: "0.5vw"}}>
                                <BookmarkBorderOutlinedIcon style={{fontSize: "3vw", marginRight: "1.2vw", color: "#B0B0B0"}}/>
                                <div style={{color: "#575757", fontSize: "2vw"}}>View Saved</div>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "5vh", marginLeft: "0.5vw"}}>
                                <FavoriteBorderOutlinedIcon style={{fontSize: "3vw", marginRight: "1.2vw", color: "#B0B0B0"}}/>
                                <div style={{color: "#575757", fontSize: "2vw"}}>View Saved</div>
                            </div>
                        </div>
                        <div style={{marginLeft: "0.5vw"}}>
                            <div style={{color:" #2F2F2F", marginTop: "8vh", fontSize: "2.3vw", fontWeight: "bold", marginBottom: "5vh"}}>Pieces You Own</div>
                            <div style={{display: "flex", justifyContent: "start"}}>
                                <div style={{width: "42%"}}>
                                    <div>
                                        <img src={logo_2} style={{width: "90%", height: "18vh"}}/>
                                        <div style={{color: "#575757", fontStyle: "italic", fontSize: "1.4vw", marginTop: "1.1vh"}}>Artist Name</div>
                                        <div style={{color: "#575757", fontSize: "1.8vw"}}>Natural Fade</div>
                                    </div>
                                </div>
                                <div style={{width: "42%", marginRight: "1vw"}}>
                                    <div>
                                        <img src={logo_1} style={{width: "90%", height: "18vh"}}/>
                                        <div style={{color: "#575757", fontStyle: "italic", fontSize: "1.4vw", marginTop: "1.1vh"}}>Artist Name</div>
                                        <div style={{color: "#575757", fontSize: "1.8vw"}}>Natural Fade</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "right", marginTop: "8vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "32%",  color: "#545454", padding: 0, border: "#C3C3C3 1px solid", background: "transparent", borderRadius: 0, fontSize: "1.8vw"}}>Go To Shop</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Colls;