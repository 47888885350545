import React from "react";
import img_1 from "./img/img_5.png";
import img_2 from "./img/img_6.png";
import img_3 from "./img/img_7.png";
import img_4 from "./img/img_8.png";
import img_1_mobile from "./img/img_feature1_mobile.png"
import img_2_mobile from "./img/img_feature2_mobile.png"
import MediaQuery from "react-responsive";

function featureCollection() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{background: 'black', color:'white', height: "75vh", paddingLeft: "3vw", paddingRight: "3vw", paddingTop: "6vh"}}>
                    <div style={{fontSize: '1.8vw'}}>
                        Our Featured Collection
                    </div>
                    <div style={{marginTop: "10vh", marginBottom: "8vh", marginRight: "34vw", fontSize: "1vw"}}>
                        Explore a rich tapestry of artistic styles that span across the ages, from classical masterpieces to contemporary wonders.
                        Our gallery embraces diversity, offering a platform for both established artists and emerging talents to shine.
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{maxWidth: "22vw", overflow: "hidden"}}>
                            <img
                                alt={"image"}
                                src={img_1}
                                loading="lazy"
                                style={{height: "35vh"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "0.75vw"}}>Artist Name</div>
                                    <div style={{fontSize: "0.9vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "1.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                        <div style={{maxWidth: "22vw", overflow: "hidden"}}>
                            <img
                                alt={"image"}
                                src={img_2}
                                loading="lazy"
                                style={{height: "35vh"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "0.75vw"}}>Artist Name</div>
                                    <div style={{fontSize: "0.9vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "1.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                        <div style={{maxWidth: "22vw", overflow: "hidden"}}>
                            <img
                                alt={"image"}
                                src={img_3}
                                loading="lazy"
                                style={{height: "35vh"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "0.75vw"}}>Artist Name</div>
                                    <div style={{fontSize: "0.9vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "1.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                        <div style={{maxWidth: "22vw", overflow: "hidden"}}>
                            <img
                                alt={"image"}
                                src={img_4}
                                loading="lazy"
                                style={{height: "35vh"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "0.75vw"}}>Artist Name</div>
                                    <div style={{fontSize: "0.9vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "1.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: 'black', color:'white', paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "4.5vh", paddingBottom: "7vh"}}>
                    <div style={{fontSize: '4.8vw'}}>
                        Our Featured Collection
                    </div>
                    <div style={{marginTop: "2vh", marginBottom: "8vh", marginRight: "20vw", fontSize: "1.7vw"}}>
                        Explore a rich tapestry of artistic styles that span across the ages, from classical masterpieces to contemporary wonders.
                        Our gallery embraces diversity, offering a platform for both established artists and emerging talents to shine.
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <img
                                alt={"image"}
                                src={img_1_mobile}
                                style={{width: "43.8vw"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "1.5vw"}}>Artist Name</div>
                                    <div style={{fontSize: "1.8vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "2.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                alt={"image"}
                                src={img_2_mobile}
                                style={{width: "43.8vw"}}
                            />
                            <div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "1.5vw"}}>Artist Name</div>
                                    <div style={{fontSize: "1.8vw"}}>$233</div>
                                </div>
                                <div style={{fontSize: "2.1vw"}}>
                                    Art Piece Title
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const itemData = [
    {
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    },
    {
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    },
];

export default featureCollection;