import {Button, TextareaAutosize} from "@mui/material";
import React from "react";
import HelpIcon from "./Icons/HelpIcon";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MediaQuery from "react-responsive";
import ImageIcon from "./Icons/ImageIcon";

const Help = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "8vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "3.5vh"}}>
                            <div style={{height: "2vw", width: "2vw", marginLeft: "0.4vw"}}>
                                <HelpIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Help and Feedback</div>
                        </div>
                        <div>
                            <div style={{fontSize: "1.3vw", fontWeight: "bold", color: "#4D4C4C", width: "70%"}}>
                                We appreciate your feedback
                            </div>
                            <div style={{color: "#575757", fontSize: "1vw", marginTop: "4vh", width: "88%"}}>
                                Use our feedback form to share your thoughts.
                                Your input matters!
                                We also have an FAQ page linked here if you have any specific questions.
                            </div>
                            <div>
                        <span style={{position: "relative", top: "27vh", left: "21.5vw", color: "#666666"}}>
                            <BorderColorOutlinedIcon style={{fontSize: "1.5vw"}}/>
                        </span>
                                <TextareaAutosize
                                    style={{width: "90%", height: "25vh", border: "none", resize: "none", fontSize: "0.9vw", paddingLeft: "1vw", paddingTop: "2vh", paddingRight: "1vw"}}
                                    placeholder="Have ideas or feedback? Let us know how we can improve your experience."
                                />
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "2vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "1.3vw"}}>Submit</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "80vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.5vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <HelpIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Help and Feedback</div>
                        </div>
                        <div>
                            <div style={{fontSize: "2.6vw", fontWeight: "bold", color: "#4D4C4C", width: "70%"}}>
                                We appreciate your feedback
                            </div>
                            <div style={{color: "#575757", fontSize: "2vw", marginTop: "4vh", width: "88%"}}>
                                Use our feedback form to share your thoughts.
                                Your input matters!
                                We also have an FAQ page linked here if you have any specific questions.
                            </div>
                            <div>
                        <span style={{position: "relative", top: "30vh", left: "46vw", color: "#666666"}}>
                            <BorderColorOutlinedIcon style={{fontSize: "2.5vw"}}/>
                        </span>
                                <TextareaAutosize
                                    style={{width: "92%", height: "28vh", border: "none", resize: "none", fontSize: "1.9vw", paddingLeft: "2vw", paddingTop: "2vh", paddingRight: "2vw"}}
                                    placeholder="Have ideas or feedback? Let us know how we can improve your experience."
                                />
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "2vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "2vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Help;