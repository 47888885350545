import React from "react";
import CuratedRoller from "./CuratedRoller";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const CuratedForYou = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "70vh", marginTop: "9vh"}}>
                    <div style={{fontSize: "2.5vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "7.5vh", marginLeft: "3vw"}}>
                        Curated for You
                    </div>
                    <div>
                        <div style={{overflow: "hidden"}}>
                            <CuratedRoller/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginTop: "9vh", marginBottom: "9vh"}}>
                    <div style={{fontSize: "3vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "7vh", marginLeft: "3vw"}}>
                        Curated for You
                    </div>
                    <div>
                        <div style={{overflow: "hidden"}}>
                            <CuratedRoller/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default CuratedForYou;