import {Button, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import MediaQuery from "react-responsive";

const Lang = () => {
    return (
        <div style={{height: "100%", width: "100%"}}>
            <div style={{height: "49%", backgroundColor: "transparent", display: "flex", alignItems: "center"}}>
                <MediaQuery minWidth={1024}>
                    <div style={{fontSize: "0.85vw", marginLeft: "18px", color: "#575757"}}>Preferred Language</div>
                </MediaQuery>
                <MediaQuery maxWidth={1024}>
                    <div style={{fontSize: "1.5vw", marginLeft: "14px", color: "#575757"}}>Preferred Language</div>
                </MediaQuery>
            </div>
            <MediaQuery minWidth={1024}>
                <Select
                    sx={{boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0}}}
                    style={{fontSize: "0.85vw", color: "#575757", backgroundColor: "#E6E6E6", height: "49%", width: "100%", borderRadius: 0}} >
                    <MenuItem value={1} style={{fontSize: "0.85vw", color: "#575757", paddingLeft: "0.4vw"}}>English</MenuItem>
                    <MenuItem value={2} style={{fontSize: "0.85vw", color: "#575757", paddingLeft: "0.4vw"}}>Spanish</MenuItem>
                </Select>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <Select
                    sx={{boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0}}}
                    style={{fontSize: "1.5vw", color: "#575757", backgroundColor: "#E6E6E6", height: "49%", width: "100%", borderRadius: 0}} >
                    <MenuItem value={1} style={{fontSize: "1.5vw", color: "#575757", paddingLeft: "0.4vw"}}>English</MenuItem>
                    <MenuItem value={2} style={{fontSize: "1.5vw", color: "#575757", paddingLeft: "0.4vw"}}>Spanish</MenuItem>
                </Select>
            </MediaQuery>
        </div>
    )
}

const Region = () => {
    return (
        <div style={{height: "100%", width: "100%"}}>
            <div style={{height: "49%", backgroundColor: "transparent", display: "flex", alignItems: "center"}}>
                <MediaQuery minWidth={1024}>
                    <div style={{fontSize: "0.85vw", marginLeft: "18px", color: "#575757"}}>Region</div>
                </MediaQuery>
                <MediaQuery maxWidth={1024}>
                    <div style={{fontSize: "1.5vw", marginLeft: "14px", color: "#575757"}}>Region</div>
                </MediaQuery>
            </div>
            <MediaQuery minWidth={1024}>
                <Select
                    sx={{boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0}}}
                    style={{fontSize: "0.85vw", color: "#575757", backgroundColor: "#E6E6E6", height: "49%", width: "100%", borderRadius: 0}} >
                    <MenuItem value={1} style={{fontSize: "0.85vw", color: "#575757", paddingLeft: "0.4vw"}}>United State</MenuItem>
                    <MenuItem value={2} style={{fontSize: "0.85vw", color: "#575757", paddingLeft: "0.4vw"}}>Canada</MenuItem>
                </Select>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <Select
                    sx={{boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0}}}
                    style={{fontSize: "1.5vw", color: "#575757", backgroundColor: "#E6E6E6", height: "49%", width: "100%", borderRadius: 0}} >
                    <MenuItem value={1} style={{fontSize: "1.5vw", color: "#575757", paddingLeft: "0.4vw"}}>United States</MenuItem>
                    <MenuItem value={2} style={{fontSize: "1.5vw", color: "#575757", paddingLeft: "0.4vw"}}>Canada</MenuItem>
                </Select>
            </MediaQuery>
        </div>
    )
}

const LangAndReg = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%", marginTop: "3.5vh"}}>
                    <div style={{width: "55%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "1.23vw", color: "#575757"}}>
                            Language and Region
                        </div>
                        <div>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "1vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div style={{width: "45%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "43%", width: "99%", border: "#AAAAAA 1px solid"}}>
                            <Lang/>
                        </div>
                        <div style={{height: "43%", width: "99%", border: "#AAAAAA 1px solid"}}>
                            <Region/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%", marginTop: "3.5vh"}}>
                    <div style={{width: "55%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "2.25vw", color: "#575757"}}>
                            Language and Region
                        </div>
                        <div>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "2vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div style={{width: "45%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "43%", width: "99%", border: "#AAAAAA 1px solid"}}>
                            <Lang/>
                        </div>
                        <div style={{height: "43%", width: "99%", border: "#AAAAAA 1px solid"}}>
                            <Region/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default LangAndReg;