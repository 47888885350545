import React from "react";
import ImageRoller_3 from "./Roller_3";
import MediaQuery from "react-responsive";

const HomeEventsSlider = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{fontSize: "3vw", marginBottom: "10vh", marginTop: "10vh", marginLeft: "5vw"}}>Upcoming Events</div>
                <div style={{display: "flex"}}>
                    <div style={{writingMode: "vertical-rl", textAlign: "center", transform: "scale(-1, -1)", fontSize: "28px", fontStyle: "italic", marginLeft: "10vw"}}>
                        Events in July
                    </div>
                    <div style={{marginLeft: "1vw", width: "86vw"}}>
                        <ImageRoller_3/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{fontSize: "4.3vw", marginBottom: "4vh", marginTop: "9.5vh", marginLeft: "5vw"}}>Upcoming Events</div>
                <div style={{textAlign: "center", fontSize: "3.3vw",  marginBottom: "4.5vh", fontStyle: "italic"}}>
                    Events in July
                </div>
                <div style={{width: "100vw", display: "flex", justifyContent: "center"}}>
                    <ImageRoller_3/>
                </div>
            </MediaQuery>
        </div>
    )
}

export default HomeEventsSlider;