import * as React from 'react';

import img1 from "../Home/img/img_trending1.png";
import img2 from "../Home/img/img_trending2.png";
import img3 from "../Home/img/img_trending3.png";
import img4 from "../Home/img/img_trending4.png";
import img5 from "../Home/img/img_trending5.png";
import img6 from "../Home/img/img_trending6.png";
import img7 from "../Home/img/img_trending7.png";
import img8 from "../Home/img/img_trending8.png";
import MediaQuery from "react-responsive";

const RenownedArtist = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{background: "#F4F4F4"}}>
                    <div style={{fontSize: "2vw", fontStyle: "italic", color: "#2F2F2F", textAlign: "center", paddingTop: "4vh"}}>Reowned Artists</div>
                    <div style={{width: "100vw"}}>
                        <div style={{marginBottom: "2.5vh", width: "88vw", display: "flex", margin: "auto", marginTop: "6vh", justifyContent: "space-between", color: "#2F2F2F"}}>
                            <div style={{width: "18.7vw"}}>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img1} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img5} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "18.7vw"}}>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img2} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img6} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "18.7vw"}}>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img3} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img7} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "18.7vw"}}>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img4} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div style={{width: "18.7vw"}}>
                                    <img src={img8} style={{width: "19vw"}}/>
                                    <div style={{height: "4.4vw"}}>
                                        <div style={{fontSize: "0.5vw"}}>Artist Name</div>
                                        <div style={{fontSize: "1vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{background: "#F4F4F4"}}>
                    <div style={{fontSize: "3vw", fontStyle: "italic", color: "#2F2F2F", textAlign: "center", paddingTop: "4vh"}}>Reowned Artists</div>
                    <div style={{width: "100vw"}}>
                        <div style={{width: "88vw", display: "flex", margin: "auto", marginTop: "4vh", flexDirection: "column", color: "#2F2F2F"}}>
                            <div style={{display: "flex", justifyContent: "space-between", width: "88vw"}}>
                                <div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img1} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img5} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img2} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img6} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", width: "88vw"}}>
                                <div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img3} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img7} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img4} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                    <div style={{marginBottom: "3.8vh"}}>
                                        <img src={img8} style={{width: "41vw"}}/>
                                        <div style={{fontSize: "1.3vw"}}>Artist Name</div>
                                        <div style={{fontSize: "2vw"}}>Art Piece Title</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const itemData = [
    {
        img: img1,
        title: 'Breakfast',
        rows: 2,
    },
    {
        img: img2,
        title: 'Burger',
        rows: 1,
    },
    {
        img: img3,
        title: 'Camera',
        rows: 2,
    },
    {
        img: img4,
        title: 'Coffee',
        rows: 1,
    },
    {
        img: img6,
        title: 'Hats',
        rows: 2,
    },
    {
        img: img8,
        title: 'Honey',
        rows: 2,
    },
    {
        img: img5,
        title: 'Basketball',
        rows: 1,
    },
    {
        img: img7,
        title: 'Fern',
        rows: 1,
    },
];

export default RenownedArtist;