import React from 'react';
import Grid from '@mui/material/Grid';
import img_ex from "./img/img_ex.png"
import img_ex_mobile from "./img/img_ex_mobile.png"
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const Feature = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw"}}>
                    <div style={{height: "40vh", width: "80vw", marginTop: "5vh", marginLeft: "10vw", display: "flex", alignItems: "center"}}>
                        <div style={{width: "55vw", fontSize: "1vw"}}>
                            <p>
                                If you would like to keep a piece of the exhibitions with you, explore our shop.
                                Browse through a curated selection of our artists’ artworks, meticulously chosen to resonate with art enthusiasts and collectors like you.
                                From vibrant landscapes that transport you to new horizons, to intricate portraits that hold a gaze of endless emotion, there's a masterpiece that speaks to your heart.
                            </p>
                        </div>
                        <Button disableRipple onClick={()=>handleClick('Shop')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "1vw", borderRadius: 0, background: "#343434", color: "white", width: "10vw", marginLeft: "8.5vw"}}>Browse Shop</Button>
                    </div>

                    <Grid container style={{marginTop: "5vh", height: "40vh", width: "80vw", marginLeft: "10vw", background: "#F2F2F2"}}>
                        <Grid item xs={5} style={{overflow: "hidden"}}>
                            <img alt="Your Image" src={img_ex} style={{height:"40vh"}}/>
                        </Grid>
                        <Grid item xs={0.2}>
                        </Grid>
                        <Grid item xs={6.8}>
                            <div>
                                <div style={{marginTop: "4.2vh", fontSize: "0.8vw", fontStyle: "italic"}}>FEATURE OF THE WEEK</div>
                                <div style={{fontSize: "2vw"}}>Ethereal Reverie Exhibition</div>
                                <div style={{marginTop: "11vh", marginRight: "4vw", fontSize: "0.8vw"}}>
                                    <div>
                                        "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space. With each stroke,
                                        the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", marginBottom: "7.5vh"}}>
                    <div style={{width: "100vw", marginTop: "12vh", display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <div style={{width: "94vw", fontSize: "2.4vw"}}>
                            <div style={{marginBottom: "5vh"}}>
                                If you would like to keep a piece of the exhibitions with you, explore our shop.
                                Browse through a curated selection of our artists’ artworks, meticulously chosen to resonate with art enthusiasts and collectors like you.
                            </div>
                            <div>
                                From vibrant landscapes that transport you to new horizons, to intricate portraits that hold a gaze of endless emotion, there's a masterpiece that speaks to your heart.
                            </div>
                            <div style={{width: "88vw", justifyContent: "end", display: "flex"}}>
                                <Button disableRipple onClick={()=>handleClick('Shop')} variant="outlined" style={{backgroundColor: "transparent", textTransform: "capitalize", fontSize: "2vw", borderRadius: 0, background: "#343434", color: "white", width: "17vw", marginTop: "4vh"}}>Browse Shop</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "5vh", paddingBottom: "4vh", width: "94vw", marginLeft: "3vw", background: "#F2F2F2"}}>
                        <div>
                            <img alt="Your Image" src={img_ex_mobile} style={{width: "84vw", marginTop: "4.5vh", marginLeft: "5vw"}}/>
                        </div>
                        <div style={{marginLeft: "5vw", marginRight: "5vw"}}>
                            <div style={{marginTop: "4.5vh", marginBottom: "0.5vh", fontSize: "1.8vw", fontStyle: "italic"}}>FEATURE OF THE WEEK</div>
                            <div style={{fontSize: "3.7vw"}}>Ethereal Reverie Exhibition</div>
                            <div style={{marginTop: "11vh", marginRight: "3vw", fontSize: "1.65vw"}}>
                                "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space. With each stroke,
                                the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default Feature;