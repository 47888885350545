import React from "react";
import {Button} from "@mui/material";
import img from "./img/img_artist.png";
import img_mobile from "./img/img_artist_mobile.png"
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const ViewArtist = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "100vh"}}>
                    <div style={{paddingTop: "18vh", paddingLeft: "6vw", color: "white"}}>
                        <div style={{fontSize: "3vw", marginRight: "40vw", marginBottom: "13vh"}}>Unveiling Brilliance: Journey through our Gallery's Finest Artists!</div>
                        <div style={{fontSize: "1.3vw", marginRight: "60vw"}}>
                            Engage with the visionaries who breathe life into their creations,
                            as they share their inspirations and artistic processes,
                            enlightening you with their profound perspectives.
                        </div>
                        <Button disableRipple variant="standard" onClick={()=>handleClick('Artists')} style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "4.5vh", fontSize: "1.1vw", borderRadius: 0, background: "#F2F2F2", color: "#004D96", height: "5vh"}}>View Artists</Button>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{backgroundImage: `url(${img_mobile})`, backgroundSize: "100%", width: "100vw", height: "96vh"}}>
                    <div style={{paddingTop: "15vh", paddingLeft: "6vw", color: "white"}}>
                        <div style={{fontSize: "4.6vw", marginRight: "15vw", marginBottom: "16vh"}}>Unveiling Brilliance: Journey through our Gallery's Finest Artists!</div>
                        <div style={{fontSize: "2.8vw", marginRight: "20vw"}}>
                            Engage with the visionaries who breathe life into their creations,
                            as they share their inspirations and artistic processes,
                            enlightening you with their profound perspectives.
                        </div>
                        <Button disableRipple variant="standard" onClick={()=>handleClick('Artists')} style={{backgroundColor: "transparent", textTransform: "capitalize", marginTop: "6.8vh", fontSize: "2.35vw", borderRadius: 0, background: "#F2F2F2", color: "#004D96", width: "19vw", height: "5vh"}}>View Artists</Button>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ViewArtist;