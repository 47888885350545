import React from 'react';
import '../Home/Home.css'

import img_1 from "../Home/img/img_feature1.png";
import img_2 from "../Home/img/img_feature2.png";
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";

const FeatureCard_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "43vh",  color: "#2F2F2F"}}>
                    <div style={{height: "30vh", maxWidth: "22vw", overflow: "hidden"}}>
                        <img src={img_1} style={{height: "30vh"}}/>
                    </div>
                    <div style={{fontSize: "1.3vw", marginTop: "1vh"}}>Art Work Title (2023)</div>
                    <div style={{fontSize: "0.9vw", fontStyle: "italic"}}>New York, New York</div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "55vw", color: "#2F2F2F"}}>
                    <img src={img_1} style={{width: "55vw"}}/>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{fontSize: "2.5vw", marginTop: "1vh"}}>Art Work Title (2023)</div>
                        <div style={{fontSize: "1.9vw", fontStyle: "italic"}}>New York, New York</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const FeatureCard_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "43vh",  color: "#2F2F2F"}}>
                    <div style={{height: "30vh", maxWidth: "22vw", overflow: "hidden"}}>
                        <img src={img_2} style={{height: "30vh"}}/>
                    </div>
                    <div style={{fontSize: "1.3vw", marginTop: "1vh"}}>Art Work Title (2023)</div>
                    <div style={{fontSize: "0.9vw", fontStyle: "italic"}}>New York, New York</div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "55vw", color: "#2F2F2F"}}>
                    <img src={img_2} style={{width: "55vw"}}/>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{fontSize: "2.5vw", marginTop: "1vh"}}>Art Work Title (2023)</div>
                        <div style={{fontSize: "1.9vw", fontStyle: "italic"}}>New York, New York</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const imageUrls = [
    <FeatureCard_1/>,
    <FeatureCard_2/>,
];

const ImageRoller_4 = () => {
    const doubledImages = [...imageUrls, ...imageUrls];
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div className="image-roller-container">
                    <div className="image-roller" id={"roller_3"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginLeft: "2vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div className="image-roller-container">
                    <div className="image-roller" id={"roller_3"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginBottom: "2.5vh"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default ImageRoller_4;