import React from "react";
import img from "./img/img_4.png"
import {Button, TextField} from "@mui/material";
import MediaQuery from "react-responsive";

const SendMsg = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw"}}>
                    <div style={{ height: "85vh", width: "90vw", paddingTop: "8vh", paddingLeft: "5vw", paddingRight: "5vw",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{height: "100%", position: "relative", width: "45%"}}>
                            <div style={{fontSize: "2.5vw", marginBottom: "7vh"}}>Send us a Message</div>
                            <div style={{height: "75%", maxWidth: "90%", overflow: "hidden"}}>
                                <img src={img} style={{position: "relative", height: "100%"}}/>
                            </div>
                        </div>
                        <div style={{height: "85%", fontSize: "1.3vw", position: "relative", width: "50%", border: "1px #DFDFDF solid", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{height: "18%", marginTop: "10%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div>First and Last Name</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "1.3vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div>Email</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "1.3vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div>Message</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "1.3vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Button style={{border: "2px solid #DEDEDE", borderRadius: 0, color: "#787878", height: "50%", width: "35%", textTransform: "capitalize", fontSize: "1.2vw"}}>Send Message</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", paddingBottom: "12vh"}}>
                    <div style={{fontSize: "4vw", marginBottom: "7vh", marginLeft: "5vw"}}>Send us a Message</div>
                    <div style={{ height: "150vh", width: "100vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{width: "80vw", position: "relative", marginBottom: "10vh"}}>
                            <img src={img} style={{position: "relative",  width: "100%"}}/>
                        </div>
                        <div style={{height: "80vh", fontSize: "1.3vw", position: "relative", width: "85vw", border: "1px #DFDFDF solid", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{height: "18%", marginTop: "10%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.4vw"}}>First and Last Name</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "2vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.4vw"}}>Email</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "2vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", color: "#666666", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{fontSize: "2.4vw"}}>Message</div>
                                <input style={{width: "100%", height: "45%", background: "#F4F4F4", border: "none", fontSize: "2vw"}}/>
                            </div>
                            <div style={{height: "18%", marginLeft: "15%", marginRight: "15%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Button style={{border: "2px solid #DEDEDE", borderRadius: 0, color: "#787878", height: "50%", width: "35%", textTransform: "capitalize", fontSize: "2vw"}}>Send Message</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default SendMsg;