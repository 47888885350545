import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import img_1 from "./img/img.png"
import img_2 from "./img/img_1.png"
import img_3 from "./img/img_2.png"
import img_4 from "./img/img_3.png"
import img_5 from "./img/img_4.png"
import img_6 from "./img/img_5.png"
import img_7 from "./img/img_6.png"
import img_8 from "./img/img_7.png"
import img_9 from "./img/img_8.png"
import img_10 from "./img/img_9.png"
import MediaQuery from "react-responsive";

const RowImage = (itemData) => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "49vh", width: "100vw"}}>
                    {itemData.map((item) => (
                        <div style={{width: "14vw", margin: "auto"}}>
                            <img
                                src={item.img}
                                style={{width: "14vw", height: "14vw"}}
                            />
                            <div>
                                <div style={{color: "#2F2F2F", marginTop: "0.3vh", fontSize: "1.1vw"}}>Artist Name</div>
                                <div style={{color: "#767676", marginTop: "0.3vh", fontStyle: "italic", fontSize: "0.9vw"}}>20 works</div>
                            </div>
                        </div>
                    ))}
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "5vh"}}>
                    <div style={{display: "flex", width: "75vw", justifyContent: "space-between"}}>
                        {itemData.map((item) => (
                            <div style={{width: "32vw"}}>
                                <div style={{maxHeight: "25vw", overflow: "hidden"}}>
                                    <img
                                        src={item.img}
                                        style={{width: "32vw"}}
                                    />
                                </div>
                                <div>
                                    <div style={{color: "#2F2F2F", marginTop: "0.3vh", fontSize: "2.4vw"}}>Artist Name</div>
                                    <div style={{color: "#767676", fontStyle: "italic", fontSize: "2vw"}}>20 works</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

function StandardImageList() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "300vh", width: "100vw"}}>
                    <div style={{fontSize: "1.3vw", fontWeight: 300, color: "#949494"}}>Showing 30 artists</div>
                    <div style={{background: "#F2F2F2"}}>
                        <div style={{height: "294.5vh", width: "100vw"}}>
                            {items.map((item) => (
                                RowImage(item)
                            ))}
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw"}}>
                    <div style={{fontSize: "2.2vw", marginLeft: "2vw", fontWeight: 300, color: "#949494"}}>Showing 30 artists</div>
                    <div style={{background: "#F2F2F2", paddingTop: "9vh", paddingBottom: "1vh"}}>
                        <div style={{borderBottom: "1px solid #F2F2F2"}}>
                            <div style={{width: "100vw"}}>
                                {items_mobile.map((item) => (
                                    <div style={{marginBottom: "6vh"}}>
                                        {RowImage(item)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

const items = [
    [{
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    },
    {
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    },
    {
        img: img_5,
        title: 'Hats',
    }],
    [{
        img: img_6,
        title: 'Honey',
    },
    {
        img: img_7,
        title: 'Basketball',
    },
    {
        img: img_8,
        title: 'Fern',
    },
    {
        img: img_9,
        title: 'Mushrooms',
    },
    {
        img: img_10,
        title: 'Tomato basil',
    }],
    [{
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    },
    {
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    },
    {
        img: img_5,
        title: 'Hats',
    }],
    [{
        img: img_6,
        title: 'Honey',
    },
    {
        img: img_7,
        title: 'Basketball',
    },
    {
        img: img_8,
        title: 'Fern',
    },
    {
        img: img_9,
        title: 'Mushrooms',
    },
    {
        img: img_10,
        title: 'Tomato basil',
    }],
    [{
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    },
    {
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    },
    {
        img: img_5,
        title: 'Hats',
    }],
    [{
        img: img_6,
        title: 'Honey',
    },
    {
        img: img_7,
        title: 'Basketball',
    },
    {
        img: img_8,
        title: 'Fern',
    },
    {
        img: img_9,
        title: 'Mushrooms',
    },
    {
        img: img_10,
        title: 'Tomato basil',
    }],
];

const items_mobile = [
    [{
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    }],
    [{
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    }],
    [{
        img: img_5,
        title: 'Hats',
    },
    {
        img: img_6,
        title: 'Honey',
    }],
    [{
        img: img_7,
        title: 'Basketball',
    },
    {
        img: img_8,
        title: 'Fern',
    }],
    [{
        img: img_9,
        title: 'Mushrooms',
    },
    {
        img: img_10,
        title: 'Tomato basil',
    }],
    [{
        img: img_1,
        title: 'Breakfast',
    },
    {
        img: img_2,
        title: 'Burger',
    }],
    [{
        img: img_3,
        title: 'Camera',
    },
    {
        img: img_4,
        title: 'Coffee',
    }],
    [{
        img: img_5,
        title: 'Hats',
    },
    {
        img: img_6,
        title: 'Honey',
    }],
    [{
        img: img_7,
        title: 'Basketball',
    },
    {
        img: img_8,
        title: 'Fern',
    }],
    [{
        img: img_9,
        title: 'Mushrooms',
    },
    {
        img: img_10,
        title: 'Tomato basil',
    }],
];

export default StandardImageList;