import React from "react";
import {Button} from "@mui/material";
import img from "./img/img.png";
import MediaQuery from "react-responsive";

const About = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, width: "100vw", height: "88.5vh", position: "absolute", zIndex: -1, filter: "brightness(50%)"}}>
                </div>
                <div style={{height: "88.5vh", display: "flex", flexDirection: "column", justifyContent:"space-between", color: "white"}}>
                    <div style={{fontSize: "3.5vw", marginBottom: "12vh", textAlign: "center", marginTop: "9vh"}}>About</div>
                    <div style={{fontSize: "1.5vw", width: "35vw", marginLeft: "5vw",marginBottom: "9vh"}}>
                        3quilibrium Co. (DBA Octavate) is a media company that has begun to organize much of the data surrounding the mainstream music industry.
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "60vh", position: "absolute", zIndex: -1, filter: "brightness(50%)"}}>
                </div>
                <div style={{height: "60vh", display: "flex", flexDirection: "column", justifyContent:"space-between", color: "white"}}>
                    <div style={{fontSize: "5.8vw", marginBottom: "12vh", textAlign: "center", marginTop: "9vh"}}>About</div>
                    <div style={{fontSize: "2.1vw", width: "53vw", marginLeft: "5vw",marginBottom: "9vh"}}>
                        3quilibrium Co. (DBA Octavate) is a media company that has begun to organize much of the data surrounding the mainstream music industry.
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default About;