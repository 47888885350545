import * as React from 'react';
import img from "./img/img.png"
import MediaQuery from "react-responsive";

const PrivateGallery = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", justifyContent: "center", height: "100vh", width: "100vw", alignItems: "center"}}>
                    <div style={{height: "75vh", width: "92vw"}}>
                        <div style={{fontSize: "2.2vw"}}>
                            Our Private Gallery
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{fontSize: "1.25vw", marginTop: "2vh", height: "68vh", width: "47vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                                <div>
                                    3quilibrium Co. (DBA Octavate) is a media company that has begun to organize much of the data surrounding the mainstream music industry.
                                </div>
                                <div>
                                    At the heart of the company’s innovation is a processor that scrapes and nests many forms of information into an organizational hierarchy of label and sub-label superstructures.
                                </div>
                                <div>
                                    The result is an index of thousands of songs, YouTube channels, and artists, all of which represents a significant part of the music industry ecosystem.
                                </div>
                                <div>
                                    Octavate then visualizes this information, from which it can conduct further analysis.
                                </div>
                                <div>
                                    In order to mark this moment in the company’s progress, Octavate is taking the data it has been amassing for over a year and minting a commemorative set of art pieces in its directory, each of which is distinctive and representative of some of the greatest visual artists in human history.
                                </div>
                            </div>
                            <div style={{height: "69vh", width: "40vw", overflow: "hidden"}}>
                                <img src={img} style={{height: "69vh"}}/>
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "center", height: "200vh", width: "100vw"}}>
                    <div style={{width: "90vw", marginTop: "11vh"}}>
                        <div style={{fontSize: "5vw", marginBottom: "6vh"}}>
                            Our Private Gallery
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                            <div style={{fontSize: "2.65vw", marginBottom: "11vh", marginTop: "2vh", height: "76vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                                <div>
                                    3quilibrium Co. (DBA Octavate) is a media company that has begun to organize much of the data surrounding the mainstream music industry.
                                </div>
                                <div>
                                    At the heart of the company’s innovation is a processor that scrapes and nests many forms of information into an organizational hierarchy of label and sub-label superstructures.
                                </div>
                                <div>
                                    The result is an index of thousands of songs, YouTube channels, and artists, all of which represents a significant part of the music industry ecosystem.
                                </div>
                                <div>
                                    Octavate then visualizes this information, from which it can conduct further analysis.
                                </div>
                                <div>
                                    In order to mark this moment in the company’s progress, Octavate is taking the data it has been amassing for over a year and minting a commemorative set of art pieces in its directory, each of which is distinctive and representative of some of the greatest visual artists in human history.
                                </div>
                            </div>
                            <img src={img} style={{width: "90vw"}}/>
                        </div>

                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default PrivateGallery;