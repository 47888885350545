import * as React from 'react';
import {Box, Button, Grid, IconButton} from "@mui/material";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Divider from '@mui/material/Divider';

import img from  "./img/img.png";
import img_1 from "./img/img_1.png";
import img_2 from "./img/img_2.png";
import MediaQuery from "react-responsive";

const CartItem_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "17vh"}}>
                    <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img alt={"img"}
                             src={img}
                             style={{height: "76%", paddingLeft: "1.9vw", paddingRight: "1.9vw"}}/>
                    </div>
                    <div style={{width: "17vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{marginLeft: "1vw", fontSize: "1.2vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{marginLeft: "1vw", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "1.05vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{marginLeft: "1vw", color: "#2F2F2F", fontSize: "1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "27vw"}}>
                        <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2.3vh", paddingBottom: "2.3vh"}}>
                            <img alt={"img"}
                                 src={img}
                                 style={{width: "72%"}}/>
                        </div>
                    </div>
                    <div style={{width: "30vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{fontSize: "2.4vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "2vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{color: "#2F2F2F", fontSize: "2.1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CartItem_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "17vh"}}>
                    <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img alt={"img"}
                             src={img_1}
                             style={{height: "76%", paddingLeft: "1.9vw", paddingRight: "1.9vw"}}/>
                    </div>
                    <div style={{width: "17vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{marginLeft: "1vw", fontSize: "1.2vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{marginLeft: "1vw", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "1.05vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{marginLeft: "1vw", color: "#2F2F2F", fontSize: "1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "27vw"}}>
                        <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2.3vh", paddingBottom: "2.3vh"}}>
                            <img alt={"img"}
                                 src={img_1}
                                 style={{width: "72%"}}/>
                        </div>
                    </div>
                    <div style={{width: "30vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{fontSize: "2.4vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "2vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{color: "#2F2F2F", fontSize: "2.1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CartItem_3 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "17vh"}}>
                    <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img alt={"img"}
                             src={img_2}
                             style={{height: "76%", paddingLeft: "1.9vw", paddingRight: "1.9vw"}}/>
                    </div>
                    <div style={{width: "17vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{marginLeft: "1vw", fontSize: "1.2vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{marginLeft: "1vw", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "1.05vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{marginLeft: "1vw", color: "#2F2F2F", fontSize: "1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "27vw"}}>
                        <div style={{background: "#F3F3F3", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2.3vh", paddingBottom: "2.3vh"}}>
                            <img alt={"img"}
                                 src={img_2}
                                 style={{width: "72%"}}/>
                        </div>
                    </div>
                    <div style={{width: "30vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{height: "13vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <div style={{fontSize: "2.4vw", color: "#2F2F2F"}}>
                                Embers of Hope
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: "#BABABA", fontSize: "2vw"}}>x 2</div>
                                <IconButton disableRipple>
                                    <CloseSharpIcon style={{color: "#B5B5B5", fontSize: "20px"}}/>
                                </IconButton>
                            </div>
                            <div style={{color: "#2F2F2F", fontSize: "2.1vw"}}>
                                $692
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const ShoppingCart = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{marginLeft: "4.5vw", width: "31vw", height: "76vh"}}>
                    <div>
                        <div style={{fontSize: "2.1vw", marginTop: "3vh", marginBottom: "4vh"}}>Shopping Cart</div>
                        <Divider/>
                    </div>
                    <div>
                        <div style={{marginBottom: "2vh", marginTop: "2.5vh"}}>
                            <CartItem_1/>
                        </div>
                        <div style={{marginBottom: "2vh"}}>
                            <CartItem_2/>
                        </div>
                        <div>
                            <CartItem_3/>
                        </div>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Button variant={"outlined"} disableRipple style={{backgroundColor: "transparent", textTransform: "capitalize", borderRadius: 0, border: "#E3E3E3 1.5px solid", color: "#696969"}}>Continue Shopping</Button>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "60vw", paddingBottom: "8.5vh"}}>
                    <div>
                        <div style={{fontSize: "3.6vw", marginTop: "3vh", marginBottom: "5vh"}}>Shopping Cart</div>
                        <Divider/>
                    </div>
                    <div>
                        <div style={{marginBottom: "2vh", marginTop: "2.5vh"}}>
                            <CartItem_1/>
                        </div>
                        <div style={{marginBottom: "2vh"}}>
                            <CartItem_2/>
                        </div>
                        <div>
                            <CartItem_3/>
                        </div>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Button variant={"outlined"} disableRipple style={{backgroundColor: "transparent", fontSize:"1.9vw", textTransform: "capitalize", borderRadius: 0, border: "#E3E3E3 1.5px solid", paddingLeft: "1.5vw", paddingRight: "1.5vw", color: "#696969"}}>Continue Shopping</Button>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ShoppingCart;