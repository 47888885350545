import React from "react";
import MediaQuery from "react-responsive";

const AboutContent = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <div style={{height: "60vh", width: "50vw", fontSize: "1.5vw", margin: "auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                        <div>
                            At the heart of the company’s innovation is a processor that scrapes and nests many forms of information into an organizational hierarchy of label and sub-label superstructures.
                        </div>
                        <div>
                            The result is an index of thousands of songs, YouTube channels, and artists, all of which represents a significant part of the music industry ecosystem.
                        </div>
                        <div>
                            Octavate then visualizes this information, from which it can conduct further analysis.
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "75vh", width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <div style={{height: "75vh", width: "90vw", fontSize: "2.7vw", margin: "auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                        <div>
                            At the heart of the company’s innovation is a processor that scrapes and nests many forms of information into an organizational hierarchy of label and sub-label superstructures.
                        </div>
                        <div>
                            The result is an index of thousands of songs, YouTube channels, and artists, all of which represents a significant part of the music industry ecosystem.
                        </div>
                        <div>
                            Octavate then visualizes this information, from which it can conduct further analysis.
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default AboutContent;