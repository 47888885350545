import React from "react";
import {Box} from "@mui/material";
import img_1 from "./img/img_2.png";
import img_2 from "./img/img_3.png";
import img_3 from "./img/img_4.png";
import MediaQuery from "react-responsive";

const Interest = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh",width: "100vw", background: "#F1F1F1"}}>
                    <div style={{textAlign: "center", paddingTop: "7vh", marginBottom: "7vh"}}>
                        <div style={{fontSize: "2.5vw"}}>You May Also Like</div>
                    </div>
                    <div>
                        <div style={{display: "flex", height: "77vh", justifyContent: "space-evenly"}}>
                            <div>
                                <div style={{textAlign:"center", height: "62vh", width: "28vw", backgroundImage: `url(${img_1})`, backgroundSize: "100%"}}>
                                </div>
                                <div>
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "1vh"}}>
                                        <div style={{fontSize: 15, fontStyle: "italic"}}>Artist Name</div>
                                        <div>$233</div>
                                    </div>
                                    <div style={{fontSize: 20}}>Art Piece Title</div>
                                </div>
                            </div>
                            <div>
                                <div style={{textAlign:"center", height: "62vh", width: "28vw", backgroundImage: `url(${img_2})`, backgroundSize: "100%"}}>
                                </div>
                                <div>
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "1vh"}}>
                                        <div style={{fontSize: 15, fontStyle: "italic"}}>Artist Name</div>
                                        <div>$233</div>
                                    </div>
                                    <div style={{fontSize: 20}}>Art Piece Title</div>
                                </div>
                            </div>
                            <div >
                                <div style={{textAlign:"center", height: "62vh", width: "28vw", backgroundImage: `url(${img_3})`, backgroundSize: "100%"}}>
                                </div>
                                <div>
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "1vh"}}>
                                        <div style={{fontSize: 15, fontStyle: "italic"}}>Artist Name</div>
                                        <div>$233</div>
                                    </div>
                                    <div style={{fontSize: 20}}>Art Piece Title</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "120vh",width: "100vw", background: "#F1F1F1"}}>
                    <div style={{textAlign: "center", paddingTop: "8vh", marginBottom: "6vh"}}>
                        <div style={{fontSize: "3.8vw"}}>You May Also Like</div>
                    </div>
                    <div>
                        <div style={{display: "flex", height: "77vh", justifyContent: "center"}}>
                            <div>
                                <div style={{textAlign:"center", height: "80vh", width: "67vw"}}>
                                    <img src={img_2} style={{height: "80vh", width: "67vw"}}/>
                                </div>
                                <div>
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "1vh"}}>
                                        <div style={{fontSize: 15, fontStyle: "italic"}}>Artist Name</div>
                                        <div>$233</div>
                                    </div>
                                    <div style={{fontSize: 20}}>Art Piece Title</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Interest;