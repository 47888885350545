import React from 'react';
import Grid from '@mui/material/Grid';
import {Button} from "@mui/material";
import img_publication from "./img/img_publication.png"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./Home.css";
import MediaQuery from "react-responsive";

const Publication = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "88vh", width: "100vw", background: "black", paddingTop: "12vh"}}>
                    <Grid container style={{height: "70vh", width: "90vw", marginLeft: "2vw"}}>
                        <Grid item xs={5.5} style={{overflow: "hidden"}}>
                            <img alt="Your Image" src={img_publication} style={{height:"69vh"}}/>
                        </Grid>
                        <Grid item xs={0.3}></Grid>
                        <Grid item xs={6.2} style={{color: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "1.6vw", fontStyle: "italic"}}>PUBLICATION</div>
                                <div style={{fontSize: "3vw", marginTop: "1vh", marginBottom: "1vh"}}>Art Work Title (2023)</div>
                                <div style={{fontSize: "1.6vw", fontStyle: "italic"}}>New York, New York</div>
                            </div>
                            <div style={{fontSize: "1.22vw"}}>
                                "Ethereal Embrace" is an invitation to let go of preconceptions and embrace the unknown.
                                It encourages viewers to find solace in the ambiguity of the abstract, allowing emotions to flow freely and unencumbered by the constraints of the rational mind. This contemporary masterpiece is an ode to the wonder of existence and a celebration of the limitless possibilities that lie within every individual.
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <Button component="label" variant="contained" endIcon={<NavigateNextIcon style={{transform:"scale(2.3)", marginRight: "1.6vw", color: "white"}}/>} style={{marginTop: "8vh", borderRadius: "0", textTransform:"capitalize", fontSize: "1.2vw", fontStyle: "italic", background: "black", color: "white", boxShadow: "none"}}>
                            Learn More
                        </Button>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{paddingBottom: "5.5vh", width: "100vw", background: "black"}}>
                    <img alt="Your Image" src={img_publication} style={{width: "100vw"}}/>
                    <div style={{color: "#FFFFFF", marginLeft: "7vw", marginTop: "6.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "28.5vh"}}>
                            <div style={{fontSize: "2.5vw", fontStyle: "italic"}}>PUBLICATION</div>
                            <div style={{fontSize: "5vw", marginBottom: "5vh", marginTop: "0.6vh"}}>Art Work Title (2023)</div>
                            <div style={{fontSize: "3.2vw", fontStyle: "italic"}}>New York, New York</div>
                        </div>
                        <div style={{fontSize: "2.2vw", marginRight: "20vw", lineHeight: "4.2vh"}}>
                            "Ethereal Embrace" is an invitation to let go of preconceptions and embrace the unknown.
                            It encourages viewers to find solace in the ambiguity of the abstract, allowing emotions to flow freely and unencumbered by the constraints of the rational mind. This contemporary masterpiece is an ode to the wonder of existence and a celebration of the limitless possibilities that lie within every individual.
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <Button component="label"
                                variant="contained"
                                endIcon={<NavigateNextIcon style={{transform:"scale(2.3)", marginRight: "1.6vw", fontSize: "3vw", color: "white"}}/>}
                                style={{marginTop: "2vh", borderRadius: "0", textTransform:"capitalize", fontSize: "2.8vw", marginRight: "5vw", fontStyle: "italic", background: "black", color: "white", boxShadow: "none"}}>
                            Learn More
                        </Button>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default Publication;