import * as React from 'react';
import img from "./img/img_1.png";
import MediaQuery from "react-responsive";

const Video = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{height: "75vh", maxWidth: "85vw", overflow: "hidden", marginTop: "8vh"}}>
                        <img src={img} style={{height: "75vh"}}/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "100vw", marginTop: "6vh"}}>
                        <img src={img} style={{width: "100vw"}}/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Video;