import React from "react";
import {Button} from "@mui/material";
import img from "../Home/img/img_join.png"
import MediaQuery from "react-responsive";

const Ex_Join = () => {
    return (
        <div>
            <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "65vh", position: "absolute", zIndex: -1, filter: "brightness(75%)"}}>
            </div>
            <div style={{width: "100vw", height: "65vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                <div style={{color: "white"}}>
                    <div style={{fontSize: "5vw", marginBottom: "9vh", textAlign: "center"}}>Join Our Gallery</div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{fontSize: "2.8vw", width: "50vw", textAlign: "center"}}>
                            Access exclusive art exhibitions and pieces curated just for you.
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button variant="standard" disableRipple style={{textTransform: "capitalize", marginTop: "3.5vh", fontSize: "2vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "20vw", height: "5vh"}}>
                            Create Account
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ex_Join;