import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import "./Artists.css";
import MediaQuery from "react-responsive";

function MiddleDividers() {
    const handleSearch = (event) => {
        console.log(event.target.value);
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{position:"relative", height: "88vh", width: "100vw"}}>
                    <div style={{position: "relative", marginTop: "12vh", marginLeft: "5vw", marginRight: "5vw"}}>
                        <div style={{position: "relative", marginLeft: "0.5vw", marginBottom:"11vh", fontSize: "4vw", fontWeight: 400, textAlign: "left"}}>Artists</div>
                        <Divider></Divider>
                        <div style={{position: "relative", marginLeft: "0.5vw", marginRight:"25vw", marginTop: "5vh", fontWeight: 300, textAlign: "left", fontSize: "1.4vw", lineHeight: 1.8}}>
                            Here is a list of our current artists and the amount of their works on the site.
                            This list includes artists who are featured in our exhibitions and collections.
                            It is constantly updated.
                        </div>
                        <TextField
                            variant="standard"
                            placeholder="Search Artists"
                            onChange={handleSearch}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{fontSize: "1.8vw"}}/>
                                    </InputAdornment>
                                ),
                                style: {color: "#2F2F2F", fontSize:"1.5vw", fontFamily: "Poppins, sans-serif"}
                            }}
                            style={{position: "relative", marginTop:"17vh", width: "82%", borderBottom: "1px solid #949494"}}
                        />
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", borderTop: "1px solid white", marginBottom: "16vh"}}>
                    <div style={{marginLeft: "5vw", marginRight: "5vw"}}>
                        <div style={{position: "relative", color: "#2F2F2F", marginTop: "10vh", marginLeft: "0.5vw", marginBottom:"8vh", fontSize: "6.8vw", fontWeight: 400, textAlign: "left"}}>Artists</div>
                        <Divider></Divider>
                        <div style={{marginLeft: "0.5vw", marginRight:"12vw", marginTop: "4.5vh", fontWeight: 300, textAlign: "left", fontSize: "2.2vw", lineHeight: 1.8}}>
                            Here is a list of our current artists and the amount of their works on the site.
                            This list includes artists who are featured in our exhibitions and collections.
                            It is constantly updated.
                        </div>
                        <TextField
                            variant="standard"
                            sx={{
                                "& input::placeholder": { fontSize: "2.3vw", fontStyle: "italic", color: "#2F2F2F", opacity: 1},
                            }}
                            placeholder="Search Artists"
                            onChange={handleSearch}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{fontSize: "3.3vw", color: "#2F2F2F"}}/>
                                    </InputAdornment>
                                ),
                                style: {color: "#2F2F2F", fontSize:"2.3vw", marginBottom: "1vw", fontFamily: "Poppins, sans-serif"}
                            }}
                            style={{position: "relative", marginTop:"10vh", width: "98%", borderBottom: "1px solid #949494"}}
                        />
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default MiddleDividers;