import {Button} from "@mui/material";
import React from "react";
import "./Setting.css";
import ChangePassIcon from "./Icons/ChangePassIcon";
import MediaQuery from "react-responsive";
import AccountIcon from "./Icons/AccountIcon";

const ChangePass = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "5vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.8vh"}}>
                            <div style={{height: "2vw", width: "2vw", marginLeft: "1vw"}}>
                                <ChangePassIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Change Password</div>
                        </div>
                        <form style={{height: "33%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "0.5vw", color: "#666666", width: "42%"}}>Old Password</label>
                                <input placeholder={"**********"} style={{width: "54%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "0.5vw", color: "#666666", width: "42%"}}>New Password</label>
                                <input placeholder={"**********"} style={{width: "54%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "0.5vw", color: "#666666", width: "42%"}}>
                                    <div>Confirm</div>
                                    <div>Password</div>
                                </div>
                                {/*<div style={{height:"2vh", width: "54%", border: "solid 1px black"}}></div>*/}
                                <input placeholder={"**********"} style={{width: "54%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                        </form>
                        <div style={{textAlign: "center", marginTop: "6vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "1.3vw"}}>Submit</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "57vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "50vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.5vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <ChangePassIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Change Password</div>
                        </div>
                        <form style={{height: "46%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "0.5vw", color: "#666666", width: "40%"}}>Old Password</label>
                                <input placeholder={"**********"} style={{width: "60%", height: "5vh", background: "#E7E7E7", fontSize: "2.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "0.5vw", color: "#666666", width: "40%"}}>New Password</label>
                                <input placeholder={"**********"} style={{width: "60%", height: "5vh", background: "#E7E7E7", fontSize: "2.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "0.5vw", color: "#666666", width: "40%"}}>
                                    <div>Confirm</div>
                                    <div>Password</div>
                                </div>
                                <input placeholder={"**********"} style={{width: "60%", height: "5vh", background: "#E7E7E7", fontSize: "2.5vw", border: "none"}}/>
                            </div>
                        </form>
                        <div style={{textAlign: "center", marginTop: "6vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "2vw"}}>Submit</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}
export default ChangePass;