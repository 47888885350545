import * as React from 'react';

import img from './img/img_5.png';
import img_1 from './img/img_6.png';
import img_2 from './img/img_7.png';
import img_3 from './img/img_8.png';
import img_4 from './img/img_9.png';
import img_5 from './img/img_10.png';
import MediaQuery from "react-responsive";

const Work_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const Work_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img_1} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img_5} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const Work_3 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img_2} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img_2} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const Work_4 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img_3} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img_3} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const Work_5 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img_4} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img_4} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}


const Work_6 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "50vh",width: "28vw"}}>
                    <div style={{height: "75%", width: "100%", overflow: "hidden", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1"}}>
                        <img src={img_5} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1vw", marginTop: "1.3vh", marginBottom: "1vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "0.7vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "0.8vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div>
                    <div style={{width: "30vw", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", background: "#F1F1F1", paddingTop: "3.5vh", paddingBottom: "3.5vh"}}>
                        <img src={img_5} style={{width: "70%"}}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginRight: "1vw"}}>
                        <div>
                            <div style={{fontSize: "1.95vw", marginTop: "1.3vh", marginBottom: "0.5vh"}}>Art Piece Title</div>
                            <div style={{fontSize: "1.3vw", fontStyle: "italic"}}>Artist Name</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", fontSize: "1.95vw"}}>
                            $233
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}


function AdmiredWork() {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{fontSize: "2.5vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "7.5vh", marginLeft: "3vw"}}>
                    Admired Works
                </div>

                <div style={{display: "flex", justifyContent: "space-around"}}>
                    <Work_1/>
                    <Work_2/>
                    <Work_3/>
                </div>
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    <Work_4/>
                    <Work_5/>
                    <Work_6/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{fontSize: "3vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "6vh", marginLeft: "3vw"}}>
                    Admired Works
                </div>

                <div style={{display: "flex", justifyContent: "space-around", marginBottom:"3.5vh"}}>
                    <Work_1/>
                    <Work_2/>
                    <Work_3/>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", marginBottom:"3.5vh"}}>
                    <Work_4/>
                    <Work_5/>
                    <Work_6/>
                </div>
            </MediaQuery>
        </div>
    )

}

export default AdmiredWork;