import Facebook from "./MediaIcons/Facebook";
import Twitter from "./MediaIcons/Twitter";
import Google from "./MediaIcons/Google";
import Instagram from "./MediaIcons/Instagram";
import React from "react";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {Button, Checkbox, FormControlLabel, FormGroup, IconButton} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MediaQuery from "react-responsive";

const Register = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "89vh", width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "80vh", width: "42vw", border: "1px solid #DCDCDC", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "76vh", width: "34vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <div style={{width: "34vw", height: "8vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{position: "absolute", zIndex: 1}}>
                                    <svg width="4vw" height="4vw">
                                        <rect x="0" y="0" width="4vw" height="4vw" stroke="white" fill="white"/>
                                        <circle cx="2vw" cy="2vw" r="1.5vw" stroke="black" strokeWidth="0.4vw" fill="white" />
                                    </svg>
                                </div>
                                <div style={{width: "34vw", position: "relative", zIndex: -1, top: "4vh"}}>
                                    <ArrowBackRoundedIcon sx={{strokeWidth: "1px", stroke: "#ffffff"}} style={{fontSize: "2.5vw"}}/>
                                </div>
                            </div>
                            <div style={{fontSize: "1.6vw", marginTop: "0.8vh"}}>
                                Register
                            </div>
                            <div style={{width: "34vw", marginTop: "2vh", height: "43vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{width: "34vw", display: "flex", justifyContent: "space-between"}}>
                                    <TextField
                                        placeholder="First Name*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                        style={{width: "47.5%"}}
                                    />
                                    <TextField
                                        placeholder="Last Name*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                        style={{width: "47.5%"}}
                                    />
                                </div>
                                <div style={{width: "34vw", display: "flex", justifyContent: "space-between"}}>
                                    <TextField
                                        placeholder="Phone #"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                    <TextField
                                        placeholder="Date of Birth*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                    <TextField
                                        placeholder="Sex*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                </div>

                                <TextField
                                    placeholder="Username*"
                                    variant="standard"
                                    focused="false"
                                    sx={{
                                        "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                    }}
                                    style={{width: "100%"}}
                                />
                                <TextField
                                    placeholder="Email Address"
                                    variant="standard"
                                    focused="false"
                                    sx={{
                                        "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                    }}
                                    style={{width: "100%"}}
                                />
                                <div>
                                    <TextField
                                        placeholder="Password*"
                                        variant="standard"
                                        focused="false"
                                        type={showPassword ? 'text' : 'password'}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} disableRipple="true">
                                                        {showPassword ? <VisibilityOff style={{fontSize: "1.7vw"}}/> : <Visibility style={{fontSize: "1.7vw"}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.1vw"}
                                        }}
                                    />
                                    <div style={{fontSize: "0.8vw", marginTop: "2vh"}}>
                                        <span style={{marginRight: "0.2vw"}}>By registering, you agree to our</span>
                                        <span style={{color: "#A60E0E", marginRight: "0.2vw"}}>Terms of Use</span>
                                        <span style={{marginRight: "0.2vw"}}>&</span>
                                        <span style={{color: "#A60E0E"}}>Privacy Policy</span>
                                    </div>
                                </div>
                            </div>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", padding: 0, marginTop: "2vh", fontSize: "1vw", borderRadius: 0, background: "#2F2F2F", color: "#FFFFFF", width: "100%", height: "4vh"}}>
                                Login
                            </Button>
                            <div style={{marginTop: "2.5vh", fontSize: "1vw"}}>
                                or Login in with
                            </div>
                            <div style={{width: "16vw", display: "flex", justifyContent: "space-between", marginTop: "3vh"}}>
                                <Google/>
                                {/* <Twitter/>
                                <Instagram/>
                                <Facebook/> */}
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", height: "89vh", width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "75vh", width: "80vw", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "69vh", width: "60vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <div style={{width: "60vw", height: "8vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{position: "absolute", zIndex: 1}}>
                                    <svg width="7vw" height="7vw">
                                        <rect x="0" y="0" width="7vw" height="7vw" stroke="white" fill="white"/>
                                        <circle cx="3.5vw" cy="3.5vw" r="2vw" stroke="black" strokeWidth="0.4vw" fill="white" />
                                    </svg>
                                </div>
                                <div style={{width: "60vw", position: "relative", zIndex: -1, top: "1.5vh"}}>
                                    <ArrowBackRoundedIcon sx={{strokeWidth: "1px", stroke: "#ffffff"}} style={{fontSize: "3.5vw"}}/>
                                </div>
                            </div>
                            <div style={{fontSize: "2.5vw"}}>
                                Register
                            </div>
                            <div style={{width: "60vw", marginTop: "2vh", height: "43vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <div style={{width: "60vw", display: "flex", justifyContent: "space-between"}}>
                                    <TextField
                                        placeholder="First Name*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                        style={{width: "47.5%"}}
                                    />
                                    <TextField
                                        placeholder="Last Name*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                        style={{width: "47.5%"}}
                                    />
                                </div>
                                <div style={{width: "60vw", display: "flex", justifyContent: "space-between"}}>
                                    <TextField
                                        placeholder="Phone #"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                    <TextField
                                        placeholder="Date of Birth*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                    <TextField
                                        placeholder="Sex*"
                                        variant="standard"
                                        focused="false"
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                        style={{width: "30%"}}
                                    />
                                </div>

                                <TextField
                                    placeholder="Username*"
                                    variant="standard"
                                    focused="false"
                                    sx={{
                                        "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                    }}
                                    style={{width: "100%"}}
                                />
                                <TextField
                                    placeholder="Email Address"
                                    variant="standard"
                                    focused="false"
                                    sx={{
                                        "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                    }}
                                    style={{width: "100%"}}
                                />
                                <div>
                                    <TextField
                                        placeholder="Password*"
                                        variant="standard"
                                        focused="false"
                                        type={showPassword ? 'text' : 'password'}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.8vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} disableRipple="true">
                                                        {showPassword ? <VisibilityOff style={{fontSize: "2.8vw"}}/> : <Visibility style={{fontSize: "2.8vw"}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                            style:{ height: "5vh", paddingLeft: "1vw", borderRadius: 0, border: "1px solid #000000", fontSize: "1.8vw"}
                                        }}
                                    />
                                    <div style={{fontSize: "1.65vw", marginTop: "2vh"}}>
                                        <span style={{marginRight: "0.2vw"}}>By registering, you agree to our</span>
                                        <span style={{color: "#A60E0E", marginRight: "0.2vw"}}>Terms of Use</span>
                                        <span style={{marginRight: "0.2vw"}}>&</span>
                                        <span style={{color: "#A60E0E"}}>Privacy Policy</span>
                                    </div>
                                </div>
                            </div>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", padding: 0, marginTop: "2vh", fontSize: "1.9vw", borderRadius: 0, background: "#2F2F2F", color: "#FFFFFF", width: "100%", height: "4vh"}}>
                                Register
                            </Button>
                            <div style={{marginTop: "2.5vh", fontSize: "1.85vw"}}>
                                or register with
                            </div>
                            <div style={{width: "25vw", display: "flex", justifyContent: "space-between", marginTop: "3vh"}}>
                                <Google/>
                                <Twitter/>
                                <Instagram/>
                                <Facebook/>
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Register;