import React from 'react';
import Security from "./Security";
import LangAndReg from "./LangAndRegion";
import Communication from "./CommPref";
import Theme from "./ThemeSelect";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import img from "./img/img.png"
import MediaQuery from "react-responsive";

const Dashboard = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "89vh", width: "70vw"}}>
                    <img src={img} style={{height: "19vh", width: "70vw", filter: "brightness(50%)"}}/>
                    <div style={{marginLeft: "4vw"}}>
                        <div style={{display: "flex", alignItems: "center", height: "10vh"}}>
                            <SettingsOutlinedIcon style={{fontSize: "2.3vw", color: "#575757", fontWeight: "0.5px"}}/>
                            <div style={{fontSize: "2vw", marginLeft: "0.6vw", color: "#575757"}}>User Dashboard</div>
                        </div>
                        <div style={{height: "10vh", width: "55vw", fontSize: "1vw"}}>
                            Manage and personalize your experience with ease using our intuitive settings dashboard.
                            Tailor your preferences to create an environment that suits your needs and enhances your interactions on our platform.
                        </div>
                        <div>
                            <div style={{display: "flex"}}>
                                <div style={{height: "21vh", width: "31vw", backgroundColor: "#F3F3F3", marginRight: "1.8vw"}}>
                                    <Security/>
                                </div>
                                <div style={{height: "21vh", width: "31vw", backgroundColor: "#F3F3F3"}}>
                                    <LangAndReg/>
                                </div>
                            </div>
                            <div style={{display: "flex", marginTop: "2.3vh"}}>
                                <div style={{height: "21vh", width: "31vw", backgroundColor: "#F3F3F3", marginRight: "1.8vw"}}>
                                    <Communication/>
                                </div>
                                <div style={{height: "21vh", width: "31vw", backgroundColor: "#F3F3F3"}}>
                                    <Theme/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", borderBottom: "2px #DCDCDC solid"}}>
                    <div style={{marginLeft: "4vw"}}>
                        <div style={{display: "flex", alignItems: "center", height: "16.5vh"}}>
                            <SettingsOutlinedIcon sx={{
                                strokeWidth: 0.7,
                                stroke: "#F3F3F3",
                                color: "#575757",
                                fontSize: "6.5vw",
                            }}/>
                            <div style={{fontSize: "4vw", marginLeft: "1.5vw", color: "#575757"}}>User Dashboard</div>
                        </div>
                        <div style={{height: "10vh", width: "70vw", fontSize: "1.95vw", marginBottom: "4vh"}}>
                            Manage and personalize your experience with ease using our intuitive settings dashboard.
                            Tailor your preferences to create an environment that suits your needs and enhances your interactions on our platform.
                        </div>
                        <div>
                            <div style={{height: "21vh", width: "60vw", marginBottom: "4vh", backgroundColor: "#F3F3F3", marginRight: "1.8vw"}}>
                                <Security/>
                            </div>
                            <div style={{height: "21vh", width: "60vw", marginBottom: "4vh", backgroundColor: "#F3F3F3"}}>
                                <LangAndReg/>
                            </div>
                            <div style={{height: "21vh", width: "60vw", marginBottom: "4vh", backgroundColor: "#F3F3F3", marginRight: "1.8vw"}}>
                                <Communication/>
                            </div>
                            <div style={{height: "21vh", width: "60vw", marginBottom: "8vh", backgroundColor: "#F3F3F3"}}>
                                <Theme/>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Dashboard;