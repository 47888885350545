import React from 'react';
import "./Slider.css";

const Slider = () => {
    return (
        <div>
            <div className="switch-container">
                <label className="switch-label">
                    <input type="checkbox" id="switch-3"/>
                    <span className="slider"></span>
                </label>
            </div>
        </div>
    )
}

export default Slider;