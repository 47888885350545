import React from "react";
import ImageRoller_3 from "../Home/Roller_3";
import MediaQuery from "react-responsive";
import ImageRoller_3_mobile from "../Home/Roller_3_mobile";

const EventsSlider = () => {
    return (
        <div>
            <MediaQuery maxWidth={1024}>
                <div style={{marginBottom: "6vh", marginTop: "12vh", marginLeft: "5vw"}}>
                    <div style={{fontSize: "2.3vw"}}>
                        Gallery Event
                    </div>
                    <div style={{fontSize: "4.8vw", fontStyle: "italic"}}>
                        This Upcoming Week
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <div style={{marginBottom: "10vh", marginTop: "12vh", marginLeft: "5vw"}}>
                    <div style={{fontSize: "1vw"}}>
                        Gallery Event
                    </div>
                    <div style={{fontSize: "3vw", fontStyle: "italic"}}>
                        This Upcoming Week
                    </div>
                </div>
            </MediaQuery>
            <div style={{display: "flex"}}>
                <MediaQuery maxWidth={1024}>
                    <div style={{writingMode: "vertical-rl", textAlign: "center", transform: "scale(-1, -1)", fontSize: "22px", fontStyle: "italic", marginLeft: "13vw"}}>Events in July</div>
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                    <div style={{writingMode: "vertical-rl", textAlign: "center", transform: "scale(-1, -1)", fontSize: "28px", fontStyle: "italic", marginLeft: "10vw"}}>Events in July</div>
                </MediaQuery>
                <div style={{marginLeft: "2vw", overflow: "hidden"}}>
                    <MediaQuery maxWidth={1024}>
                        <ImageRoller_3_mobile/>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                        <ImageRoller_3/>
                    </MediaQuery>
                </div>
            </div>
            <MediaQuery minWidth={1024}>
                <div style={{marginBottom: "12.5vh", marginTop: "12.5vh", marginLeft: "5vw", fontSize: "1.25vw", width: "47vw"}}>
                    Capture the magic of the exhibition and share your favorite moments on social media using the hashtag #EtherealVisions.
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginBottom: "9vh", marginTop: "11.5vh", marginLeft: "6vw", fontSize: "2.5vw", width: "80vw"}}>
                    Capture the magic of the exhibition and share your favorite moments on social media using the hashtag #EtherealVisions.
                </div>
            </MediaQuery>
        </div>
    )
}

export default EventsSlider;