import React from "react";
import {Button} from "@mui/material";
import img from "./img/img_3.png";

const CheckThankYou = () => {
    return (
        <div>
            <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "89vh", position: "absolute", zIndex: -1, filter: "brightness(60%)"}}>
            </div>
            <div style={{width: "100vw", height: "89vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                <div style={{color: "white"}}>
                    <div style={{fontSize: "2.9vw", marginBottom: "12vh", textAlign: "center"}}>
                        Thank you for Supporting the Arts
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{fontSize: "1.5vw", width: "60vw", textAlign: "center"}}>
                            If you liked our artists’ work, join our gallery!
                            By creating an account, you can access exclusive art exhibitions and pieces curated just for you.
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button variant="standard" disableRipple style={{textTransform: "capitalize", marginTop: "6vh", fontSize: "1.1vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "11vw", height: "5vh"}}>
                            Return Back
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckThankYou;