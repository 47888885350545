import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel
} from "@mui/material";


import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import MediaQuery from "react-responsive";

const AddressCheck = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <FormControlLabel
                    control={<Checkbox icon={<CircleOutlinedIcon />}
                                       checkedIcon={<CheckCircleOutlinedIcon />} />}
                    label={<div style={{fontSize: "0.85vw"}}>Same as Shipping Address</div>}
                    style={{marginRight: "1.8vw", color: "#A0A0A0"}}
                />
                <FormControlLabel
                    control={<Checkbox icon={<CircleOutlinedIcon />}
                                       checkedIcon={<CheckCircleOutlinedIcon />} />}
                    label={<div style={{fontSize: "0.85vw"}}>Use a Different Address</div>}
                    style={{color: "#A0A0A0"}}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <FormControlLabel
                    control={<Checkbox icon={<CircleOutlinedIcon />}
                                       checkedIcon={<CheckCircleOutlinedIcon />} />}
                    label={<div style={{fontSize: "1.7vw"}}>Same as Shipping Address</div>}
                    style={{marginRight: "1.8vw", color: "#A0A0A0"}}
                />
                <FormControlLabel
                    control={<Checkbox icon={<CircleOutlinedIcon />}
                                       checkedIcon={<CheckCircleOutlinedIcon />} />}
                    label={<div style={{fontSize: "1.7vw"}}>Use a Different Address</div>}
                    style={{color: "#A0A0A0"}}
                />
            </MediaQuery>
        </div>
    );
}

const BillingAddress = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{marginLeft: "1vw", width: "55vw"}}>
                    <div>
                        <div style={{fontSize: "1.5vw", marginBottom: "1.8vh", marginTop: "8vh"}}>
                            Billing Address
                        </div>
                        <div>
                            <AddressCheck/>
                        </div>
                        <div style={{marginTop: "2.5vh", marginBottom: "6vh", height: "32vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "30vw", height: "6.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "15vw"}}>
                                    <TextField
                                        label="First Name"
                                        variant="filled"
                                        placeholder={"John"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                    />
                                </div>
                                <div style={{width: "14vw"}}>
                                    <TextField
                                        label="Last Name"
                                        variant="filled"
                                        placeholder={"Doe"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {height: "", color: "#9E9E9E", fontSize: "0.9vw"}}}
                                    />
                                </div>
                            </div>

                            <div style={{width: "30vw", height: "6.5vh"}}>
                                <TextField
                                    label="Address"
                                    variant="filled"
                                    placeholder={"123 Street Name, Apt 1"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{fontSize: "1vw", height: "6.5vh"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                />
                            </div>

                            <div style={{width: "30vw", height: "6.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "18.5vw"}}>
                                    <TextField
                                        label="City"
                                        variant="filled"
                                        placeholder={"New York"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                    />
                                </div>
                                <div style={{width: "10.5vw"}}>
                                    <FormControl
                                        fullWidth
                                        focused
                                        variant="filled"
                                    >
                                        <InputLabel style={{color: "#9E9E9E", fontSize: "0.9vw"}}>State</InputLabel>
                                        <Select
                                            variant="filled"
                                            disableUnderline
                                            style={{fontSize: "1vw", height: "6.5vh"}}
                                        >
                                            <MenuItem value={1}>NY</MenuItem>
                                            <MenuItem value={2}>NJ</MenuItem>
                                            <MenuItem value={3}>FL</MenuItem>
                                            <MenuItem value={4}>CA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div style={{display: "flex", width: "30vw", height: "6.5vh", justifyContent: "space-between"}}>
                                <div style={{width: "13.5vw"}}>
                                    <TextField
                                        label="Zip Code"
                                        variant="filled"
                                        placeholder={"12345"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                    />
                                </div>
                                <div style={{width: "15.5vw"}}>
                                    <FormControl
                                        fullWidth
                                        focused
                                        variant="filled"
                                    >
                                        <InputLabel style={{color: "#9E9E9E", fontSize: "0.9vw"}}>Country</InputLabel>
                                        <Select
                                            variant="filled"
                                            disableUnderline
                                            style={{fontSize: "1vw", height: "6.5vh"}}
                                        >
                                            <MenuItem value={1}>United States</MenuItem>
                                            <MenuItem value={2}>United Kingdom</MenuItem>
                                            <MenuItem value={3}>Mexico</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div style={{textAlign: "center", width: "30vw"}}>
                            <Button fullWidth variant={"outlined"} style={{color: "#696969", border: "2px #9A9A9A solid", textTransform: "capitalize", fontSize: "0.85vw"}}>Continue to Summary</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginLeft: "3vw", marginBottom: "8vh"}}>
                    <div>
                        <div style={{fontSize: "2.8vw", marginBottom: "1.8vh", marginTop: "8vh"}}>
                            Billing Address
                        </div>
                        <div>
                            <AddressCheck/>
                        </div>
                        <div style={{marginTop: "2.5vh", marginBottom: "6vh", height: "32vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{width: "69vw", height: "6.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "36vw"}}>
                                    <TextField
                                        label="First Name"
                                        variant="filled"
                                        placeholder={"John"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "2.1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                    />
                                </div>
                                <div style={{width: "31vw"}}>
                                    <TextField
                                        label="Last Name"
                                        variant="filled"
                                        placeholder={"Doe"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "2.1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {height: "", color: "#9E9E9E", fontSize: "2vw"}}}
                                    />
                                </div>
                            </div>

                            <div style={{width: "69vw", height: "6.5vh"}}>
                                <TextField
                                    label="Address"
                                    variant="filled"
                                    placeholder={"123 Street Name, Apt 1"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{fontSize: "2.1vw", height: "6.5vh"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                />
                            </div>

                            <div style={{width: "69vw", height: "6.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div style={{width: "42vw"}}>
                                    <TextField
                                        label="City"
                                        variant="filled"
                                        placeholder={"New York"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "2.1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                    />
                                </div>
                                <div style={{width: "25vw"}}>
                                    <FormControl
                                        fullWidth
                                        focused
                                        variant="filled"
                                    >
                                        <InputLabel style={{color: "#9E9E9E", fontSize: "2vw"}}>State</InputLabel>
                                        <Select
                                            variant="filled"
                                            disableUnderline
                                            style={{fontSize: "2.1vw", height: "6.5vh"}}
                                        >
                                            <MenuItem value={1}>NY</MenuItem>
                                            <MenuItem value={2}>NJ</MenuItem>
                                            <MenuItem value={3}>FL</MenuItem>
                                            <MenuItem value={4}>CA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div style={{display: "flex", width: "69vw", height: "6.5vh", justifyContent: "space-between"}}>
                                <div style={{width: "33.5vw"}}>
                                    <TextField
                                        label="Zip Code"
                                        variant="filled"
                                        placeholder={"12345"}
                                        sx={{
                                            "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{disableUnderline: true, style:{fontSize: "2.1vw", height: "6.5vh"}}}
                                        InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                    />
                                </div>
                                <div style={{width: "33.5vw"}}>
                                    <FormControl
                                        fullWidth
                                        focused
                                        variant="filled"
                                    >
                                        <InputLabel style={{color: "#9E9E9E", fontSize: "2vw"}}>Country</InputLabel>
                                        <Select
                                            variant="filled"
                                            disableUnderline
                                            style={{fontSize: "2.1vw", height: "6.5vh"}}
                                        >
                                            <MenuItem value={1}>United States</MenuItem>
                                            <MenuItem value={2}>United Kingdom</MenuItem>
                                            <MenuItem value={3}>Mexico</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div style={{textAlign: "center", width: "69vw"}}>
                            <Button fullWidth variant={"outlined"} style={{color: "#696969", border: "2px #9A9A9A solid", textTransform: "capitalize", fontSize: "2vw"}}>Continue to Summary</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>

        </div>
    );
}


export default BillingAddress;