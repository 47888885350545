import {Button} from "@mui/material";
import React from "react";
import Slider from "./Slider/Slider";
import MediaQuery from "react-responsive";

const Security = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%", marginTop: "3.5vh"}}>
                    <div style={{width: "43%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "1.23vw", color: "#575757"}}>
                            Security Options
                        </div>
                        <div>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "1vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div style={{width: "52%", height: "100%", display: "flex", alignItems: "center"}}>
                        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div style={{color: "#666666", fontSize: "1.05vw"}}>Location </div>
                                <div style={{color: "#888888", fontSize: "0.9vw"}}>Enable while on Site </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", height: "75%", width: "90%", marginTop: "3.5vh"}}>
                    <div style={{width: "43%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{fontSize: "2.25vw", color: "#575757"}}>
                            Security Options
                        </div>
                        <div>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", borderRadius: 0, marginLeft: "0.6vw", width: "4.8vw", padding: 0, fontSize: "2vw", backgroundColor: "#545454", color: "#FFFFFF"}}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div style={{width: "52%", height: "100%", display: "flex", alignItems: "center"}}>
                        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div style={{color: "#666666", fontSize: "2.05vw"}}>Location </div>
                                <div style={{color: "#888888", fontSize: "1.75vw"}}>Enable while on Site </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Security;