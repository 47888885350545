import React from 'react';
import {Button} from "@mui/material";
import "./Setting.css";
import Radio from "./RadioButton/RadioButton";
import AccountIcon from "./Icons/AccountIcon";
import MediaQuery from "react-responsive";

const Account = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "5vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.8vh"}}>
                            <div style={{height: "2.6vw", width: "2.6vw", marginLeft: "0.4vw"}}>
                                <AccountIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Manage Account</div>
                        </div>
                        <form style={{height: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "1vw", color: "#666666"}}>Name</label>
                                <input placeholder={"First Last"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "1vw", color: "#666666"}}>Email</label>
                                <input placeholder={"username@email.com"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "1vw", color: "#666666"}}>Phone #</label>
                                <input placeholder={"+(123)-456-7890"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "1vw", color: "#666666"}}>Password</label>
                                <input placeholder={"**********"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "2.5vw", color: "#666666"}}>Gender</label>
                                <Radio/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "1.35vw", marginRight: "1vw", color: "#666666"}}>Date of Birth</label>
                                <input placeholder={"08/12/23"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "1.5vw", border: "none"}}/>
                            </div>
                        </form>
                        <div style={{textAlign: "center", marginTop: "6vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "1.3vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "80vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "4.5vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <AccountIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Manage Account</div>
                        </div>
                        <form style={{height: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2vw", color: "#666666"}}>Name</label>
                                <input placeholder={"First Last"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "2.6vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2vw", color: "#666666"}}>Email</label>
                                <input placeholder={"username@email.com"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "2.6vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2vw", color: "#666666"}}>Phone #</label>
                                <input placeholder={"+(123)-456-7890"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "2.6vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2vw", color: "#666666"}}>Password</label>
                                <input placeholder={"**********"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "2.6vw", border: "none"}}/>
                            </div>
                            <div style={{display: "flex"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2.5vw", color: "#666666"}}>Gender</label>
                                <Radio/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <label style={{whiteSpace: "nowrap", fontSize: "2.6vw", marginRight: "2vw", color: "#666666"}}>Date of Birth</label>
                                <input placeholder={"08/12/23"} style={{width: "100%", height: "5vh", background: "#E7E7E7", fontSize: "2.6vw", border: "none"}}/>
                            </div>
                        </form>
                        <div style={{textAlign: "center", marginTop: "6vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "2vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Account;