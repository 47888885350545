import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Button} from "@mui/material";
import img_ex from "./img/img_ex.png"
import img_ex_mobile from "./img/img_ex_mobile.png";
import "./Artists.css";
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const HorizontalDisplacement = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "100vh", width: "100vw"}}>
                    <Grid container style={{marginTop: "10vh", height: "70vh", width: "90vw", marginLeft: "5vw"}}>
                        <Grid item xs={6}>
                            <div style={{height: "35vh",marginTop: "4.2vh", fontSize: "3.2vw"}}>Browse Artist Exhibitions</div>
                            <div style={{display: "flex",flexDirection: "column", justifyContent: "space-between", height: "35vh", marginRight: "7vw", fontSize: "1.2vw"}}>
                                <div>
                                    Beyond the canvas, our artists seek to share the transformative power of art with the world.
                                    Through workshops, talks, and community involvement, they encourage others to nurture their creative spirit and explore the artistry within.
                                </div>
                                <Button disableRipple variant="contained" onClick={()=>handleClick('Exhibitions')} style={{backgroundColor: "transparent", width: "11vw", height: "6vh", borderRadius: "0", background: "#2F2F2F", textTransform:"capitalize", fontSize: "1.1vw"}}>View Exhibitions</Button>
                            </div>
                        </Grid>
                        <Grid item xs={6} style={{overflow: "hidden"}}>
                            <img alt="Your Image" src={img_ex} style={{height:"77vh"}}/>
                        </Grid>
                    </Grid>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw"}}>
                    <img alt="Your Image" src={img_ex_mobile} style={{width: "100vw"}}/>
                    <div style={{marginLeft: "5.5vw", paddingBottom: "11vh"}}>
                        <div style={{marginTop: "5vh", fontSize: "4.5vw"}}>Browse Artist Exhibitions</div>
                        <div style={{marginTop: "17vh", fontSize: "2.3vw", width: "75vw", marginBottom: "3vh"}}>
                            Beyond the canvas, our artists seek to share the transformative power of art with the world.
                            Through workshops, talks, and community involvement, they encourage others to nurture their creative spirit and explore the artistry within.
                        </div>
                        <Button disableRipple variant="contained" onClick={()=>handleClick('Exhibitions')} style={{backgroundColor: "transparent", padding: "1vh 2vw 1vh 2vw",  borderRadius: "0", background: "#2F2F2F", textTransform:"capitalize", fontSize: "2vw"}}>View Exhibitions</Button>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default HorizontalDisplacement;