import React from 'react';
import Grid from '@mui/material/Grid';
import {Button} from "@mui/material";
import img_ex from "./img/img_ex.png"
import EastIcon from '@mui/icons-material/East';
import {useNavigate} from "react-router-dom";
import MediaQuery from "react-responsive";

const FeatureEx = () => {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "95vh", width: "100vw"}}>
                    <Grid container style={{marginTop: "10vh", height: "70vh", width: "90vw", marginLeft: "5vw"}}>
                        <Grid item xs={6}>
                            <div style={{marginTop: "4.2vh", fontSize: "1.6vw", fontStyle: "italic"}}>FEATURED</div>
                            <div style={{fontSize: "3.2vw"}}>Exhibition Title</div>
                            <div style={{marginTop: "25vh", marginRight: "7vw", fontSize: "1.2vw"}}>
                                <div>
                                    Join us on this artistic odyssey, where emotions transcend boundaries and colors tell tales of our shared humanity.
                                </div>
                                <Button disableRipple component="label" variant="contained" onClick={()=>handleClick('Exhibitions')} startIcon={<EastIcon style={{transform:"scale(1.8)", marginRight: "1.6vw"}}/>} style={{backgroundColor: "transparent", width: "15vw", height: "6vh", marginTop: "8vh", justifyContent: "flex-start", borderRadius: "0", textTransform:"capitalize", fontSize: "1.2vw", background: "white", color: "#2F2F2F", boxShadow: "none"}}>
                                    Learn More
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{maxWidth: "44vw", overflow: "hidden"}}>
                                <img alt="Your Image" src={img_ex} style={{marginTop: "5vh", height:"68vh"}}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "100vw", paddingBottom: "6vh"}}>
                    <div style={{marginTop: "5vh", width: "90vw", marginLeft: "5vw"}}>
                        <div>
                            <div style={{marginTop: "4.2vh", fontSize: "2.3vw", fontStyle: "italic"}}>FEATURED</div>
                            <div style={{fontSize: "4.2vw"}}>Exhibition Title</div>
                        </div>
                        <img alt="Your Image" src={img_ex} style={{marginTop: "4vh", width: "90vw"}}/>
                        <div>
                            <div style={{marginTop: "5.5vh", marginRight: "20vw"}}>
                                <div style={{fontSize: "2.2vw"}}>
                                    Join us on this artistic odyssey, where emotions transcend boundaries and colors tell tales of our shared humanity.
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <Button disableRipple component="label"
                                        variant="contained"
                                        onClick={()=>handleClick('Exhibitions')}
                                        startIcon={<EastIcon style={{transform:"scale(1.8)",fontSize: "2vw", marginRight: "1.6vw"}}/>}
                                        style={{backgroundColor: "transparent", padding: 0, height: "6vh", marginTop: "4vh", justifyContent: "flex-start", borderRadius: "0", textTransform:"capitalize", fontSize: "2.5vw", background: "white", color: "#2F2F2F", boxShadow: "none"}}>
                                    Learn More
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default FeatureEx;