import React from 'react';
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const TermOfUse = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "300vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "3.7vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Frequently Asked Questions</div>
                    </div>
                    <div style={{height: "270vh", width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{height: "235vh", width: "82vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>What is Octavate and what are its goals?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>
                                            Octavate is an American technology company formed from a decentralized group of artists and executives aiming to quantify the chaos of the music industry.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Where is Octavate located?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>Octavate is based in New York City, but it has roots worldwide.</span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How do you plan to use the sales fees?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            The sales fees will primarily go toward software development.
                                            Additionally, a portion will cover administrative costs and artist development.
                                            A small percentage will be donated to a 501(c)3 charity supporting independent artists.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How are you going to better quantify the music industry?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Octavate's approach involves hiring the best talent, including capable engineers who align with our long-term vision.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Why do you want to create a quantification method?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Current social media algorithms often promote harmful content for financial gain.
                                            Octavate aims to break this cycle by rewarding genuine talent and fostering a culture that brings out the best in individuals.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How much does it cost to change the music industry?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Effecting positive change in the global music industry requires significant capital.
                                            The vast world of the art industry presents opportunities for participation and development.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Why don't you just use Kickstarter?</span>
                                    </div>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>
                                            Utilizing the art world aligns with the music industry's needs.
                                            Starting in these domains helps circumvent resistance from established institutions.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{fontSize: "1.3vw", width: "70vw", display: "flex", justifyContent: "space-between"}}>
                                    <div style={{width: "28vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q: </span>
                                        <span>
                                            Why don't you just use Kickstarter?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "300vh", width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", fontSize: "5vw", height: "21vh", width: "90vw", display: "flex", alignItems: "center"}}>
                        <div>Frequently Asked Questions</div>
                    </div>
                    <div style={{height: "270vh", width: "90vw", display: "flex", color: "#2F2F2F"}}>
                        <div style={{height: "264vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "29vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>What is Octavate and what are its goals?</span>
                                    </div>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>Octavate is an American technology company formed from a decentralized group of artists and executives aiming to quantify the chaos of the music industry.</span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "26vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "17vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Where is Octavate located?</span>
                                    </div>
                                    <div style={{width: "45vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>Octavate is based in New York City, but it has roots worldwide.</span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "38vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "29vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How do you plan to use the sales fees?</span>
                                    </div>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            The sales fees will primarily go toward software development.
                                            Additionally, a portion will cover administrative costs and artist development.
                                            A small percentage will be donated to a 501(c)3 charity supporting independent artists.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "33vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "24vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "50vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How are you going to better quantify the music industry?</span>
                                    </div>
                                    <div style={{width: "55vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Octavate's approach involves hiring the best talent, including capable engineers who align with our long-term vision.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "37vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "28vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "50vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Why do you want to create a quantification method?</span>
                                    </div>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Current social media algorithms often promote harmful content for financial gain.
                                            Octavate aims to break this cycle by rewarding genuine talent and fostering a culture that brings out the best in individuals.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "37vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "28vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "40vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>How much does it cost to change the music industry?</span>
                                    </div>
                                    <div style={{width: "60vw"}}>
                                        <span style={{fontWeight: "bold"}}>A:  </span>
                                        <span>
                                            Effecting positive change in the global music industry requires significant capital.
                                            The vast world of the art industry presents opportunities for participation and development.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "33vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "24vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q:  </span>
                                        <span>Why don't you just use Kickstarter?</span>
                                    </div>
                                    <div style={{width: "65vw"}}>
                                        <span style={{fontWeight: "bold"}}>A: </span>
                                        <span>
                                            Utilizing the art world aligns with the music industry's needs.
                                            Starting in these domains helps circumvent resistance from established institutions.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "33vh"}}>
                                <div style={{fontSize: "2.4vw", width: "75vw", height: "24vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <div style={{width: "70vw"}}>
                                        <span style={{fontWeight: "bold"}}>Q: </span>
                                        <span>
                                            Why don't you just use Kickstarter?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default TermOfUse;