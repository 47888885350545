import TextField from '@mui/material/TextField';
import * as React from "react";
import MediaQuery from "react-responsive";

const BillingDetail = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{width: "25.5vw", marginLeft: "1vw", marginTop: "6vh"}}>
                    <div style={{marginBottom: "3vh"}}>
                        <div style={{fontSize: "1.5vw", color: "#2F2F2F"}}>Billing Details</div>
                    </div>
                    <div style={{height: "26vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "6.5vh"}}>
                            <TextField
                                label="First and Last Name"
                                variant="standard"
                                placeholder={"John Doe"}
                                sx={{
                                    "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                }}
                                fullWidth
                                focused
                                InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "1vw"}}}
                                InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                            />
                        </div>

                        <div style={{height: "6.5vh"}}>
                            <TextField
                                label="Card Number"
                                variant="standard"
                                placeholder={"1234 5678 9012 3456"}
                                sx={{
                                    "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                }}
                                fullWidth
                                focused
                                InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "1vw"}}}
                                InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                            />
                        </div>

                        <div style={{display: "flex", height: "6.5vh", justifyContent: "space-between"}}>
                            <div style={{width: "12vw"}}>
                                <TextField
                                    label="Expiration Date"
                                    variant="standard"
                                    placeholder={"01/30"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                />
                            </div>
                            <div style={{width: "12vw"}}>
                                <TextField
                                    label="CVC"
                                    variant="standard"
                                    placeholder={"123"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "0.9vw"}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{width: "48vw", marginLeft: "3vw", marginTop: "6vh"}}>
                    <div style={{marginBottom: "3vh"}}>
                        <div style={{fontSize: "2.8vw", color: "#2F2F2F"}}>Billing Details</div>
                    </div>
                    <div style={{height: "26vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{height: "6.5vh"}}>
                            <TextField
                                label="First and Last Name"
                                variant="standard"
                                placeholder={"John Doe"}
                                sx={{
                                    "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                }}
                                fullWidth
                                focused
                                InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "2.1vw"}}}
                                InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                            />
                        </div>

                        <div style={{height: "6.5vh"}}>
                            <TextField
                                label="Card Number"
                                variant="standard"
                                placeholder={"1234 5678 9012 3456"}
                                sx={{
                                    "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                }}
                                fullWidth
                                focused
                                InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "2.1vw"}}}
                                InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                            />
                        </div>

                        <div style={{display: "flex", height: "6.5vh", justifyContent: "space-between"}}>
                            <div style={{width: "20vw"}}>
                                <TextField
                                    label="Expiration Date"
                                    variant="standard"
                                    placeholder={"01/30"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "2.1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                />
                            </div>
                            <div style={{width: "20vw"}}>
                                <TextField
                                    label="CVC"
                                    variant="standard"
                                    placeholder={"123"}
                                    sx={{
                                        "& input::placeholder": { fontSize: "2.1vw", color: "#2F2F2F", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{borderBottom: "#D4D4D4 3px solid", height: "4.5vh", fontSize: "2.1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "2vw"}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default BillingDetail;
