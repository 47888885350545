import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {Button} from "@mui/material";
import React from "react";
import SettingMasterCard from "./SettingCard/SettingMasterCard";
import PayPal from "./SettingCard/SettingPayPal";
import MonetaryIcon from "./Icons/MonetaryIcon";
import MediaQuery from "react-responsive";
import HelpIcon from "./Icons/HelpIcon";


const BillAndPay = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "80vh", width: "27vw", background: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "24vw"}}>
                        <div style={{height: "8vh", display: "flex", alignItems: "center", color: "#575757"}}>
                            <div style={{height: "2.5vw", width: "2.5vw", marginLeft: "0.4vw"}}>
                                <MonetaryIcon/>
                            </div>
                            <div style={{marginLeft: "1vw", fontSize: "1.5vw"}}>Billing and Payment</div>
                        </div>
                        <div style={{marginLeft: "3.8vw", marginBottom: "5vh", fontSize: "1.2vw", color: "#575757"}}>Set payment details</div>
                        <div style={{background: "#E7E7E7", height: "40%", width: "97%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div style={{height: "100%", width: "90%"}}>
                                <div style={{fontSize: "1.15vw", fontWeight: "bold", color: "#2F2F2F", marginTop: "2.5vh"}}>Payment Methods</div>
                                <div style={{display: "flex", marginTop: "1.6vh", marginLeft: "0.6vw"}}>
                                    <SettingMasterCard/>
                                    <div style={{marginLeft: "1vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "1.18vw", color: "#2F2F2F"}}>Mastercard ***4465</div>
                                        <div style={{fontSize: "0.8vw", color: "#868686"}}>Expiration Date 2026</div>
                                    </div>
                                </div>
                                <div style={{display: "flex", marginTop: "1.8vh", marginLeft: "0.6vw"}}>
                                    <PayPal/>
                                    <div style={{marginLeft: "1vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "1.18vw", color: "#2F2F2F"}}>PayPal</div>
                                        <div style={{fontSize: "0.8vw", color: "#868686"}}>@username</div>
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", marginTop: "2vh"}}>
                                    <AddBoxOutlinedIcon style={{fontSize: "2.3vw", marginRight: "0.6vw", color: "#8A8A8A"}}/>
                                    <div style={{fontSize: "1.18vw", color: "#575757"}}>Add More</div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "2.5vh"}}></div>
                        <div style={{background: "#E7E7E7", margin: "auto", height: "26%", width: "97%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div style={{height: "100%", width: "90%"}}>
                                <div style={{fontSize: "1.15vw", fontWeight: "bold", color: "#2F2F2F", marginTop: "2.5vh"}}>Primary Payment Method</div>
                                <div style={{display: "flex", marginTop: "1.6vh", marginLeft: "0.6vw"}}>
                                    <SettingMasterCard/>
                                    <div style={{marginLeft: "1vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "1.18vw", color: "#2F2F2F"}}>Mastercard ***4465</div>
                                        <div style={{fontSize: "0.8vw", color: "#868686"}}>Expiration Date 2026</div>
                                    </div>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Button variant="outlined" disableRipple disableElevation style={{fontSize: "1vw", color: "#575757", borderColor: "#A5A5A5", background: "transparent", borderRadius: 0, padding: 0, textTransform: "capitalize", marginTop: "1.3vh", width: "4vw"}}>Edit</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "2.5vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "1.1vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "80vh", width: "57vw", background: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "73vh", width: "50vw"}}>
                        <div style={{height: "6vh", display: "flex", alignItems: "center", color: "#575757", marginBottom: "1vh"}}>
                            <div style={{height: "5vw", width: "5vw", marginLeft: "1vw"}}>
                                <MonetaryIcon/>
                            </div>
                            <div style={{marginLeft: "2vw", fontSize: "2.7vw"}}>Billing and Payment</div>
                        </div>
                        <div style={{marginLeft: "8vw", marginBottom: "5vh", fontSize: "2.1vw", color: "#575757"}}>Set payment details</div>
                        <div style={{background: "#E7E7E7", height: "40%", width: "97%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div style={{height: "100%", width: "90%"}}>
                                <div style={{fontSize: "2.1vw", fontWeight: "bold", color: "#2F2F2F", marginTop: "2.5vh"}}>Payment Methods</div>
                                <div style={{display: "flex", marginTop: "1.6vh", marginLeft: "0.6vw"}}>
                                    <SettingMasterCard/>
                                    <div style={{marginLeft: "2vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "2vw", color: "#2F2F2F"}}>Mastercard ***4465</div>
                                        <div style={{fontSize: "1.52vw", color: "#868686"}}>Expiration Date 2026</div>
                                    </div>
                                </div>
                                <div style={{display: "flex", marginTop: "1.8vh", marginLeft: "0.6vw"}}>
                                    <PayPal/>
                                    <div style={{marginLeft: "2vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "2vw", color: "#2F2F2F"}}>PayPal</div>
                                        <div style={{fontSize: "1.52vw", color: "#868686"}}>@username</div>
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", marginTop: "2vh"}}>
                                    <AddBoxOutlinedIcon style={{fontSize: "4vw", marginRight: "0.6vw", color: "#8A8A8A"}}/>
                                    <div style={{fontSize: "1.9vw", color: "#575757"}}>Add More</div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "2.5vh"}}></div>
                        <div style={{background: "#E7E7E7", margin: "auto", height: "26%", width: "97%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div style={{height: "100%", width: "90%"}}>
                                <div style={{fontSize: "2.1vw", fontWeight: "bold", color: "#2F2F2F", marginTop: "2.5vh"}}>Primary Payment Method</div>
                                <div style={{display: "flex", marginTop: "1.6vh", marginLeft: "0.6vw"}}>
                                    <SettingMasterCard/>
                                    <div style={{marginLeft: "2vw", marginTop: "0.8vh"}}>
                                        <div style={{fontSize: "2vw", color: "#2F2F2F"}}>Mastercard ***4465</div>
                                        <div style={{fontSize: "1.52vw", color: "#868686"}}>Expiration Date 2026</div>
                                    </div>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Button variant="outlined" disableRipple disableElevation style={{fontSize: "1.7vw", color: "#575757", borderColor: "#A5A5A5", background: "transparent", borderRadius: 0, padding: 0, textTransform: "capitalize", marginTop: "1.3vh", width: "4vw"}}>Edit</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginTop: "2.5vh"}}>
                            <Button variant="contained" disableRipple disableElevation style={{textTransform: "capitalize", width: "50%", background: "#545454", borderRadius: 0, fontSize: "2vw"}}>Save Changes</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default BillAndPay;