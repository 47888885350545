import React from 'react';
import './Home.css'
import img_1 from "./img/img_event1.png";
import img_2 from "./img/img_event2.png";
import img_3 from "./img/img_event3.png";
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";

const EventCard_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "28vw", backgroundImage: `url(${img_1})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "16.5vh", width: "16vw", background: "rgba(47,47,47, 0.5)", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", height: "8.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "0.9vw", color: "white"}}>
                                    Etheral Exhibition
                                </div>
                                <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "0.6vw"}}>
                                    with featured artwork by Kate Evans
                                </div>
                            </div>

                            <div style={{fontSize: "0.7vw", color: "white"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "1.5vh"}}>
                            <Button style={{height: "2.7vh",width: "6.5vw", fontSize: "0.5vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "33vh", width: "60vw", backgroundImage: `url(${img_1})`, backgroundSize: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{height: "19vh", width: "40vw", background: "rgba(47,47,47, 0.5)"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{fontSize: "2.8vw", color: "white"}}>
                                Etheral Exhibition
                            </div>
                            <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "1.5vw"}}>
                                with featured artwork by Kate Evans
                            </div>
                            <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "white"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "12vw", fontSize: "0.95vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const EventCard_3 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "28vw", backgroundImage: `url(${img_3})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "16.5vh", width: "16vw", background: "rgba(47,47,47, 0.5)", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", height: "8.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "0.9vw", color: "white"}}>
                                    Etheral Exhibition
                                </div>
                                <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "0.6vw"}}>
                                    with featured artwork by Kate Evans
                                </div>
                            </div>

                            <div style={{fontSize: "0.7vw", color: "white"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "1.5vh"}}>
                            <Button style={{height: "2.7vh",width: "6.5vw", fontSize: "0.5vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "33vh", width: "60vw", backgroundImage: `url(${img_3})`, backgroundSize: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{height: "19vh", width: "40vw", background: "rgba(47,47,47, 0.5)"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{fontSize: "2.8vw", color: "white"}}>
                                Etheral Exhibition
                            </div>
                            <div style={{color:" #CECECE", fontStyle: "italic", fontSize: "1.5vw"}}>
                                with featured artwork by Kate Evans
                            </div>
                            <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "white"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "12vw", fontSize: "0.95vw", border: "solid #CECECE 0.3px", borderRadius: 0, color: "white"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const EventCard_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{height: "33vh", width: "28vw", backgroundImage: `url(${img_2})`, backgroundSize: "100%", display: "flex", alignItems: "flex-end"}}>
                    <div style={{height: "16.5vh", width: "16vw", background: "rgba(242,242,242, 0.5)", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", height: "8.8vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div>
                                <div style={{fontSize: "0.9vw", color: "#2F2F2F"}}>
                                    Etheral Exhibition
                                </div>
                                <div style={{color:" #424242", fontStyle: "italic", fontSize: "0.6vw"}}>
                                    with featured artwork by Kate Evans
                                </div>
                            </div>

                            <div style={{fontSize: "0.7vw", color: "#2F2F2F"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>
                        <div style={{textAlign: "center", marginBottom: "1.5vh"}}>
                            <Button style={{height: "2.7vh",width: "6.5vw", fontSize: "0.5vw", border: "solid #424242 0.3px", borderRadius: 0, color: "#2F2F2F"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{height: "33vh", width: "60vw", backgroundImage: `url(${img_2})`, backgroundSize: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{height: "19vh", width: "40vw", background: "rgba(242,242,242, 0.5)"}}>
                        <div style={{marginTop: "1.5vh", marginLeft: "0.8vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{fontSize: "2.8vw", color: "#2F2F2F"}}>
                                Etheral Exhibition
                            </div>
                            <div style={{color: "#2F2F2F", fontStyle: "italic", fontSize: "1.5vw"}}>
                                with featured artwork by Kate Evans
                            </div>
                            <div style={{fontSize: "1.7vw", marginTop: "2.5vh", color: "#2F2F2F"}}>
                                July 5th @ MoMA on the 2nd Floor
                            </div>
                        </div>

                        <div style={{textAlign: "center"}}>
                            <Button style={{marginTop: "1.5vh", height: "2.7vh",width: "12vw", fontSize: "0.95vw", border: "solid #2F2F2F 0.3px", borderRadius: 0, color: "#2F2F2F"}}>Reserve a Ticket</Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const imageUrls = [
    <EventCard_1/>,
    <EventCard_2/>,
    <EventCard_3/>
];

const ImageRoller_3 = () => {
    const doubledImages = [...imageUrls, ...imageUrls];
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div className="image-roller-container">
                    <div className="image-roller" id={"roller_3"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginRight: "2vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div className="image-roller-container" style={{width: "60vw", height: "107vh"}}>
                    <div className="image-roller" id={"roller_3"}>
                        {doubledImages.map((elem, index) => (
                            <div style={{marginBottom: "4vw"}}>
                                {elem}
                            </div>
                        ))}
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default ImageRoller_3;