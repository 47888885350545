import * as React from 'react';
import {useNavigate} from "react-router-dom";
import './FooterBar.css'
import {Button} from "@mui/material";
import MediaQuery from "react-responsive";

function FooterBar() {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div className="social-icons-footer" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", width: "94vw", alignItems: "center"}}>
                        <div style={{marginRight: "8vw", whiteSpace: "nowrap"}}>
                            <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="twitter">
                                <i className="fa fa-twitter-square" aria-hidden="true"></i>
                            </a>
                            <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="instagram">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                            <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="facebook">
                                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div style={{display: "flex", width: "53vw"}}>
                            <Button disableRipple onClick={()=>handleClick('TermOfUse')} style={{fontSize: "1vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent", marginRight: "4vw"}}>Terms of Use</Button>
                            <Button disableRipple onClick={()=>handleClick('PrivatePolicy')} style={{fontSize: "1vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent", marginRight: "4vw"}}>Privacy Policy</Button>
                            <Button disableRipple onClick={()=>handleClick("Collect")} style={{fontSize: "1vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent"}}>Collect</Button>
                        </div>
                        <div style={{display: "flex", width: "27vw", fontSize: "1vw", color: "#6A6A6A"}}>
                            3quilibrium Co. DBA Octavate. All Rights Reserved.
                        </div>
                        {/*<a href="#" title="youtube">*/}
                        {/*    <i className="fa fa-youtube-square" aria-hidden="true"></i>*/}
                        {/*</a>*/}
                        {/*<a href="#" title="linkedin">*/}
                        {/*    <i className="fa fa-linkedin-square" aria-hidden="true"></i>*/}
                        {/*</a>*/}
                        {/*<a href="#" title="pinterest">*/}
                        {/*    <i className="fa fa-pinterest-p" aria-hidden="true"></i>*/}
                        {/*</a>*/}
                        {/*<a href="#" title="camera">*/}
                        {/*    <i className="fa fa-camera-retro" aria-hidden="true"></i>*/}
                        {/*</a>*/}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div className="social-icons-footer">
                    <div style={{height: "12vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                            <Button disableRipple onClick={()=>handleClick('TermOfUse')} style={{fontSize: "2vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent", marginRight: "6vw", padding: 0}}>Terms of Use</Button>
                            <Button disableRipple onClick={()=>handleClick('PrivatePolicy')} style={{fontSize: "2vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent", marginRight: "6vw", padding: 0}}>Privacy Policy</Button>
                            <Button disableRipple onClick={()=>handleClick("Collect")} style={{fontSize: "2vw", color: "#6A6A6A", textTransform: "capitalize", backgroundColor: "transparent", padding: 0}}>Collect</Button>
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{marginRight: "8vw", whiteSpace: "nowrap"}}>
                                <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="twitter">
                                    <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                </a>
                                <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="instagram">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="1-private-gallery/Figma-design/src/components/Footer/button#FooterBar.jsx" title="facebook">
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div style={{display: "flex", fontSize: "1.8vw", color: "#6A6A6A"}}>
                                3quilibrium Co. DBA Octavate. All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default FooterBar;