import React from 'react';
import Grid from '@mui/material/Grid';
import img from "./img/img.png"
import MediaQuery from "react-responsive";

const FeatureCollect = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <Grid container style={{marginTop: "2vh", marginBottom: "8vh", height: "43vh", width: "86vw", marginLeft: "6vw", background: "#F2F2F2"}}>
                    <Grid item xs={5} style={{overflow: "hidden"}}>
                        <img alt="Your Image" src={img} style={{height:"43vh"}}/>
                    </Grid>
                    <Grid item xs={0.3}>
                    </Grid>
                    <Grid item xs={6.7}>
                        <div>
                            <div style={{marginTop: "4.2vh", fontSize: "0.8vw", fontStyle: "italic"}}>FEATURE OF THE WEEK</div>
                            <div style={{fontSize: "2vw"}}>Ethereal Reverie Exhibition</div>
                            <div style={{marginTop: "18vh", marginRight: "4vw", fontSize: "0.8vw"}}>
                                <div>
                                    "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space. With each stroke,
                                    the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <Grid container style={{marginBottom: "5vh", width: "96vw", marginLeft: "2vw", background: "#F2F2F2"}}>
                    <Grid item xs={5.5}>
                        <img alt="Your Image" src={img} style={{width: "40vw"}}/>
                    </Grid>
                    <Grid item xs={6.5} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{marginTop: "3.5vh", fontSize: "1.5vw", fontStyle: "italic"}}>FEATURE OF THE WEEK</div>
                            <div style={{fontSize: "2.8vw"}}>Ethereal Reverie Exhibition</div>
                        </div>
                        <div style={{marginRight: "3vw", fontSize: "1.23vw", marginBottom: "3vh"}}>
                            "Ethereal Reverie" is an enchanting fusion of vibrant hues and dynamic brushstrokes that breathe life into every corner of your space. With each stroke,
                            the artist [Artist's Name] skillfully captures the essence of boundless creativity and emotion, allowing the artwork to resonate with your own inner experiences.
                        </div>
                    </Grid>
                </Grid>
            </MediaQuery>
        </div>
    );
};

export default FeatureCollect;