import React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from '@mui/material/TextField';
import img from './img/img_1.png'
import {Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";

const Collect = () => {
    const handleSearch = (event) => {
        console.log(event.target.value);
    };
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "88.6vh", position: "absolute", zIndex: -1, filter: "brightness(50%)"}}>
                </div>
                <div style={{width: "100vw", height: "88.6vh"}}>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <TextField
                            variant="standard"
                            placeholder="Search Collect"
                            onChange={handleSearch}
                            sx={{
                                "& input::placeholder": { fontSize: "1.35vw", color: "#FFFFFF", fontStyle: "italic", opacity: 1},
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: "#FFFFFF", fontSize: "1.8vw"}}/>
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                                style: {color: "#FFFFFF", fontSize:"1.35vw", marginTop: "5vh", marginRight: "3vw", fontFamily: "Poppins, sans-serif"}
                            }}
                            style={{position: "relative", width: "16%"}}
                        />
                    </div>
                    <div style={{marginLeft: "5vw", marginTop: "5vh"}}>
                        <div style={{fontSize: "3.5vw", color: "#FFFFFF"}}>
                            Collect
                        </div>
                        <Divider style={{borderColor: "#A0A0A0", fontWeight: "4px", width: "80%", marginTop: "20vh"}}/>
                        <div style={{marginTop: "12vh", marginBottom: "5vh", width: "87%", fontSize: "1.28vw", color: "#FFFFFF"}}>
                            Welcome to our treasure trove of artistic expression.
                            Explore a diverse and carefully curated collection that celebrates the magic of creativity in all its forms.
                            Each piece has been handpicked to evoke emotions, provoke thoughts, and spark conversations.
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "60vh", position: "absolute", zIndex: -1, filter: "brightness(50%)"}}>
                </div>
                <div style={{width: "100vw", height: "60vh"}}>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <TextField
                            variant="standard"
                            placeholder="Search Collect"
                            onChange={handleSearch}
                            sx={{
                                "& input::placeholder": { fontSize: "2.3vw", color: "#FFFFFF", fontStyle: "italic", opacity: 1},
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: "#FFFFFF", fontSize: "2.8vw"}}/>
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                                style: {color: "#FFFFFF", fontSize:"2.3vw", marginTop: "3.5vh", marginRight: "3vw", fontFamily: "Poppins, sans-serif"}
                            }}
                            style={{position: "relative", width: "25%", marginRight: "3vw"}}
                        />
                    </div>
                    <div style={{marginLeft: "5vw", marginTop: "3vh"}}>
                        <div style={{fontSize: "5.3vw", color: "#FFFFFF"}}>
                            Collect
                        </div>
                        <Divider style={{borderColor: "#A0A0A0", fontWeight: "4px", width: "98%", marginTop: "20vh"}}/>
                        <div style={{marginTop: "8vh", marginBottom: "5vh", width: "90%", fontSize: "1.85vw", color: "#FFFFFF"}}>
                            Welcome to our treasure trove of artistic expression.
                            Explore a diverse and carefully curated collection that celebrates the magic of creativity in all its forms.
                            Each piece has been handpicked to evoke emotions, provoke thoughts, and spark conversations.
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

export default Collect;