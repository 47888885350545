import React from "react";
import img from "./img/new_img_1.png";
import img_1 from "./img/new_img_2.png";
import img_2 from "./img/new_img_3.png";
import MediaQuery from "react-responsive";


const ArtistPieceCard_1 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img})`,
                    width: "25vw",
                    height: "65vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "25vw",
                    height: "65vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "25vw", height: "30vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1vw", textAlign: "center"}}>Artist Name</div>
                            <div style={{fontSize: "2vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.5vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img})`,
                    backgroundSize: "100%",
                    width: "30vw",
                    height: "36vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "30vw",
                    height: "36vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "30vw", height: "16vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1.7vw", textAlign: "center", marginBottom: "0.6vh"}}>Artist Name</div>
                            <div style={{fontSize: "2.5vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.85vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const ArtistPieceCard_2 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img_1})`,
                    width: "25vw",
                    height: "65vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "25vw",
                    height: "65vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "25vw", height: "30vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1vw", textAlign: "center"}}>Artist Name</div>
                            <div style={{fontSize: "2vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.5vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img_1})`,
                    backgroundSize: "100%",
                    width: "30vw",
                    height: "36vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "30vw",
                    height: "36vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "30vw", height: "16vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1.7vw", textAlign: "center", marginBottom: "0.6vh"}}>Artist Name</div>
                            <div style={{fontSize: "2.5vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.85vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const ArtistPieceCard_3 = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img_2})`,
                    width: "25vw",
                    height: "65vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "25vw",
                    height: "65vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "25vw", height: "30vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1vw", textAlign: "center"}}>Artist Name</div>
                            <div style={{fontSize: "2vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.5vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{
                    backgroundImage: `url(${img_2})`,
                    backgroundSize: "100%",
                    width: "30vw",
                    height: "36vh",
                    position: "absolute",
                    zIndex: -1,
                    filter: "brightness(50%)"
                }}>
                </div>
                <div style={{
                    width: "30vw",
                    height: "36vh",
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    color: "white"
                }}>
                    <div style={{width: "30vw", height: "16vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <div>
                            <div style={{fontSize: "1.7vw", textAlign: "center", marginBottom: "0.6vh"}}>Artist Name</div>
                            <div style={{fontSize: "2.5vw", textAlign: "center"}}>Art Piece Title</div>
                        </div>
                        <div style={{fontSize: "1.85vw", textAlign: "center"}}>2023</div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const CuratedForYou = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{marginTop: "9vh", marginBottom: "20vh"}}>
                    <div style={{marginLeft: "3vw"}}>
                        <div style={{fontSize: "2.5vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "7.5vh"}}>
                            Newly Recognized Pieces
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        <ArtistPieceCard_1/>
                        <ArtistPieceCard_2/>
                        <ArtistPieceCard_3/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{marginTop: "9vh", marginBottom: "20vh"}}>
                    <div style={{marginLeft: "3vw"}}>
                        <div style={{fontSize: "3vw", fontStyle: "italic", color: "#2F2F2F", marginBottom: "6vh"}}>
                            Newly Recognized Pieces
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "94vw", display: "flex", justifyContent: "space-between"}}>
                            <ArtistPieceCard_1/>
                            <ArtistPieceCard_2/>
                            <ArtistPieceCard_3/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>

    )
}

export default CuratedForYou;